import {Container, Grid, Paper} from "@mui/material";
import Typography from "@mui/material/Typography";
import {DividingHR} from "../../../Utils/DividingHR";
import {TextValueField, TextValueFieldMonetary} from "../../../Utils/TextValueField";
import {AddressDisplayBlock} from "../../../Utils/Address";
import PhoneIcon from "@mui/icons-material/Phone";
import FaxIcon from "@mui/icons-material/Fax";
import EmailIcon from "@mui/icons-material/Email";
import React from "react";
import {Customer} from "../../../../../../corelogic/models/customer";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import LanguageIcon from "@mui/icons-material/Language";
import Box from "@mui/material/Box";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {UserRoleCode} from "../../../../../../corelogic/models/user";
import {FormattedPercentageNumber} from "../../../Utils/FormattedDisplayNumber";
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import {
    ContactPhoneOutlined,
    DescriptionOutlined,
    InfoOutlined,
    LocalShippingOutlined,
    LocationOnOutlined,
    PaymentsOutlined,
    WorkspacesOutlined
} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {getCustomerSpecificData} from "../../../../../../store/selectors/serverSelector";
import {CustomerSpecificCode} from "../../../../../../corelogic/models/CustomerSpecificData";

interface CustomerViewInterface {
    data: Customer
    userLoggedRoleCode?: string
}

export const CustomerView = (props: CustomerViewInterface) => {
    const {data, userLoggedRoleCode} = props
    const {customerSpecificData} = useSelector(getCustomerSpecificData)
    const intl = useIntl()

    return (
        <>
            <Container maxWidth="xl">
                <Grid container spacing={2}>
                    <Grid item sm={12} lg={6}>
                        <Paper elevation={2} sx={{height: "100%"}}>
                            <Grid container direction="column" height="100%">
                                <Grid container item alignItems="center" p={1}>
                                    <InfoOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageGeneralInformationTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container direction="column" px={2} py={1} spacing={1} flexGrow={1}>
                                    <Grid item pb={1}>
                                        <Paper elevation={5}>
                                            <Grid item container direction="column" p={1} spacing={1}>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldCode"))}
                                                        value={data.code || ""}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldBusinessName"))}
                                                        value={data.businessName || ""}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldBusinessForm"))}
                                                        value={data.businessForm?.label || ""}/>
                                                </Grid>
                                                <Grid container item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldLangage"))}
                                                        value={data.language?.label || ""}/>
                                                </Grid>
                                                <Grid container item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldCurrency"))}
                                                        value={data.currency?.label || ""}/>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <Paper elevation={2} sx={{height: "100%"}}>
                            <Grid container direction="column" height="100%">
                                <Grid container item alignItems="center" p={1}>
                                    <WorkspacesOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageClassificationTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container direction="column" px={2} py={1} spacing={1} flexGrow={1}>
                                    <Grid item height="100%">
                                        <Paper elevation={5} sx={{height: "100%"}}>
                                            <Grid item container direction="column" justifyContent="space-around" p={1}
                                                  spacing={1} height="100%">
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormSuperFamily"))}
                                                        value={data.superFamily?.code || ""}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFamily"))}
                                                        value={data.family?.code || ""}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormCategory"))}
                                                        value={data.category?.code || ""}/>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <Paper elevation={2} sx={{height: "100%"}}>
                            <Grid container direction="column" height="100%">
                                <Grid container item alignItems="center" p={1}>
                                    <LocationOnOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageViewFieldMainAddress.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container direction="column" px={2} py={1} spacing={1} flexGrow={1}>
                                    <Grid item height="100%" pb={1}>
                                        <Paper elevation={5} sx={{height: "100%"}}>
                                            <Grid container item p={2}>
                                                <Box sx={{paddingLeft: "10px"}}>
                                                    <AddressDisplayBlock title={data.businessName}
                                                                         address1={data.address1}
                                                                         address2={data.address2}
                                                                         address3={data.address3}
                                                                         zipCode1={data.zipCode1}
                                                                         zipCode2={data.zipCode2}
                                                                         city={data.city}
                                                                         country={data.country?.label}
                                                                         hasFocus={true}/>
                                                </Box>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <Paper elevation={2} sx={{height: "100%"}}>
                            <Grid container direction="column" height="100%">
                                <Grid container item alignItems="center" p={1}>
                                    <ContactPhoneOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageContactTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container direction="column" px={2} py={1} spacing={1} flexGrow={1}>
                                    <Grid item pb={1}>
                                        <Paper elevation={5}>
                                            <Grid container direction="column" p={1}>
                                                <Grid container item p={1}>
                                                    <PhoneIcon sx={{mr: 1}}/>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone"))}
                                                        value={data.phone || ""}/>
                                                </Grid>
                                                <Grid container item p={1}>
                                                    <PhoneIcon sx={{mr: 1}}/>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldPhone2"))}
                                                        value={data.phone2 || ""}/>
                                                </Grid>
                                                <Grid container item p={1}>
                                                    <FaxIcon sx={{mr: 1}}/>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldFax"))}
                                                        value={data.fax || ""}/>
                                                </Grid>
                                                <Grid container item p={1}>
                                                    <LanguageIcon sx={{mr: 1}}/>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldWebsite"))}
                                                        value={data.website || ""}/>
                                                </Grid>
                                                <Grid container item p={1}>
                                                    <EmailIcon sx={{mr: 1}}/>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEmail"))}
                                                        value={data.mail || ""}/>
                                                </Grid>
                                                <Grid container item p={1}>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("userPageFormFieldSendEmailAtOrderSubmission"))}
                                                        value={data.sendEmailAtOrderSubmission ?
                                                            <CheckBoxIcon fontSize="small"/> :
                                                            <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <Paper elevation={2} sx={{height: "100%"}}>
                            <Grid container direction="column" height="100%">
                                <Grid container item alignItems="center" p={1}>
                                    <DiscountOutlinedIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageRateTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container direction="column" px={2} py={1} spacing={1} flexGrow={1}>
                                    <Grid item pb={1}>
                                        <Paper elevation={5}>
                                            <Grid item container direction="column" p={1} spacing={1}>
                                                {(userLoggedRoleCode === UserRoleCode.COMMERCIAL || UserRoleCode.ADMIN || UserRoleCode.SODIWIN) &&
                                                    <>
                                                        <Grid item>
                                                            <TextValueField
                                                                text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldRate"))}
                                                                value={`${data.rateCode?.code} - (${data.rateCode?.name})` || ""}/>
                                                        </Grid>
                                                    </>}
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormSaleRatePercentage"))}
                                                        value={<FormattedPercentageNumber
                                                            value={data.saleRatePercentage || 0}/>}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormPromotionalRateCode"))}
                                                        value={data.promotionalRateCode?.code && data.promotionalRateCode.name ? `${data.promotionalRateCode?.code} - (${data.promotionalRateCode?.name})` : ""}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormPromotionalSaleRatePercentage"))}
                                                        value={<FormattedPercentageNumber
                                                            value={data.promotionalRatePercentage || 0}/>}/>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <Paper elevation={2} sx={{height: "100%"}}>
                            <Grid container direction="column" height="100%">
                                <Grid container item alignItems="center" p={1}>
                                    <PaymentsOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageBillTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container direction="column" px={2} py={1} spacing={1} flexGrow={1}>
                                    <Grid item height="100%">
                                        <Paper elevation={5} sx={{height: "100%"}}>
                                            <Grid item container direction="column" justifyContent="space-around" p={1}
                                                  spacing={1} height="100%">
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormPaymentChoice"))}
                                                        value={`${data.paymentChoice?.code} - (${data.paymentChoice?.label})` || ""}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormVatManagement"))}
                                                        value={data.vatManagement?.value ? `${data.vatManagement?.value} - (${data.vatManagement?.label})` : ""}/>
                                                </Grid>
                                                {(userLoggedRoleCode === UserRoleCode.COMMERCIAL || UserRoleCode.ADMIN || UserRoleCode.SODIWIN) &&
                                                    <>
                                                        <Grid item>
                                                            <TextValueField
                                                                text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldSaleRepresentative1Code"))}
                                                                value={data.saleRepresentative1?.code && data.saleRepresentative1?.label ? `${data.saleRepresentative1?.code} - (${data.saleRepresentative1?.label})` : ""}/>
                                                        </Grid>
                                                    </>}
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <Paper elevation={2} sx={{height: "100%"}}>
                            <Grid container direction="column" height="100%">
                                <Grid container item alignItems="center" p={1}>
                                    <FolderOutlinedIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageAdministrativeTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container direction="column" px={2} py={1} spacing={1} flexGrow={1}>
                                    <Grid item pb={1}>
                                        <Paper elevation={5}>
                                            <Grid item container direction="column" p={1} spacing={1}>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldSiret"))}
                                                        value={data.siret}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldVat"))}
                                                        value={data.intraCommunityVAT}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldApeNaf"))}
                                                        value={data.apeNaf}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormFieldEan"))}
                                                        value={data.eanCode}/>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item sm={12} lg={6}>
                        <Paper elevation={2} sx={{height: "100%"}}>
                            <Grid container direction="column" height="100%">
                                <Grid container item alignItems="center" p={1}>
                                    <LocalShippingOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageLogisticTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container direction="column" px={2} py={1} spacing={1} flexGrow={1}>
                                    <Grid item height="100%">
                                        <Paper elevation={5} sx={{height: "100%"}}>
                                            <Grid item container direction="column" justifyContent="space-around" p={1}
                                                  spacing={1} height="100%">
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormTransitTime"))}
                                                        value={data.transitTime || ""}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormAcceptRemainder"))}
                                                        value={data.acceptRemainder ?
                                                            <CheckBoxIcon fontSize="small"/> :
                                                            <CheckBoxOutlineBlankIcon fontSize="small"/>}/>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    {customerSpecificData?.customerSpecificCode !== CustomerSpecificCode.SOCOFRAM && <Grid item sm={12}>
                        <Paper elevation={2} sx={{height: "100%"}}>
                            <Grid container direction="column" height="100%">
                                <Grid container item alignItems="center" p={1}>
                                    <LocalShippingOutlined
                                        style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageOutstandingTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container direction="column" px={2} py={1} spacing={1}>
                                    <Grid item height="100%">
                                        <Paper elevation={5} sx={{height: "100%"}}>
                                            <Grid container direction="column" justifyContent="space-around" p={2}
                                                  spacing={1}>
                                                <Grid item p={1}>
                                                    <TextValueFieldMonetary
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormCustomerOutstanding"))}
                                                        value={data.customerOutstanding} currency="€"/>
                                                </Grid>
                                                <Grid item p={1}>
                                                    <TextValueFieldMonetary
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageFormAmountDue"))}
                                                        value={data.amountDue} currency="€"/>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>}
                    <Grid item sm={12}>
                        <Paper elevation={2} sx={{height: "100%"}}>
                            <Grid container direction="column" height="100%">
                                <Grid container item alignItems="center" p={1}>
                                    <DescriptionOutlined style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageNotesTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <Grid container direction="column" px={2} py={1} spacing={1}>
                                    <Grid item height="100%">
                                        <Paper elevation={5} sx={{height: "100%"}}>
                                            <Grid container direction="column" justifyContent="space-around" p={2}
                                                  spacing={1}>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageViewFieldObservations"))}
                                                        value={data.observations || ""}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("customerPageViewFieldDeliveryInformation"))}
                                                        value={data.deliveryInformation || ""}/>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}