import {Item} from "../../../../../../corelogic/models/item";
import {
    Alert,
    Button,
    Card,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    InputLabel,
    Select,
    SelectChangeEvent,
    Tooltip
} from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {QuantityPicker} from "../../../Utils/QuantityPicker";
import * as React from "react";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {DividingHR} from "../../../Utils/DividingHR";
import {FormattedMessage, useIntl} from "react-intl";
import {Image} from "../../../../../../corelogic/models/user";
import {useSelector} from "react-redux";
import {getOrderLinesSelector, getOrderSelectedSelector} from "../../../../../../store/selectors/ordersSelectors";
import AddIcon from '@mui/icons-material/Add';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import InfoIcon from '@mui/icons-material/Info';
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {TextValueField, TextValueFieldMonetary} from "../../../Utils/TextValueField";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {ItemDetailsDialog} from "../../Item/ItemDetailsPage/ItemDetailsDialog";
import {getCustomerSpecificData} from "../../../../../../store/selectors/serverSelector";
import {CustomerSpecificCode} from "../../../../../../corelogic/models/CustomerSpecificData";

interface OrderItemCardInterface {
    data: Item,
    onItemDetailClick: (idItem: number) => void,
    onAddItemToOrderLineClick: (idItem: number, quantity: number, packageSelected: string) => void
}

export const OrderItemCard = (props: OrderItemCardInterface) => {
    const {data, onItemDetailClick, onAddItemToOrderLineClick} = props
    const {dataOrderLines} = useSelector(getOrderLinesSelector)
    const {orderSelected} = useSelector(getOrderSelectedSelector)
    const {userLogged} = useSelector(getUserLoggedSelector)
    const {customerSpecificData} = useSelector(getCustomerSpecificData)
    const [quantity, setQuantity] = useState(1)
    const [quantityErrorModal, setQuantityErrorModal] = useState(false)
    const [imageValue64Format, setImageValue64Format] = useState<string | undefined>("")
    const [isDisabled, setIsDisabled] = useState<boolean>(false)
    const [openItemDetails, setOpenItemDetails] = useState<boolean>(false)
    const setDefaultPackage = () => {
        if (data.packagings.length > 0)
            return data.packagings[0].code
        return ""
    }
    const [packageSelected, setPackageSelected] = useState<string>(setDefaultPackage())
    const intl = useIntl()

    useEffect(() => {
        // On récupère l'avatar de l'user si il en a déjà un en bdd
        if (props.data.picture) {
            //Json stringify l'objet de type File pour pouvoir le manipuler
            const imageObjectToJsonString = JSON.stringify(props.data.picture)
            const imageJsonStringToObject: Image = JSON.parse(imageObjectToJsonString)
            //On envoit la value en base64 dans le use state pour preview image
            setImageValue64Format(imageJsonStringToObject.image)
        }
    }, [imageValue64Format, props.data.picture])

    useEffect(() => {
        if (dataOrderLines) {
            const productToSelect = dataOrderLines.find((item) => item.itemCode === data.code)
            if (productToSelect && productToSelect.quantity) {
                if (productToSelect.quantity >= 999999) {
                    setIsDisabled(true)
                }
            }
        }
    }, [dataOrderLines, data.id, data.code])

    const handleItemDetailsClose = () => {
        setOpenItemDetails(false)
    }

    const handleItemAddClick = () => {
        onAddItemToOrderLineClick(data.id, quantity, packageSelected)
    }

    const handleItemDetailClick = () => {
        onItemDetailClick(data.id)
    }

    const handleItemQuantityChange = (quantity: number) => {
        setQuantity(quantity)
    }

    const handleItemQuantityChangeError = () => {
        setQuantityErrorModal(true)
    }

    const handleChangeQuantityType = (event: SelectChangeEvent) => {
        setPackageSelected(event.target.value)
    }

    return (
        <>
            <Card sx={{width: 300, margin: "4px"}} elevation={5}>
                <Grid container p={1} spacing={1}>
                    <Grid item xs={12}>
                        <Tooltip title={data.label}>
                            <Box>
                                <Typography width="100%" noWrap textAlign="center" fontWeight="bold"
                                            className="text-field-value text-lower-first-letter-up">{data.label}</Typography>
                            </Box>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={4}>
                        <CardMedia
                            component="img"
                            height="80"
                            image={`${process.env.REACT_APP_API_ENDPOINT}img/items/${data.code.replace("/", "_")}.jpg`}
                            onError={({currentTarget}) => {
                                currentTarget.onerror = null
                                currentTarget.src = `${process.env.PUBLIC_URL}/noproduct.jpg`
                            }}
                            alt="green iguana"
                            sx={{marginRight: "5px", width: "80px", borderRadius: "5px"}}
                        />
                    </Grid>
                    <Grid container item xs={8}>
                        <Grid item xs={12}>
                            <Tooltip title={data.code}>
                                <TextValueField
                                    text={intl.formatMessage(getMessageDescriptor("itemDetailPackagingTabFieldCode"))}
                                    value={data.code || ""}/>
                            </Tooltip>
                        </Grid>
                        <Grid item xs={12}>
                            <Tooltip title={data.brand?.label}>
                                <TextValueField
                                    text={intl.formatMessage(getMessageDescriptor("orderVisualisationPageGridOrderLinesItemBrand"))}
                                    value={data.brand?.label || ""}/>
                            </Tooltip>
                        </Grid>
                        {customerSpecificData?.customerSpecificCode === CustomerSpecificCode.SOCOFRAM ?
                            <>
                                <Grid item xs={12}>
                                    <TextValueFieldMonetary
                                        text={intl.formatMessage(getMessageDescriptor("itemDetailRateUnitPrice"))}
                                        decimal={4}
                                        value={data.itemPrice?.genPrice}/>
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={12}>
                                    <TextValueFieldMonetary
                                        text={intl.formatMessage(getMessageDescriptor("itemDetailRateGrossPrice"))}
                                        decimal={4}
                                        value={data.itemPrice?.grossPrice}/>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextValueFieldMonetary
                                        text={intl.formatMessage(getMessageDescriptor("itemDetailRateNetPrice"))}
                                        decimal={4}
                                        value={data.itemPrice?.netPrice}/>
                                </Grid>
                            </>
                        }
                    </Grid>
                    <DividingHR subContent/>
                    <Grid item container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <FormControl fullWidth sx={{width: "190px"}}>
                                <InputLabel>{intl.formatMessage(getMessageDescriptor("orderLineGridColPackageOrdered"))}</InputLabel>
                                <Select
                                    value={packageSelected}
                                    onChange={handleChangeQuantityType}
                                    label={intl.formatMessage(getMessageDescriptor("orderLineGridColPackageOrdered"))}
                                    sx={{height: 35}}>
                                    {data.packagings.map((x) =>
                                        <MenuItem key={x.id} value={x.code}>{x.label}</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                {/*{(data.rateDetails.length > 0 || data.promotionalRateDetails.length > 0 || data.orderSchedule) &&*/}
                                <Grid item>
                                    <IconButton onClick={() => {
                                        setOpenItemDetails(true)
                                    }}>
                                        <InfoIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    </IconButton>
                                </Grid>
                                {/*}*/}
                                <Grid item>
                                    <IconButton onClick={handleItemDetailClick}>
                                        <RemoveRedEyeIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item container alignItems="center" justifyContent="space-between" py={1}>
                        <Grid item>
                            <QuantityPicker sendQuantityProps={handleItemQuantityChange}
                                            sendQuantityPropsError={handleItemQuantityChangeError}/>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" disabled={isDisabled} onClick={handleItemAddClick}
                                    sx={{minWidth: "75px"}}>
                                <AddIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Card>
            {quantityErrorModal &&
                <Dialog
                    maxWidth="lg"
                    open={true}
                >
                    <DialogTitle>
                        <FormattedMessage id={messages.orderCreationModalQuantityErrorInfoTitle.id}/>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Alert
                                severity="warning">{intl.formatMessage(getMessageDescriptor("orderCreationModalQuantityErrorInfo"))}
                            </Alert>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" color="error"
                                onClick={() => setQuantityErrorModal(false)}><FormattedMessage
                            id={messages.genericCancel.id}/></Button>
                    </DialogActions>
                </Dialog>}
            {openItemDetails && <ItemDetailsDialog onClose={handleItemDetailsClose}
                                                   rateDetails={data.rateDetails}
                                                   promotionalRateDetails={data.promotionalRateDetails}
                                                   dateRef={orderSelected?.delayRequested}
                                                   orderSchedule={data.orderSchedule}
                                                   itemPrice={data.itemPrice}
                                                   itemCode={data.code}
                                                   userLogged={userLogged}
                                                   unitCode={data.orderUnit?.code}
            />}
        </>
    )
}