import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {ThunkResult} from "store/appState";
import {ServerGateway} from "../../gateways/ServerGateway";

export const retrieveCustomerSpecificData = ():
    ThunkResult<{ serverGateway: ServerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {serverGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.server.CUSTOMER_SPECIFIC_FETCHING})
        try {
            const customerSpecificData = await serverGateway.retrieveCustomerSpecificData()
            dispatch({type: APP_ACTIONS_TYPES.server.CUSTOMER_SPECIFIC_SELECTING, payload: customerSpecificData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}
export const retrieveServerVersion = ():
    ThunkResult<{ serverGateway: ServerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {serverGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.server.SERVER_VERSION_FETCHING})
        try {
            const serverVersion = await serverGateway.retrieveServerVersion()
            dispatch({type: APP_ACTIONS_TYPES.server.SERVER_VERSION_SELECTING, payload: serverVersion})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}
export const retrieveServerConfig = ():
    ThunkResult<{ serverGateway: ServerGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {serverGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.server.SERVER_CONFIG_FETCHING})
        try {
            const serverConfig = await serverGateway.retrieveServerConfig()
            dispatch({type: APP_ACTIONS_TYPES.server.SERVER_CONFIG_SELECTING, payload: serverConfig})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}