import {HashRouter} from "react-router-dom";
import './App.scss';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import React, {useEffect, useState} from "react";
import {createTheme, CssBaseline, ThemeProvider} from "@mui/material";
import {Main} from "./adapters/primary/Components/Main";
import messages_fr from "./i18n/fr-FR.json";
import messages_en from "./i18n/en.json";
import messages_tr from "./i18n/tr-TR.json";
import messages_es from "./i18n/es-ES.json";
import {enUS as dataGridEnUs, esES as dataGridEsES, frFR as dataGridFrFR, trTR as dataGridTrTR} from '@mui/x-data-grid';
import {
    enUS as pickersEnUs,
    esES as pickersEsES,
    frFR as pickersFrFR,
    trTR as pickersTrTR
} from '@mui/x-date-pickers/locales';
import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import 'moment/locale/tr';
import 'moment/locale/es';
import {IntlProvider} from "react-intl";
import {ToastContainer} from "react-toastify";
import {enUS, esES, frFR, trTR} from "@mui/material/locale";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

export const App = () => {
    const messages = {
        'fr': messages_fr,
        'en': messages_en,
        'tr': messages_tr,
        'es': messages_es
    }

    const localsMui = {
        'fr': [frFR, dataGridFrFR, pickersFrFR],
        'en': [enUS, dataGridEnUs, pickersEnUs],
        'tr': [trTR, dataGridTrTR, pickersTrTR],
        'es': [esES, dataGridEsES, pickersEsES]
    }

    let lang = 'en';
    try {
        lang = navigator.languages.map(language => language.split(/[-_]/)[0]).find(lang => messages[lang]) || 'en';  // language without region code
    } catch (e) {
        console.error("Error determining language: ", e);
    }

    moment.locale(lang);
    const [darkThemeForApp, setDarkThemeForApp] = useState(false)

    const theme = createTheme(
        {
            palette: {
                mode: "light",
                primary: {
                    main: "rgba(33, 150, 243, 1)",
                    dark: "rgb(31,141,229)",
                    light: "rgb(35,157,253)"
                },
                secondary: {
                    main: "rgba(255, 82, 82, 1)",
                    dark: "rgb(232,74,74)",
                    light: "rgb(253,92,92)"
                },
                background: {
                    main: "hsl(0, 0%, 87%)",
                    default: "hsl(0,0%,95%)"
                },
                common: {
                    main: "hsl(0, 0%, 96%)",
                    black: "hsl(0,0%,100%)"
                },
                text: {
                    primary: "#232323",
                    secondary: "#444444"
                }
            }
        },
        ...localsMui[lang]
    )

    const darkTheme = createTheme({
            palette: {
                mode: 'dark',
                primary: {
                    main: "rgba(33, 150, 243, 1)",
                    dark: "rgb(31,141,229)",
                    light: "rgb(35,157,253)"
                },
                secondary: {
                    main: "rgba(255, 82, 82, 1)",
                    dark: "rgb(232,74,74)",
                    light: "rgb(253,92,92)"
                },
                background: {
                    main: "hsl(210, 0%, 10%)",
                    default: "hsl(210,5%,16%)"
                },
                common: {
                    main: "hsl(210, 0%, 13%)",
                    black: "hsl(0,0%,12%)"
                },
                text: {
                    primary: "#fff",
                    secondary: "#d9d9d9",
                    main: "hsl(0,0%,88%)"
                }
            },
        },
        localsMui[lang]
    )

    useEffect(() => {
        const themeSodiwinAppValue = localStorage.getItem("themeSodiwinApp") ?? "light"
        setDarkThemeForApp(themeSodiwinAppValue !== "light")
    }, [])

    return (
        <ThemeProvider theme={darkThemeForApp ? darkTheme : theme}>
            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={lang}>
                <IntlProvider locale={lang} defaultLocale={'en'} messages={messages[lang]}>
                    <React.StrictMode>
                        <HashRouter>
                            <CssBaseline/>
                            <ToastContainer/>
                            <Main/>
                        </HashRouter>
                    </React.StrictMode>
                </IntlProvider>
            </LocalizationProvider>
        </ThemeProvider>
    )
}
