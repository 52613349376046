import {ItemPrice, OrderSchedule, RateDetail} from "../../../../../../corelogic/models/item";
import {User, UserRoleCode} from "../../../../../../corelogic/models/user";
import * as React from "react";
import {FormattedDate, FormattedMessage, FormattedNumber, useIntl} from "react-intl";
import {
    Alert,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Paper,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import {DataGrid} from "@mui/x-data-grid";
import moment from "moment/moment";
import {TextValueField, TextValueFieldDate, TextValueFieldMonetary} from "../../../Utils/TextValueField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import Discount from "@mui/icons-material/Discount";
import History from "@mui/icons-material/History";


interface ItemDetailsDialogProps {
    onClose: () => void
    rateDetails?: RateDetail[]
    promotionalRateDetails?: RateDetail[]
    dateRef?: Date
    orderSchedule?: OrderSchedule
    itemPrice?: ItemPrice
    itemCode: string
    userLogged?: User | null
    unitCode?: string
}

enum DateRange {
    PAST, PRESENT, FUTUR
}

export const ItemDetailsDialog = (props: ItemDetailsDialogProps) => {
    const {
        onClose,
        rateDetails = [],
        promotionalRateDetails = [],
        orderSchedule,
        dateRef = moment().toDate(),
        itemPrice,
        itemCode,
        userLogged,
        unitCode
    } = props
    const [tabIdxSelected, setTabIdxSelected] = React.useState(0)
    const [dateRangeSelected, setDateRangeSelected] = React.useState(DateRange.PRESENT)
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabIdxSelected(newValue);
    }
    const intl = useIntl()
    const theme = useTheme()
    const mdBreakpoint = useMediaQuery(theme.breakpoints.up('md'))

    const handleItemDetailsClose = () => {
        setTabIdxSelected(0)
        setDateRangeSelected(DateRange.PRESENT)
        onClose()
    }

    const handleDateRangeChangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDateRangeSelected(DateRange[DateRange[Number(event.target.value)] as keyof typeof DateRange]);
    }

    const a11yProps = (index: number) => {
        return {
            id: `vertical-tab-${index}`,
            'aria-controls': `vertical-tabpanel-${index}`,
        }
    }

    interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
    }

    const TabPanel = (props: TabPanelProps) => {
        const {children, value, index, ...other} = props;

        return (
            <Box
                role="tabpanel"
                hidden={value !== index}
                id={`vertical-tabpanel-${index}`}
                aria-labelledby={`vertical-tab-${index}`}
                sx={{width: "100%"}}
                {...other}
            >
                {value === index && (
                    <Box sx={{p: 2}}>
                        <Typography>{children}</Typography>
                    </Box>
                )}
            </Box>
        )
    }

    const columRateDetails = (): GridColDef[] => {
        return [{
            field: 'id',
            headerName: "id",
            description: "id",
            headerAlign: "center",
            type: 'number',
            flex: 1,
            sortable: false,
            align: "center"
        }, {
            field: 'minimumQuantity',
            headerName: intl.formatMessage(getMessageDescriptor("rateDetailMinimumQuantity")),
            description: intl.formatMessage(getMessageDescriptor("rateDetailMinimumQuantity")),
            headerAlign: "center",
            type: 'number',
            flex: 1,
            sortable: false,
            align: "center",
            renderCell: (cellValues) => {
                return <FormattedNumber value={cellValues.value}/>

            }
        }, {
            field: 'price',
            headerName: intl.formatMessage(getMessageDescriptor("rateDetailPrice")),
            description: intl.formatMessage(getMessageDescriptor("rateDetailPrice")),
            headerAlign: "center",
            type: 'number',
            flex: 1,
            sortable: false,
            align: "center",
            renderCell: (cellValues) => {
                return cellValues.row?.price ? <FormattedNumber
                        value={cellValues.row?.price || 0}
                        maximumFractionDigits={2}
                        style="currency" currency="EUR"/>
                    : <></>

            }
        }, {
            field: 'discount',
            headerName: intl.formatMessage(getMessageDescriptor("rateDetailDiscount")),
            description: intl.formatMessage(getMessageDescriptor("rateDetailDiscount")),
            headerAlign: "center",
            type: 'number',
            flex: 1,
            sortable: false,
            align: "center",
            renderCell: (cellValues) => {
                return cellValues.row?.discount ?
                    <FormattedNumber value={cellValues.row.discount || 0} maximumFractionDigits={2}
                                     style="percent"/>
                    : <></>
            }
        }, {
            field: 'startDate',
            headerName: intl.formatMessage(getMessageDescriptor("rateDetailStartDate")),
            description: intl.formatMessage(getMessageDescriptor("rateDetailStartDate")),
            headerAlign: "center",
            type: 'number',
            flex: 1,
            sortable: false,
            align: "center",
            renderCell: (cellValues) => {
                return cellValues.row?.startDate ?
                    <FormattedDate value={cellValues.row.startDate}/>
                    : <></>
            }
        }, {
            field: 'endDate',
            headerName: intl.formatMessage(getMessageDescriptor("rateDetailEndDate")),
            description: intl.formatMessage(getMessageDescriptor("rateDetailEndDate")),
            headerAlign: "center",
            type: 'number',
            flex: 1,
            sortable: false,
            align: "center",
            renderCell: (cellValues) => {
                return cellValues.row?.endDate ?
                    <FormattedDate value={cellValues.row.endDate}/>
                    : <></>
            }
        }
        ]
    }

    const CustomNoRowsOverlay = () => {
        return <Stack height={100} m='auto' alignItems="center" justifyContent="center">
            <Alert severity="info">
                {intl.formatMessage(getMessageDescriptor("rateDetailsLinesNoData"))}
            </Alert>
        </Stack>
    }


    const getRateDetailDataGrid = (rateDetails: RateDetail[]) => {
        return <div style={{height: 400, width: '100%'}}>
            <DataGrid columns={[...columRateDetails()]}
                      rows={rateDetails}
                      hideFooter
                      disableColumnFilter
                      disableColumnMenu
                      disableColumnSelector
                      slots={{
                          noRowsOverlay: CustomNoRowsOverlay
                      }}
                      initialState={{
                          columns: {
                              columnVisibilityModel: {
                                  id: false,
                                  price: rateDetails.length > 0 && !!rateDetails.find(x => x.price !== undefined && x.price > 0),
                                  discount: rateDetails.length === 0 || !!rateDetails.find(x => x.discount !== undefined && x.discount > 0)
                              }
                          }
                      }}
            />
        </div>
    }

    const calculateActiveRateDetailAccordingToDate = (rateDetails: RateDetail[], dateRef?: Date) => {
        if (!dateRef)
            return rateDetails
        switch (dateRangeSelected) {
            case DateRange.PAST:
                return rateDetails.filter(rd => rd.endDate && moment(rd.endDate).isBefore(dateRef, 'day'))
            case DateRange.FUTUR:
                return rateDetails.filter(rd => rd.startDate && moment(rd.startDate).isAfter(dateRef, 'day'))
            default:
                return rateDetails.filter(rd => (rd.startDate === undefined && rd.endDate === undefined)
                    || (rd.startDate && !rd.endDate && moment(rd.startDate).isSameOrBefore(dateRef, 'day'))
                    || (rd.endDate && !rd.startDate && moment(rd.endDate).isSameOrAfter(dateRef, 'day'))
                    || (rd.endDate && rd.startDate && moment(dateRef).isBetween(rd.startDate, rd.endDate, 'day'))
                )
        }
    }

    const getDateRangeSelector = () => {
        return <Grid container px={1} mb={1} justifyContent="center">
            <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={dateRangeSelected}
                onChange={handleDateRangeChangeChange}
            >
                <FormControlLabel value={DateRange.PAST} control={<Radio/>} label="Passé"/>
                <FormControlLabel value={DateRange.PRESENT} control={<Radio/>} label="Présent"/>
                <FormControlLabel value={DateRange.FUTUR} control={<Radio/>} label="Futur"/>
            </RadioGroup>
        </Grid>
    }

    return <Dialog
        fullWidth
        maxWidth="md"
        open={true}
        scroll="paper"
        onClose={handleItemDetailsClose}
    >
        <DialogTitle>
            <Grid container>
                <Grid item xs={4}>
                    <FormattedMessage id={getMessageDescriptor("itemInfo").id}/>
                </Grid>
                <Grid item xs={6}>
                    <FormattedMessage id={getMessageDescriptor("priceAsOf").id}/>
                    <span>:</span>
                    <span
                        className="text-field-value">{intl.formatDate(dateRef)}</span>
                </Grid>
            </Grid>
        </DialogTitle>
        <DialogContent>
            <Grid container px={1} mb={1}>
                <Grid item xl={4} xs={4}>
                    <TextValueField
                        text={intl.formatMessage((getMessageDescriptor("item")))}
                        value={itemCode}
                        classNameProp="field-value-no-bottom-margin"/>
                </Grid>
                <Grid item xl={2} xs={4}>
                    <TextValueFieldMonetary
                        text={intl.formatMessage((getMessageDescriptor("itemDetailRateGrossPrice")))}
                        value={itemPrice?.grossPrice || 0}/>
                </Grid>
                <Grid item xl={2} xs={4}>
                    <TextValueField
                        text={intl.formatMessage((getMessageDescriptor("d1")))}
                        value={((Number(itemPrice?.customerRateR1 || 0)) * 100).toFixed(2) + " %"}/>
                </Grid>
                <Grid item xl={2} xs={4}>
                    <TextValueField
                        text={intl.formatMessage((getMessageDescriptor("d2")))}
                        value={((Number(itemPrice?.customerPromotionalRateR2 || 0)) * 100).toFixed(2) + " %"}/>
                </Grid>
                <Grid item xl={2} xs={4}>
                    <TextValueFieldMonetary
                        text={intl.formatMessage((getMessageDescriptor("itemDetailRateNetPrice")))}
                        value={itemPrice?.netPrice || 0}/>
                </Grid>
            </Grid>
            {(rateDetails.length > 0 || promotionalRateDetails.length > 0 || orderSchedule) && <Paper
                sx={{flexGrow: 1, display: 'flex', minHeight: '40vh'}}
            >
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={tabIdxSelected}
                    onChange={handleChange}
                    aria-label="Vertical tabs example"
                    sx={{borderRight: 1, borderColor: 'divider', minWidth: "120px", py: 2}}
                >
                    {rateDetails.length > 0 &&
                        <Tooltip title={intl.formatMessage(getMessageDescriptor("rateDetailRate"))}>
                            <Tab icon={<MonetizationOnIcon/>}
                                 label={mdBreakpoint ? intl.formatMessage(getMessageDescriptor("rateDetailRate")) : undefined} {...a11yProps(0)} />
                        </Tooltip>}
                    {promotionalRateDetails.length > 0 &&
                        <Tooltip
                            title={intl.formatMessage(getMessageDescriptor("rateDetailPromotionalRate"))}>
                            <Tab icon={<Discount/>}
                                 label={mdBreakpoint ? intl.formatMessage(getMessageDescriptor("rateDetailPromotionalRate")) : undefined}
                                 {...a11yProps(rateDetails.length > 0 ? 1 : 0)} />
                        </Tooltip>}
                    {orderSchedule &&
                        <Tooltip title={intl.formatMessage(getMessageDescriptor("itemOrderSchedule"))}>
                            <Tab icon={<History/>}
                                 label={mdBreakpoint ? intl.formatMessage(getMessageDescriptor("itemOrderSchedule")) : undefined}
                                 {...a11yProps((rateDetails.length > 0 && promotionalRateDetails.length > 0) ? 2
                                     : (rateDetails.length > 0 || promotionalRateDetails.length > 0) ? 1 : 0)} />
                        </Tooltip>}
                </Tabs>
                {rateDetails.length > 0 && <TabPanel value={tabIdxSelected} index={0}>
                    {getDateRangeSelector()}
                    {getRateDetailDataGrid(calculateActiveRateDetailAccordingToDate(rateDetails, dateRef))}
                </TabPanel>}
                {promotionalRateDetails.length > 0 &&
                    <TabPanel value={tabIdxSelected} index={rateDetails.length > 0 ? 1 : 0}>
                        {getDateRangeSelector()}
                        {getRateDetailDataGrid(calculateActiveRateDetailAccordingToDate(promotionalRateDetails, dateRef))}
                    </TabPanel>}
                <TabPanel value={tabIdxSelected}
                          index={(rateDetails.length > 0 && promotionalRateDetails.length > 0) ? 2
                              : (rateDetails.length > 0 || promotionalRateDetails.length > 0) ? 1 : 0}>
                    {orderSchedule && <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <TextValueField
                                text="Label"
                                value={orderSchedule?.lastOrderItemLabel}
                                classNameProp="field-value-no-bottom-margin"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValueFieldDate
                                text="Date"
                                value={orderSchedule?.lastOrderDate}
                                classNameProp="field-value-no-bottom-margin"/>
                        </Grid>
                        <Grid item xs={12}>
                            <TextValueField
                                text="Quantité"
                                value={`${orderSchedule?.lastOrderQuantity || '0'} - ${unitCode || ''}`}
                                classNameProp="field-value-no-bottom-margin"/>
                        </Grid>
                        {userLogged?.role?.code !== UserRoleCode.CUSTOMER && <Grid item xs={12}>
                            <TextValueFieldMonetary
                                text="Prix Brut"
                                value={orderSchedule?.lastOrderGrossPrice}/>
                        </Grid>}
                        {userLogged?.role?.code !== UserRoleCode.CUSTOMER && <Grid item xs={12}>
                            <TextValueField
                                text="Remise 1"
                                value={((Number(orderSchedule?.lastOrderDiscount1 || 0)) * 100).toFixed(2) + " %"}/>
                        </Grid>}
                        {userLogged?.role?.code !== UserRoleCode.CUSTOMER && <Grid item xs={12}>
                            <TextValueField
                                text="Remise 2"
                                value={((Number(orderSchedule?.lastOrderDiscount2 || 0)) * 100).toFixed(2) + " %"}/>
                        </Grid>}
                        {userLogged?.role?.code !== UserRoleCode.CUSTOMER && <Grid item xs={12}>
                            <TextValueFieldMonetary
                                text="Prix Net"
                                value={orderSchedule?.lastOrderNetPrice}/>
                        </Grid>}
                    </Grid>}
                </TabPanel>
            </Paper>}
        </DialogContent>
    </Dialog>
}