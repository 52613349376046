import * as React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {Grid} from "@mui/material";
import {InputNumberField} from "./TextValueField";
import {getMessageDescriptor} from "../../../../i18n/messages";
import {FormattedMessage} from "react-intl";

interface QuantityPickerInterface {
    sendQuantityProps: (newQuantity: number) => void
    sendQuantityPropsError: () => void
}

export const QuantityPicker = (props: QuantityPickerInterface) => {
    const [quantity, setQuantity] = React.useState<number>(1)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateQuantity(Number(event.target.value))
    }

    const handleAddClick = () => {
        updateQuantity(quantity + 1)
    }

    const handleRemoveClick = () => {
        updateQuantity(quantity - 1)
    }

    const handleClick = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        e.target.select()
    }

    const updateQuantity = (newQuantity: number) => {
        if (!isNaN(newQuantity) && newQuantity > 0 && newQuantity <= 999999) {
            setQuantity(newQuantity)
            props.sendQuantityProps(newQuantity)
        } else {
            props.sendQuantityPropsError()
        }
    }

    return (
        <Grid container flexWrap="wrap" alignItems="center">
            <Grid item>
                <FormControl sx={{width: '190px', height: "100%"}} variant="outlined"
                             className="form-control-order-creation-cards">
                    <InputLabel htmlFor="outlined-adornment-password">
                        <FormattedMessage id={getMessageDescriptor("orderLineGridColItemQuantity").id}/>
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        value={quantity}
                        onFocus={(e) => handleClick(e)}
                        onChange={handleChange}
                        inputComponent={InputNumberField as any}
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={handleRemoveClick}
                                >
                                    <RemoveIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleAddClick}
                                >
                                    <AddIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Quantité"
                    />
                </FormControl>
            </Grid>
        </Grid>
    );
}

export const QuantityPickerSmaller = (props: QuantityPickerInterface) => {
    const [quantity, setQuantity] = React.useState<number>(1)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        updateQuantity(Number(event.target.value))
    }

    const handleAddClick = () => {
        updateQuantity(quantity + 1)
    }

    const handleRemoveClick = () => {
        updateQuantity(quantity - 1)
    }

    const updateQuantity = (newQuantity: number) => {
        if (!isNaN(newQuantity) && newQuantity >= 1 && newQuantity <= 999999) {
            setQuantity(newQuantity)
            props.sendQuantityProps(newQuantity)
        }
    }

    return (
        <Box sx={{display: 'flex', flexWrap: 'wrap', alignItems: "center"}}>
            <div>
                <FormControl sx={{margin: "5px", width: '21ch', height: "100%"}} variant="outlined"
                             className="form-control-order-creation-cards">
                    <InputLabel htmlFor="outlined-adornment-password">
                        Quantité
                    </InputLabel>
                    <OutlinedInput
                        id="outlined-adornment-password"
                        type="text"
                        value={quantity}
                        onChange={handleChange}
                        startAdornment={
                            <InputAdornment position="start">
                                <IconButton
                                    onClick={handleRemoveClick}
                                >
                                    <RemoveIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={handleAddClick}
                                >
                                    <AddIcon/>
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Quantité"
                    />
                </FormControl>
            </div>
        </Box>
    );
}
