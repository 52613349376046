import {
    EnumData,
    ItemBrand,
    ItemFamily,
    ItemGeographicalOrigin,
    Parameter,
    SuperItemFamily,
    Unit,
    VatType
} from "./parameter";
import {Image} from "./user";

export interface ItemWithCount {
    count: number
    items: Item[]
}

export interface Item {
    id: number
    code: string
    commercialDesignation?: string
    label?: string
    brand?: ItemBrand
    superFamily?: SuperItemFamily
    family?: ItemFamily
    geographicalOrigin?: ItemGeographicalOrigin
    packagings: ItemPackaging[]
    itemPrice?: ItemPrice
    degreeOfAlcohol?: number
    density?: number
    barcodeType?: EnumData
    barcode?: string
    width?: number
    height?: number
    depth?: number
    dimensionUnit?: EnumData
    byHowMuch?: number
    packagePerLayer?: number
    layerPerPallet?: number
    netWeight?: number
    grossWeight?: number
    weightUnit?: EnumData
    orderUnit?: Unit
    vatType?: VatType
    picture?: Image | File
    coef?: number
    bio?: boolean
    priceExTax?: number
    stock?: number
    favorite?: boolean
    authorizationToSellByConsumerUnit?: boolean
    authorizationToSellByPackage?: boolean
    referenced?: boolean
    ingredients?: string
    nutritionalValues?: string
    presentation?: string
    organic?: boolean
    manufactured?: boolean
    sold?: boolean
    managedInStock?: false
    purchased?: true
    blocked?: false
    targetStock?: number
    minimumStock?: number
    safetyStock?: number
    minimumReplenishmentQuantity?: number
    multipleReplenishment?: number
    supplyLeadTime?: number
    publishedInTheCatalog?: boolean
    ecommerceExport?: boolean
    physicalStock?: number
    purchasesOutstanding?: number
    salesOutstanding?: number
    lastImportDatetime?: Date
    orderSchedule?: OrderSchedule
    rateDetails: RateDetail[]
    promotionalRateDetails: RateDetail[]
    deletionDate?: Date
}

export interface ItemPackaging {
    id: number
    code: string
    label: string
    coefficient: number
    authorizedForSale: boolean
}

export interface ItemPackagingForm {
    id?: number
    code: string,
    label: string,
    authorizedForSale: boolean,
    coefficient: number
}

export interface ItemPrice {
    grossPrice: number
    customerRateR1: number
    customerPromotionalRateR2: number
    customerLineDiscountR3: number
    netPrice: number
    genPrice: number
}

export interface OrderSchedule {
    lastOrderDate?: Date
    lastOrderQuantity: number
    lastOrderGrossPrice: number
    lastOrderDiscount1?: number
    lastOrderDiscount2?: number
    lastOrderNetPrice: number
    lastOrderItemLabel: string
}

export interface RateDetail {
    id: number
    price: number
    discount: number
    minimumQuantity: number
    startDate?: Date
    endDate?: Date
}

export interface PaginationFilter {
    size?: number
    page?: number
}

export interface ItemFilter extends PaginationFilter {
    favorite?: boolean
    authorizationToSellOnly?: boolean
    brandCodes: string[]
    familyCodes: string[]
    superFamilyCodes: string[]
    geographicalOriginCodes: string[]
    search: string
    isBlocked?: boolean
    isManagedInStock?: boolean
    isSold?: boolean
    isPurchased?: boolean
    isOrganic?: boolean
    isReferenced?: boolean
    isPublishedInTheCatalog?: boolean
    isExportECommerce?: boolean
    isOrderSchedule?: boolean
    brandIds: number[]
    superFamilyIds: number[]
    familyIds: number[]
    geographicalOriginIds: number[]
}

export class ItemFilterImpl implements ItemFilter {
    favorite?: boolean
    authorizationToSellOnly?: boolean
    brandCodes: string[]
    familyCodes: string[]
    superFamilyCodes: string[]
    geographicalOriginCodes: string[]
    search: string
    isBlocked?: boolean
    isManagedInStock?: boolean
    isSold?: boolean
    isPurchased?: boolean
    isOrganic?: boolean
    isReferenced?: boolean
    isPublishedInTheCatalog?: boolean
    isExportECommerce?: boolean
    isOrderSchedule?: boolean
    brandIds: number[]
    superFamilyIds: number[]
    familyIds: number[]
    geographicalOriginIds: number[]
    size?: number
    page?: number

    constructor(filter: ItemFilter) {
        this.favorite = filter.favorite
        this.authorizationToSellOnly = filter.authorizationToSellOnly
        this.brandCodes = filter.brandCodes
        this.familyCodes = filter.familyCodes
        this.superFamilyCodes = filter.superFamilyCodes
        this.geographicalOriginCodes = filter.geographicalOriginCodes
        this.search = filter.search
        this.isBlocked = filter.isBlocked
        this.isManagedInStock = filter.isManagedInStock
        this.isSold = filter.isSold
        this.isPurchased = filter.isPurchased
        this.isOrganic = filter.isOrganic
        this.isReferenced = filter.isReferenced
        this.isPublishedInTheCatalog = filter.isPublishedInTheCatalog
        this.isExportECommerce = filter.isExportECommerce
        this.isOrderSchedule = filter.isOrderSchedule
        this.brandIds = filter.brandIds
        this.superFamilyIds = filter.superFamilyIds
        this.familyIds = filter.familyIds
        this.geographicalOriginIds = filter.geographicalOriginIds
        this.size = filter.size
        this.page = filter.page
    }

    getFilters(): ItemFilter {
        return {
            favorite: this.favorite,
            authorizationToSellOnly: this.authorizationToSellOnly,
            brandCodes: this.brandCodes,
            familyCodes: this.familyCodes,
            superFamilyCodes: this.superFamilyCodes,
            geographicalOriginCodes: this.geographicalOriginCodes,
            search: this.search,
            isBlocked: this.isBlocked,
            isManagedInStock: this.isManagedInStock,
            isSold: this.isSold,
            isPurchased: this.isPurchased,
            isOrganic: this.isOrganic,
            isReferenced: this.isReferenced,
            isPublishedInTheCatalog: this.isPublishedInTheCatalog,
            isExportECommerce: this.isExportECommerce,
            isOrderSchedule: this.isOrderSchedule,
            brandIds: this.brandIds,
            superFamilyIds: this.superFamilyIds,
            familyIds: this.familyIds,
            geographicalOriginIds: this.geographicalOriginIds
        }
    }

    toSearchParam(): URLSearchParams {
        const params = new URLSearchParams()
        const p: any = {}
        if (this.favorite !== undefined) p.favorite = this.favorite
        if (this.brandCodes && this.brandCodes.length > 0) p.brandCodes = this.brandCodes.join(',')
        if (this.familyCodes && this.familyCodes.length > 0) p.familyCodes = this.familyCodes.join(',')
        if (this.superFamilyCodes && this.superFamilyCodes.length > 0) p.superFamilyCodes = this.superFamilyCodes.join(',')
        if (this.geographicalOriginCodes && this.geographicalOriginCodes.length > 0) p.geographicalOriginCodes = this.geographicalOriginCodes.join(',')
        if (this.search) p.search = this.search
        if (this.isBlocked !== undefined) p.isBlocked = this.isBlocked
        if (this.isManagedInStock !== undefined) p.isManagedInStock = this.isManagedInStock
        if (this.isSold !== undefined) p.isSold = this.isSold
        if (this.isPurchased !== undefined) p.isPurchased = this.isPurchased
        if (this.isOrganic !== undefined) p.isOrganic = this.isOrganic
        if (this.isReferenced !== undefined) p.isReferenced = this.isReferenced
        if (this.isPublishedInTheCatalog !== undefined) p.isPublishedInTheCatalog = this.isPublishedInTheCatalog
        if (this.isExportECommerce !== undefined) p.isExportECommerce = this.isExportECommerce
        if (this.isOrderSchedule !== undefined) p.isOrderSchedule = this.isOrderSchedule
        if (this.brandIds && this.brandIds.length > 0) p.brandIds = this.brandIds.join(',')
        if (this.superFamilyIds && this.superFamilyIds.length > 0) p.superFamilyIds = this.superFamilyIds.join(',')
        if (this.familyIds && this.familyIds.length > 0) p.familyIds = this.familyIds.join(',')
        if (this.geographicalOriginIds && this.geographicalOriginIds.length > 0) p.geographicalOriginIds = this.geographicalOriginIds.join(',')
        let json = JSON.stringify(p)
        if (json !== '{}') {
            const encodedP = btoa(json)
            params.append('pa', encodedP)
        }
        if (this.size !== undefined) params.append('size', String(this.size))
        if (this.page !== undefined) params.append('page', String(this.page))
        return params
    }

    static fromSearchParam(params: URLSearchParams): ItemFilterImpl {
        const encodedP = params.get('pa')
        let p: any = {}
        try {
            if (encodedP !== null)
                p = JSON.parse(atob(encodedP))
        } catch (e) {
            console.error('Failed to decode parameter:', e)
        }
        const filter: ItemFilter = {
            favorite: p.favorite,
            brandCodes: p.brandCodes ? p.brandCodes.split(',') : [],
            familyCodes: p.familyCodes ? p.familyCodes.split(',') : [],
            superFamilyCodes: p.superFamilyCodes ? p.superFamilyCodes.split(',') : [],
            geographicalOriginCodes: p.geographicalOriginCodes ? p.geographicalOriginCodes.split(',') : [],
            search: p.search ? p.search : '',
            isBlocked: p.isBlocked,
            isManagedInStock: p.isManagedInStock,
            isSold: p.isSold,
            isPurchased: p.isPurchased,
            isOrganic: p.isOrganic,
            isReferenced: p.isReferenced,
            isPublishedInTheCatalog: p.isPublishedInTheCatalog,
            isExportECommerce: p.isExportECommerce,
            isOrderSchedule: p.isOrderSchedule,
            brandIds: p.brandIds ? p.brandIds.split(',').map(Number) : [],
            superFamilyIds: p.superFamilyIds ? p.superFamilyIds.split(',').map(Number) : [],
            familyIds: p.familyIds ? p.familyIds.split(',').map(Number) : [],
            geographicalOriginIds: p.geographicalOriginIds ? p.geographicalOriginIds.split(',').map(Number) : [],
            size: params.get('size') ? Number(params.get('size')) : undefined,
            page: params.get('page') ? Number(params.get('page')) : undefined,
        }
        return new ItemFilterImpl(filter)
    }

    count(): number {
        return Object.entries(this)
            .filter(([key, value]) =>
                value !== undefined &&
                key !== 'size' &&
                key !== 'page' &&
                key !== 'superFamilyCodes' &&
                key !== 'geographicalOriginCodes' &&
                key !== 'familyCodes' &&
                key !== 'brandCodes' &&
                key !== 'authorizationToSellOnly' &&
                key !== 'search' &&
                (!Array.isArray(value) || value.length > 0)
            )
            .length;
    }
}

export interface ItemAllFormData {
    brands: Parameter[]
    superFamilies: SuperItemFamily[]
    families: ItemFamily[]
    subFamilies: Parameter[]
    formats: Parameter[]
    subFormats: Parameter[]
    geographicalOrigins: Parameter[]
    originArea: Parameter[]
    types: Parameter[]
    supplyTypes: Parameter[]
    categories: Parameter[]
    subCategories: Parameter[]
    managers: Parameter[]
    packagingTypes: Parameter[]
    vatTypes: Parameter[]
    accountingCodes: Parameter[]
    accountingAnalyticalCodes: Parameter[]
    units: EnumData[]
    weightUnits: EnumData[]
    volumeUnits: EnumData[]
    dimensionUnits: EnumData[]
    batchCodificationTypes: EnumData[]
    valuationTypes: EnumData[]
    inventoryTurnoverClasses: EnumData[]
    calculationMethods: EnumData[]
    barcodeTypes: EnumData[]
    dayTypes: EnumData[]
    rates: EnumData[]
    suppliers: EnumData[]
    items: EnumData[]
}