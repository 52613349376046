import {Moment} from "moment";
import moment from "moment/moment";

export const getDateFromMilliseconds = (dateInMilliseconds?: number): Date | undefined => {
    if (dateInMilliseconds) {
        return new Date(dateInMilliseconds)
    }
}

export const getMillisecondsFromDate = (date?: Date | Moment): number | undefined => {
    if (date) {
        return moment(date).toDate().getTime()
    }
}