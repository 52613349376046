import {AppState} from "store/appState";


export const getServerVersion = (state: AppState) => {
    return {serverVersion: state.server.serverVersion, fetching: state.server.fetchingServerVersion}
}
export const getServerConfig = (state: AppState) => {
    return {serverConfig: state.server.serverConfigData, fetching: state.server.fetchingServerConfigData}
}
export const getCustomerSpecificData = (state: AppState) => {
    return {customerSpecificData: state.server.customerSpecificData, fetching: state.server.fetchingCustomerSpecificData}
}
