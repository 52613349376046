import {BadgeProps, Button, Grid, Tooltip} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {styled} from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../../i18n/messages";
import {DividingHR} from "../../Utils/DividingHR";
import ReturnButton from "../../Utils/ReturnButton";
import {TextValueField} from "../../Utils/TextValueField";
import {useDebounce} from "@uidotdev/usehooks";
import {SearchBar} from "../../Utils/SearchBar";
import {useSearchParams} from "react-router-dom";
import {InvoiceFilterImpl} from "../../../../../corelogic/models/invoice";

const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

interface InvoiceListPageHeaderProps {
    invoicesCount?: number
    onChange?: (input: string) => void
    getToggleFiltersState?: (state: boolean) => void
    filterCount: number
}


export const InvoiceListPageHeader = (props: InvoiceListPageHeaderProps) => {
    const {invoicesCount, onChange, getToggleFiltersState, filterCount} = props
    const intl = useIntl()
    const [toggleFilters, setToggleFilters] = useState<boolean>(true)
    const [searchBarValue, setSearchBarValue] = useState("")
    const delaySearchValue = useDebounce(searchBarValue, 500)
    const [isFirstChange, setIsFirstChange] = useState(true)
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let params = InvoiceFilterImpl.fromSearchParam(searchParams)
        setSearchBarValue(params.searchInput ? params.searchInput : "")
    }, [])

    useEffect(() => {
        if (onChange !== undefined && !isFirstChange) {
            onChange(delaySearchValue)
        }
    }, [onChange, delaySearchValue,isFirstChange])

    const handleOpenFilters = () => {
        setToggleFilters(prevState => !prevState)
        if (getToggleFiltersState) {
            getToggleFiltersState(toggleFilters)
        }
    }

    const handleChangeSearchInput = (e: string) => {
        setSearchBarValue(e)
        setIsFirstChange(false)
    }

    const handleResetSearchBar = () => {
        setSearchBarValue("")
    }

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" p={1}>
                <Grid item>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <ReturnButton/>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item mr={1}>
                            <StyledBadge badgeContent={filterCount} color="error">
                                {toggleFilters ?
                                    <Tooltip
                                        title={intl.formatMessage(getMessageDescriptor("invoiceListFilterOpenTitle"))}>
                                        <Button variant="contained" onClick={handleOpenFilters}>
                                            <MenuIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                        </Button>
                                    </Tooltip>
                                    :
                                    <Tooltip
                                        title={intl.formatMessage(getMessageDescriptor("invoiceListFilterCloseTitle"))}>
                                        <Button variant="contained" onClick={handleOpenFilters}>
                                            <MenuOpenIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                        </Button>
                                    </Tooltip>
                                }
                            </StyledBadge>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <TextValueField
                                style={{
                                    fontWeight: "bolder"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("invoiceListPageHeaderTitle"))}
                                value={invoicesCount}/>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <SearchBar label={intl.formatMessage(getMessageDescriptor("searchBarInvoice"))}
                               onChange={handleChangeSearchInput} onResetClick={handleResetSearchBar}
                               value={searchBarValue}/>
                </Grid>
            </Grid>
        </>
    )
}