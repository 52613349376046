import React, {useEffect, useState} from 'react';
import {
    Button,
    Card,
    CardContent,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogProps,
    DialogTitle,
    Grid,
    Paper,
    Tooltip,
    Typography
} from "@mui/material";
import {DividingHR} from "../../../Utils/DividingHR";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {
    TextValueField,
    TextValueField2,
    TextValueFieldDate,
    TextValueFieldMonetary,
    TextValueFieldNumber
} from "../../../Utils/TextValueField";
import {displayEnumData, displayParameter, truncateStringWithDot} from "../../../Utils/Utils";
import {Barcode} from "../../../Utils/Barcode";
import CropIcon from "@mui/icons-material/Crop";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import InventoryIcon from "@mui/icons-material/Inventory";
import {Image, UserRoleCode} from "../../../../../../corelogic/models/user";
import {EnumData} from "../../../../../../corelogic/models/parameter";
import {Item} from "../../../../../../corelogic/models/item";
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InfoIcon from '@mui/icons-material/Info';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import {FormattedPercentageNumber} from "../../../Utils/FormattedDisplayNumber";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import {useSelector} from "react-redux";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import moment from "moment";
import IconButton from "@mui/material/IconButton";
import {ItemDetailsDialog} from "./ItemDetailsDialog";
import {CustomerSpecificCode} from "../../../../../../corelogic/models/CustomerSpecificData";
import {getCustomerSpecificData} from "../../../../../../store/selectors/serverSelector";

const ItemGeneralInformations = (props: { data: Item }) => {
    const {data} = props
    const {userLogged} = useSelector(getUserLoggedSelector)
    const {customerSpecificData} = useSelector(getCustomerSpecificData)
    const intl = useIntl()
    const [imageValue64Format, setImageValue64Format] = useState<string | undefined>("")
    const [openDialogFieldDetails, setOpenDialogFieldDetails] = React.useState(false)
    const [dialogFieldDetails, setDialogFieldDetails] = React.useState({
        title: "",
        details: ""
    })
    const [scroll, setScroll] = useState<DialogProps['scroll']>('paper')
    const descriptionElementRef = React.useRef<HTMLElement>(null)
    const [openItemRateDetails, setOpenItemRateDetails] = useState<boolean>(false)

    useEffect(() => {
        if (props.data.picture) {
            const imageObjectToJsonString = JSON.stringify(props.data.picture)
            const imageJsonStringToObject: Image = JSON.parse(imageObjectToJsonString)
            setImageValue64Format(imageJsonStringToObject.image)
        }
    }, [imageValue64Format, props.data.picture])

    useEffect(() => {
        if (openDialogFieldDetails) {
            const {current: descriptionElement} = descriptionElementRef
            if (descriptionElement !== null) {
                descriptionElement.focus()
            }
        }
    }, [openDialogFieldDetails])

    const displayMetric = (value?: number, unit?: EnumData) => {
        return value !== undefined && unit ? `${value} (${unit.label})` : ""
    }

    const calculateUvcPerPallet = (uvcPerPackage ?: number, packagePerLayer?: number, layerPerPallet?: number) => {
        const calc = Number(uvcPerPackage) * Number(packagePerLayer) * Number(layerPerPallet)
        return isNaN(calc) ? undefined : calc
    }


    function TextAbstract(text: string | undefined, length: number) {
        if (text === undefined) {
            return "";
        }
        if (text.length <= length) {
            return text;
        }
        text = text.substring(0, length);
        let last = text.lastIndexOf(" ");
        text = text.substring(0, last);
        return text + "...";
    }

    const handleClickOpen = (scrollType: DialogProps['scroll'], field: string, details: string) => () => {
        setDialogFieldDetails({title: field, details: details})
        setOpenDialogFieldDetails(true)
        setScroll(scrollType)
    }

    const handleClose = () => {
        setOpenDialogFieldDetails(false)
    }

    const handleItemDetailsClose = () => {
        setOpenItemRateDetails(false)
    }

    const renderDialog = () => {
        return (
            <Dialog
                open={openDialogFieldDetails}
                onClose={handleClose}
                scroll={scroll}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle id="scroll-dialog-title">
                    {dialogFieldDetails.title === "nutritionalValues" ? intl.formatMessage(getMessageDescriptor("itemListCardFieldNutritionalValues"))
                        : dialogFieldDetails.title === "ingredients" ? intl.formatMessage(getMessageDescriptor("itemListCardFieldPIngredients")) : dialogFieldDetails.title === "presentation" ? intl.formatMessage(getMessageDescriptor("itemListCardFieldPresentation")) : ""}
                </DialogTitle>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText
                        id="scroll-dialog-description"
                        ref={descriptionElementRef}
                        tabIndex={-1}
                    >
                        {dialogFieldDetails.details}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleClose}>Fermer</Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderLongTextFields = () => {
        return <Grid item p={1}>
            <Paper elevation={5}>
                <Grid container direction="column" spacing={1} p={1}>
                    <Grid item alignItems="center">
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <TextValueField
                                    text={intl.formatMessage(getMessageDescriptor("itemListCardFieldPresentation"))}
                                    value={truncateStringWithDot(data.presentation ? data.presentation : "", 0, 45)}/>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="error"
                                        size="small" fullWidth
                                        disabled={!data.presentation}
                                        onClick={handleClickOpen('paper', "presentation", data.presentation || "")}>
                                    {intl.formatMessage(getMessageDescriptor("genericSeeMore"))}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item alignItems="center">
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <TextValueField
                                    text={intl.formatMessage(getMessageDescriptor("itemListCardFieldPIngredients"))}
                                    value={truncateStringWithDot(data.ingredients ? data.ingredients : "", 0, 45)}/>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" color="error"
                                        size="small" fullWidth
                                        disabled={!data.ingredients}
                                        onClick={handleClickOpen('paper', "ingredients", data.ingredients || "")}>
                                    {intl.formatMessage(getMessageDescriptor("genericSeeMore"))}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item alignItems="center">
                        <Grid container justifyContent="space-between">
                            <Grid item>
                                <TextValueField
                                    text={intl.formatMessage(getMessageDescriptor("itemListCardFieldNutritionalValues"))}
                                    value={truncateStringWithDot(data.nutritionalValues ? data.nutritionalValues : "", 0, 45)}/>
                            </Grid>
                            <Grid item pb={1}>
                                <Button variant="contained" color="error"
                                        size="small" fullWidth
                                        disabled={!data.nutritionalValues}
                                        onClick={handleClickOpen('paper', "nutritionalValues", data.nutritionalValues || "")}>
                                    {intl.formatMessage(getMessageDescriptor("genericSeeMore"))}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grid>
    }

    return (
        <>
            <Container maxWidth="xl">
                <Grid container direction="column" spacing={3} p={2}>
                    <Grid container spacing={2} p={1}>
                        <Grid item md={5} sm={12}>
                            <Card elevation={5}>
                                <Grid container alignItems="center" p={1}>
                                    <InsertPhotoIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Tooltip title={data.label}>
                                        <Typography variant="h6" component="h2" fontWeight="bolder">
                                            {TextAbstract(data.label, 34)}
                                        </Typography>
                                    </Tooltip>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <CardContent>
                                    <Grid container justifyContent="center">
                                        <Grid item>
                                            <img alt={data.label} className="detail-content-card-with-picture"
                                                 src={`${process.env.REACT_APP_API_ENDPOINT}img/items/${data.code.replace("/", "_")}.jpg`}
                                                 onError={({currentTarget}) => {
                                                     currentTarget.onerror = null
                                                     currentTarget.src = `${process.env.PUBLIC_URL}/noproduct.jpg`
                                                 }}/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item md={7} sm={12}>
                            <Card elevation={5} sx={{height: "100%"}}>
                                <Grid container p={1} alignItems="center">
                                    <InfoIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.customerPageGeneralInformationTitle.id}/>
                                    </Typography>
                                    {/*<Button variant="contained"><Download/></Button>*/}
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <CardContent>
                                    <Grid container direction="column" spacing={1} p={1}>
                                        <Paper elevation={5}>
                                            <Grid container direction="column" spacing={1} p={1}>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("itemListCardFieldProductCode"))}
                                                        value={data.code}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("itemListCardFieldCommercialDesignation"))}
                                                        value={data.label}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("itemListFilterFieldBrandTitle"))}
                                                        value={displayParameter(data.brand)}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("itemListFilterFieldSuperFamilyTitle"))}
                                                        value={displayParameter(data.superFamily)}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("itemListFilterFieldFamilyTitle"))}
                                                        value={displayParameter(data.family)}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("itemListFilterFieldGeographicalOriginTitle"))}
                                                        value={displayParameter(data.geographicalOrigin)}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField
                                                        text={intl.formatMessage(getMessageDescriptor("itemFieldByHowMuch"))}
                                                        value={data.byHowMuch}/>
                                                </Grid>
                                                <Grid item>
                                                    <TextValueField2
                                                        text={intl.formatMessage(getMessageDescriptor("itemListFilterFieldVatTypeTitle"))}>
                                                        <FormattedPercentageNumber value={data.vatType?.rate || 0}/>
                                                    </TextValueField2>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} p={1}>
                        <Grid item sm={12}>
                            <Card elevation={5}>
                                <Grid container alignItems="center" p={1}>
                                    <LocalMallIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.itemListCommercialInformationsTitle.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={6} sm={12}>
                                            <Grid container direction="column" spacing={1} p={1}>
                                                <Grid item p={1}>
                                                    <Paper elevation={5}>
                                                        <Grid container direction="column" spacing={1} p={1}>
                                                            <Grid item>
                                                                <TextValueField
                                                                    text={intl.formatMessage(getMessageDescriptor("itemListCardFieldOrderingUnits"))}
                                                                    value={data.orderUnit?.label || ""}/>
                                                            </Grid>
                                                            <Grid item>
                                                                <TextValueField
                                                                    text={intl.formatMessage(getMessageDescriptor("itemListCardFieldisBio"))}
                                                                    value={data.organic ?
                                                                        <CheckBoxIcon fontSize="small"/> :
                                                                        <CheckBoxOutlineBlankIcon
                                                                            fontSize="small"/>}/>
                                                            </Grid>
                                                            {!!data.degreeOfAlcohol && <Grid item pb={1}>
                                                                <TextValueField
                                                                    text={intl.formatMessage(getMessageDescriptor("itemListCardFieldAlcoholDegree"))}
                                                                    value={data.degreeOfAlcohol}/>
                                                            </Grid>}
                                                        </Grid>
                                                    </Paper>
                                                </Grid>
                                                {userLogged?.role?.code !== UserRoleCode.CUSTOMER ? <>
                                                        <Grid item p={1}>
                                                            <Paper elevation={5}>
                                                                <Grid container direction="column" spacing={1}
                                                                      p={1}>
                                                                    <Grid item>
                                                                        <TextValueField
                                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldAuthorizationToSellByConsumerUnit"))}
                                                                            value={data.authorizationToSellByConsumerUnit ?
                                                                                <CheckBoxIcon fontSize="small"/> :
                                                                                <CheckBoxOutlineBlankIcon
                                                                                    fontSize="small"/>}/>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <TextValueField
                                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldAuthorizationToSellByPackage"))}
                                                                            value={data.authorizationToSellByPackage ?
                                                                                <CheckBoxIcon fontSize="small"/> :
                                                                                <CheckBoxOutlineBlankIcon
                                                                                    fontSize="small"/>}/>
                                                                    </Grid>
                                                                    <Grid item>
                                                                        <TextValueField
                                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldSold"))}
                                                                            value={data.sold ?
                                                                                <CheckBoxIcon fontSize="small"/> :
                                                                                <CheckBoxOutlineBlankIcon
                                                                                    fontSize="small"/>}/>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item p={1}>
                                                            <Paper elevation={5}>
                                                                <Grid container justifyContent={"space-between"}>
                                                                    <Grid container md={4} direction="column"
                                                                          spacing={1}
                                                                          p={1}>
                                                                        <Grid item container alignItems="center">
                                                                            <TextValueField
                                                                                text={intl.formatMessage(getMessageDescriptor("itemListCardFieldPurchased"))}
                                                                                value={data.purchased ?
                                                                                    <CheckBoxIcon fontSize="small"/> :
                                                                                    <CheckBoxOutlineBlankIcon
                                                                                        fontSize="small"/>}/>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TextValueField
                                                                                text={intl.formatMessage(getMessageDescriptor("itemListCardFieldBlocked"))}
                                                                                value={data.blocked ?
                                                                                    <CheckBoxIcon fontSize="small"/> :
                                                                                    <CheckBoxOutlineBlankIcon
                                                                                        fontSize="small"/>}/>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TextValueField
                                                                                text={intl.formatMessage(getMessageDescriptor("itemListCardFieldManagedInStock"))}
                                                                                value={data.managedInStock ?
                                                                                    <CheckBoxIcon fontSize="small"/> :
                                                                                    <CheckBoxOutlineBlankIcon
                                                                                        fontSize="small"/>}/>
                                                                        </Grid>
                                                                    </Grid>
                                                                    <Grid container md={8} direction="column"
                                                                          spacing={1}
                                                                          p={1}>
                                                                        <Grid item container alignItems="center">
                                                                            <TextValueField
                                                                                text={intl.formatMessage(getMessageDescriptor("itemListCardFieldPublishedInCatalog"))}
                                                                                value={data.publishedInTheCatalog ?
                                                                                    <CheckBoxIcon fontSize="small"/> :
                                                                                    <CheckBoxOutlineBlankIcon
                                                                                        fontSize="small"/>}/>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TextValueField
                                                                                text={intl.formatMessage(getMessageDescriptor("itemListCardFieldExportECommerce"))}
                                                                                value={data.ecommerceExport ?
                                                                                    <CheckBoxIcon fontSize="small"/> :
                                                                                    <CheckBoxOutlineBlankIcon
                                                                                        fontSize="small"/>}/>
                                                                        </Grid>
                                                                        <Grid item>
                                                                            <TextValueFieldDate
                                                                                text={intl.formatMessage(getMessageDescriptor("deletionDate"))}
                                                                                value={data.deletionDate}
                                                                                classNameProp="field-value-no-bottom-margin"/>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </Grid>
                                                    </>
                                                    : renderLongTextFields()
                                                }
                                            </Grid>
                                        </Grid>
                                        <Grid item md={6} sm={12}>
                                            <Grid container direction="column" spacing={1} p={1}>
                                                {userLogged?.role?.code !== UserRoleCode.CUSTOMER &&
                                                    renderLongTextFields()
                                                }
                                                <Grid item flexGrow={1} p={1}>
                                                    <Paper elevation={5} sx={{height: "100%"}}>
                                                        <Grid container direction="column" spacing={1} p={1}>
                                                            <Grid item>
                                                                <TextValueField
                                                                    text={intl.formatMessage(getMessageDescriptor("itemListCardFieldbareCodeType"))}
                                                                    value={displayEnumData(data.barcodeType) || ""}/>
                                                            </Grid>
                                                            <Grid item pb={!data.barcode ? 1 : 0}>
                                                                <TextValueField
                                                                    text={intl.formatMessage(getMessageDescriptor("itemListCardFieldbareCode"))}
                                                                    value={data.barcode || ""}/>
                                                            </Grid>
                                                            {data.barcode &&
                                                                <Grid item p={1}>
                                                                    <Grid container justifyContent="space-evenly">
                                                                        <Barcode value={data.barcode}
                                                                                 options={{
                                                                                     height: 80,
                                                                                     fontSize: 16,
                                                                                     fontOptions: "bold",
                                                                                 }}/>
                                                                    </Grid>
                                                                </Grid>}
                                                        </Grid>
                                                    </Paper>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} p={1}>
                        <Grid item md={12}>
                            <Card elevation={5}>
                                <Grid container alignItems="center" p={1}>
                                    <CropIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        <FormattedMessage id={messages.itemTitleDimensions.id}/>
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item md={4} sm={12}>
                                            <Paper elevation={5}>
                                                <Grid container direction="column" spacing={1} p={1}>
                                                    <Grid item>
                                                        <TextValueField
                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldWidth"))}
                                                            value={displayMetric(data.width, data.dimensionUnit)}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextValueField
                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldHeight"))}
                                                            value={displayMetric(data.height, data.dimensionUnit)}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextValueField
                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldDepth"))}
                                                            value={displayMetric(data.depth, data.dimensionUnit)}/>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        <Grid item md={4} sm={12}>
                                            <Paper elevation={5}>
                                                <Grid container direction="column" spacing={1} p={1}>
                                                    <Grid item>
                                                        <TextValueField
                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldNumberOfPackagesPerLayer"))}
                                                            value={data.packagePerLayer}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextValueField
                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldNumberOfLayersPerPallet"))}
                                                            value={data.layerPerPallet}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextValueField
                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldNumberOfUvcPerPallet"))}
                                                            value={calculateUvcPerPallet(data.byHowMuch, data.packagePerLayer, data.layerPerPallet)}/>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                        <Grid item md={4} sm={12}>
                                            <Paper elevation={5}>
                                                <Grid container direction="column" spacing={1} p={1}>
                                                    <Grid item>
                                                        <TextValueField
                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldWeightNet"))}
                                                            value={displayMetric(data.netWeight, data.weightUnit)}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextValueField
                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldWeightBrut"))}
                                                            value={displayMetric(data.grossWeight, data.weightUnit)}/>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextValueField
                                                            text={intl.formatMessage(getMessageDescriptor("itemListCardFieldDensity"))}
                                                            value={data.density}/>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2} p={1}>
                        <Grid item xs={12} md={5}>
                            <Card elevation={5} sx={{height: "100%"}}>
                                <Grid container justifyContent="space-between" alignItems="center" p={1}>
                                    <Grid container item xs={11} alignItems="center">
                                        <DiscountOutlinedIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                        <Typography variant="h6" component="h2" fontWeight="bolder">
                                            <FormattedMessage id={messages.itemTitleDiscount.id}/>
                                        </Typography>
                                    </Grid>
                                    {(data.orderSchedule || (data.rateDetails && data.rateDetails.length > 0) || (data.promotionalRateDetails && data.promotionalRateDetails.length > 0)) &&
                                        <Grid item xs={1} alignItems="center">
                                            <IconButton onClick={() => {
                                                setOpenItemRateDetails(true)
                                            }}>
                                                <InfoIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                            </IconButton>
                                        </Grid>}
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <CardContent>
                                    <Grid container>
                                        <Grid item sm={12}>
                                            <Paper elevation={5}>
                                                <Grid container p={1}>
                                                    <Grid item xs={12}>
                                                        <TextValueFieldMonetary
                                                            text={intl.formatMessage(getMessageDescriptor("itemDetailRateGrossPrice"))}
                                                            value={data.itemPrice?.grossPrice} currency="€"/>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Card elevation={5} sx={{height: "100%"}}>
                                <Grid container alignItems="center" p={1}>
                                    <InventoryIcon style={{fill: "rgba(33, 150, 243, 1)", marginRight: 10}}/>
                                    <Typography variant="h6" component="h2" fontWeight="bolder">
                                        {userLogged?.role?.code !== UserRoleCode.CUSTOMER ?
                                            <FormattedMessage id={messages.itemDetailStockToDateWithDate.id}
                                                              values={{date: moment(data.lastImportDatetime).format("DD/MM/YYYY HH:mm:ss")}}/>
                                            : <FormattedMessage id={messages.itemDetailStockToDateWithoutDate.id}/>
                                        }
                                    </Typography>
                                </Grid>
                                <DividingHR style={{marginTop: 0}}/>
                                <CardContent>
                                    <Grid container>
                                        <Grid item sm={12}>
                                            <Paper elevation={5}>
                                                <Grid container direction="column" spacing={1} p={1}>
                                                    <Grid item>
                                                        {userLogged?.role?.code !== UserRoleCode.CUSTOMER ?
                                                            <TextValueFieldNumber
                                                                text={intl.formatMessage(getMessageDescriptor("itemDetailPhysicalStock"))}
                                                                value={data.physicalStock}/>
                                                            :
                                                            <TextValueField
                                                                text={intl.formatMessage(getMessageDescriptor("itemDetailPhysicalStock"))}
                                                                value={data.physicalStock && data.physicalStock > 0 ?
                                                                    <CheckBoxIcon fontSize="small"/> :
                                                                    <CheckBoxOutlineBlankIcon
                                                                        fontSize="small"/>}/>
                                                        }
                                                    </Grid>
                                                    {userLogged?.role?.code !== UserRoleCode.CUSTOMER &&
                                                        <>
                                                            {customerSpecificData?.customerSpecificCode !== CustomerSpecificCode.SOCOFRAM &&
                                                                <Grid item>
                                                                    <TextValueFieldNumber
                                                                        text={intl.formatMessage(getMessageDescriptor("itemDetailSalesOutstanding"))}
                                                                        value={data.salesOutstanding}/>
                                                                </Grid>
                                                            }
                                                            <Grid item>
                                                                <TextValueFieldNumber
                                                                    text={intl.formatMessage(getMessageDescriptor("itemDetailPurchasesOutstanding"))}
                                                                    value={data.purchasesOutstanding}/>
                                                            </Grid>
                                                        </>
                                                    }
                                                </Grid>
                                            </Paper>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
            {
                openDialogFieldDetails && renderDialog()
            }
            {openItemRateDetails && <ItemDetailsDialog onClose={handleItemDetailsClose}
                                                       rateDetails={data.rateDetails}
                                                       promotionalRateDetails={data.promotionalRateDetails}
                                                       orderSchedule={data.orderSchedule}
                                                       itemPrice={data.itemPrice}
                                                       itemCode={data.code}
                                                       userLogged={userLogged}
                                                       unitCode={data.orderUnit?.code}
            />}
        </>
    )
}

export default ItemGeneralInformations;