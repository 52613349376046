import {EnumData, Parameter} from "../../../../../../corelogic/models/parameter";
import {Image, User, UserAllFormData, UserRoleCode, UserRoleUtils} from "../../../../../../corelogic/models/user";
import React, {useEffect, useRef, useState} from "react";
import * as yup from "yup";
import {Control, Controller, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {
    Autocomplete,
    Avatar,
    Button,
    Checkbox,
    FormControlLabel,
    FormLabel,
    Grid,
    Input,
    Paper,
    Radio,
    RadioGroup,
    TextField,
    Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {toast} from "react-toastify";
import {DividingHR} from "../../../Utils/DividingHR";
import EditIcon from "@mui/icons-material/Edit";
import {useSelector} from "react-redux";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {useNavigate} from "react-router-dom";

type UserFormData = {
    login: string
    mail: string
    lastName: string
    firstName: string
    language: Parameter
    status: EnumData
    role: Parameter
    representative: Parameter | null
    linkedCustomer: Parameter | null
    sendEmailAtOrderSubmission: boolean
    defaultOrderUnitPackage: number
    avatar?: File | Image | string
}

interface UserGeneralInformationFormInterface {
    data: User,
    dataFormFields: UserAllFormData,
    onValidate: (form: User) => void
}

export const UserGeneralInformationForm = (props: UserGeneralInformationFormInterface) => {
    const {data, dataFormFields, onValidate} = props
    const roleAuthorized = useRef<Parameter[]>([])
    const {userLogged} = useSelector(getUserLoggedSelector)
    const intl = useIntl()
    const navigate = useNavigate()
    const [form, setForm] = useState<User>({
        ...data
    })
    const [disableField, setDisableField] = useState({
        linkedCustomer: form.role?.code === UserRoleCode.SODIWIN || form.role?.code === UserRoleCode.ADMIN || form.role?.code === UserRoleCode.COMMERCIAL,
        representative: form.role?.code === UserRoleCode.SODIWIN || form.role?.code === UserRoleCode.ADMIN || form.role?.code === UserRoleCode.CUSTOMER
    })
    const theme = useTheme();
    const lgBreakpoint = useMediaQuery(theme.breakpoints.up('lg'));
    const schemaValidationGeneralInformation = yup.object().shape({
        login: yup.string().required("Please enter your login").min(4).max(10),
        mail: yup.string().email().required("Please enter your email"),
        lastName: yup.string().required("Please enter your name"),
        firstName: yup.string().required("Please enter your firstName"),
        language: yup.object().required("Please choose a language").typeError("Language can't be of type null"),
        status: yup.object().required("Please choose a status").typeError("Status can't be of type null"),
        role: yup.object().required("Please choose a role").typeError("Role can't be of type null"),
        linkedCustomer: yup.object().nullable()
            .when("role", (role: Parameter, schema: any) => {
                if (role?.code === UserRoleCode.CUSTOMER) {
                    return yup.object().required("Field required for the CUSTOMER role").nullable()
                }
                return schema
            }),
        representative: yup.object().nullable(),
        sendEmailAtOrderSubmission: yup.boolean(),
        defaultOrderUnitPackage: yup.number(),
        avatar: yup.object().nullable()
    })

    const {
        handleSubmit,
        formState: {errors, isValid, isDirty,},
        control,
        watch,
        setValue,
    } = useForm<UserFormData>(
        {
            mode: "onSubmit",
            resolver: yupResolver(schemaValidationGeneralInformation),
            defaultValues: {
                login: form.login,
                mail: form.mail,
                lastName: form.lastName,
                firstName: form.firstName,
                language: form.language,
                status: form.status,
                role: form.role,
                representative: form.representative ?? null,
                linkedCustomer: form.linkedCustomer ?? null,
                sendEmailAtOrderSubmission: form.sendEmailAtOrderSubmission,
                defaultOrderUnitPackage: form.defaultOrderUnitPackage === undefined ? -1 : form.defaultOrderUnitPackage ? 1 : 0,
                avatar: form.avatar
            }
        }
    )

    const watchRole = watch("role", data.role)
    useEffect(() => {
        if (watchRole?.code === UserRoleCode.ADMIN) {
            setDisableField({linkedCustomer: true, representative: true})
            setValue("linkedCustomer", null)
            setValue("representative", null)
        } else if (watchRole?.code === UserRoleCode.COMMERCIAL) {
            setDisableField({linkedCustomer: true, representative: false})
            setValue("linkedCustomer", null)
        } else if (watchRole?.code === UserRoleCode.CUSTOMER) {
            setDisableField({linkedCustomer: false, representative: true})
            setValue("representative", null)
        } else {
            setDisableField({linkedCustomer: true, representative: true})
            setValue("linkedCustomer", null)
            setValue("representative", null)
        }
    }, [form, setValue, watchRole]);

    useEffect(() => {
        roleAuthorized.current = dataFormFields.roles.filter(role => role.code !== UserRoleCode.SODIWIN)
    }, [dataFormFields.roles])

    const onSubmit: SubmitHandler<UserFormData> = (dataForm) => {
        onValidate({
            ...form,
            login: dataForm.login,
            mail: dataForm.mail,
            lastName: dataForm.lastName,
            firstName: dataForm.firstName,
            language: dataForm.language,
            role: dataForm.role,
            status: dataForm.status,
            sendEmailAtOrderSubmission: dataForm.sendEmailAtOrderSubmission,
            representative: dataForm.representative,
            linkedCustomer: dataForm.linkedCustomer,
            defaultOrderUnitPackage: dataForm.defaultOrderUnitPackage === -1 ? undefined : dataForm.defaultOrderUnitPackage === 1,
        })
    }

    const handleChangeAvatar = (avatar: File) => {
        setValue("avatar", avatar)
        setForm({...form, avatar: avatar})
    }

    const handleReset = () => {
        if (UserRoleUtils.isAdmin(userLogged)) {
            navigate("/users")
        } else {
            navigate("/")
        }
    }

    return (
        <>
            <Paper elevation={3} component="form" onSubmit={handleSubmit(onSubmit)} onReset={handleReset}
                   sx={{p: 2, m: 2, height: "100%"}}>
                <Grid container>
                    <Grid item>
                        <InfoIcon sx={{fill: "rgba(33, 150, 243, 1)", marginRight: "10px"}}/>
                    </Grid>
                    <Grid item>
                        <Typography fontWeight="bolder" fontSize="1.2rem">
                            <FormattedMessage id={messages.userPageFormGeneralInformationTitle.id}/>
                        </Typography>
                    </Grid>
                </Grid>
                <DividingHR/>
                <Grid container spacing={2} p={1}>
                    <Grid item xs={12} md={4}>
                        <UserAvatarForm data={data} onChange={handleChangeAvatar} control={control}/>
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Paper sx={{p: 1}}>
                            <Grid container spacing={4} p={2}>
                                <Grid item xs={6}>
                                    <Controller
                                        name="login"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                fullWidth
                                                required
                                                variant="standard"
                                                disabled={!UserRoleUtils.isAdmin(userLogged)}
                                                label={intl.formatMessage((getMessageDescriptor("userPageFormFieldLogin")))}
                                                placeholder={intl.formatMessage((getMessageDescriptor("userPageFormFieldLogin"))) + "..."}
                                                type="text"
                                                error={!!errors.login}
                                                helperText={errors.login?.message}/>
                                        }
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="mail"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                required
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage((getMessageDescriptor("userPageFormFieldEmail")))}
                                                placeholder={intl.formatMessage((getMessageDescriptor("userPageFormFieldEmail"))) + "..."}
                                                type="email"
                                                error={!!errors.mail}
                                                helperText={errors.mail?.message}/>}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="lastName"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                required
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage((getMessageDescriptor("userPageFormFieldName")))}
                                                placeholder={intl.formatMessage((getMessageDescriptor("userPageFormFieldName"))) + "..."}
                                                type="text"
                                                error={!!errors.lastName}
                                                helperText={errors.lastName?.message}/>}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="firstName"
                                        control={control}
                                        render={({field}) =>
                                            <TextField
                                                {...field}
                                                required
                                                fullWidth
                                                variant="standard"
                                                label={intl.formatMessage((getMessageDescriptor("userPageFormFieldFirstName")))}
                                                placeholder={intl.formatMessage((getMessageDescriptor("userPageFormFieldFirstName"))) + "..."}
                                                type="text"
                                                error={!!errors.firstName}
                                                helperText={errors.firstName?.message}/>}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        name="language"
                                        control={control}
                                        render={({field: {onChange, value}}) =>
                                            <Autocomplete
                                                fullWidth
                                                options={dataFormFields.languages}
                                                onChange={(event, country) => {
                                                    onChange(country)
                                                }}
                                                value={value}
                                                getOptionLabel={(option => option.label || "")}
                                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                                renderInput={(params) => <TextField {...params}
                                                                                    label={intl.formatMessage((getMessageDescriptor("userPageFormFieldLanguage")))}
                                                                                    variant="standard"
                                                                                    error={!!errors.language}
                                                                                    helperText={errors.language?.message}/>}
                                            />}
                                    />
                                </Grid>
                                {UserRoleUtils.isAdmin(userLogged) &&
                                    <>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="status"
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <Autocomplete
                                                        options={dataFormFields.statuses}
                                                        onChange={(event, status) => {
                                                            onChange(status)
                                                        }}
                                                        value={value}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            required
                                                                                            label={intl.formatMessage((getMessageDescriptor("userPageFormFieldStatus")))}
                                                                                            variant="standard"
                                                                                            error={!!errors.status}
                                                                                            helperText={errors.status?.message}/>}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="role"
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <Autocomplete
                                                        disabled={UserRoleUtils.isSodiwin(userLogged) && userLogged?.id === form.id}
                                                        options={roleAuthorized.current}
                                                        onChange={(event, role) => {
                                                            onChange(role)
                                                        }}
                                                        value={value}
                                                        getOptionLabel={(option => option.label || "")}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            required
                                                                                            label={intl.formatMessage((getMessageDescriptor("userPageFormFieldRole")))}
                                                                                            variant="standard"
                                                                                            error={!!errors.role}
                                                                                            helperText={errors.role?.message}/>}
                                                    />
                                                }
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="representative"
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <Autocomplete
                                                        disabled={disableField.representative}
                                                        options={dataFormFields.representatives}
                                                        onChange={(event, representative) => {
                                                            onChange(representative)
                                                        }}
                                                        value={value || null}
                                                        getOptionLabel={(option => `${option.label || ""} (${option.code || ""})`)}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage((getMessageDescriptor("userPageFormFieldRepresentative")))}
                                                                                            variant="standard"
                                                                                            error={!!errors.representative}
                                                                                            helperText={errors.representative?.message}/>}
                                                    />}
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <Controller
                                                name="linkedCustomer"
                                                control={control}
                                                render={({field: {onChange, value}}) =>
                                                    <Autocomplete
                                                        disabled={disableField.linkedCustomer}
                                                        options={dataFormFields.customers}
                                                        onChange={(event, country) => {
                                                            onChange(country)
                                                        }}
                                                        value={value || null}
                                                        getOptionLabel={(option => `${option.label || ""} (${option.code || ""})`)}
                                                        isOptionEqualToValue={(option, value) => option.id === value.id}
                                                        renderInput={(params) => <TextField {...params}
                                                                                            label={intl.formatMessage((getMessageDescriptor("userPageFormFieldLinkedCustomer")))}
                                                                                            variant="standard"
                                                                                            error={!!errors.linkedCustomer}
                                                                                            helperText={errors.linkedCustomer?.message}/>}
                                                    />}
                                            />
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} xl={6}>
                                    <FormControlLabel
                                        label={intl.formatMessage(getMessageDescriptor("userPageFormFieldSendEmailAtOrderSubmission"))}
                                        control={
                                            <Controller
                                                name="sendEmailAtOrderSubmission"
                                                control={control}
                                                render={({field: props}) => (
                                                    <Checkbox
                                                        {...props}
                                                        checked={props.value}
                                                        onChange={(e) => props.onChange(e.target.checked)}
                                                    />
                                                )}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name="defaultOrderUnitPackage"
                                        control={control}
                                        render={({field}) => (
                                            <>
                                                <FormLabel>
                                                    {intl.formatMessage(getMessageDescriptor("userPageFormFieldDefaultOrderUnitPackageLabel"))}
                                                </FormLabel>
                                                <RadioGroup
                                                    {...field}
                                                    row={lgBreakpoint}
                                                    value={field.value ?? null}
                                                >
                                                    <FormControlLabel value="0" control={<Radio/>}
                                                                      label= {intl.formatMessage(getMessageDescriptor("userPageFormFieldDefaultOrderUnitPackageItemUnit"))}/>
                                                    <FormControlLabel value="1" control={<Radio/>}
                                                                      label= {intl.formatMessage(getMessageDescriptor("userPageFormFieldDefaultOrderUnitPackagePackage"))}/>
                                                    <FormControlLabel value="-1" control={<Radio/>}
                                                                      label= {intl.formatMessage(getMessageDescriptor("userPageFormFieldDefaultOrderUnitPackageSocietyDefault"))}/>
                                                </RadioGroup>
                                            </>
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container justifyContent="flex-end" spacing={2}>
                                        <Grid item>
                                            <Button variant="contained" color="error" type="reset">
                                                <FormattedMessage id={messages.genericCancel.id}/>
                                            </Button>
                                        </Grid>
                                        <Grid item>
                                            <Button variant="contained" type="submit"
                                                    disabled={!isValid || !(isValid && (isDirty))}>
                                                <FormattedMessage id={messages.genericConfirm.id}/>
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
        </>
    )
}

interface UserAvatarFormInterface {
    data: User,
    onChange: (userAvatar: File) => void,
    control: Control<UserFormData>
}

const UserAvatarForm = (props: UserAvatarFormInterface) => {
    const {data, onChange, control} = props
    const intl = useIntl()
    const [imageDisplayed, setImageDisplayed] = useState<string>(`${process.env.REACT_APP_API_ENDPOINT}img/${data.avatar}`)
    const [imageFileOnChange, setImageFileOnChange] = useState<Blob>()

    useEffect(() => {
        if (imageFileOnChange) {
            const reader = new FileReader()
            reader.onloadend = () => {
                setImageDisplayed(reader.result as string)
            }
            reader.readAsDataURL(imageFileOnChange)
        }
    }, [imageFileOnChange])

    const handleChangeImage = (e: any) => {
        const imageChoosedByUser = e.target.files[0]
        //On récupère l'objet de type File choisie par le user
        const file = imageChoosedByUser
        //On vérifie que c'est bien une image
        if (file && file.type.substring(0, 5) === "image") {
            //Envois au parent de l'objet type File brut
            onChange(imageChoosedByUser)
            //Maj state pour preview
            setImageFileOnChange(imageChoosedByUser)
        } else {
            toast.error(intl.formatMessage((getMessageDescriptor("userPageFormAvatarUploadError"))))
        }
    }

    return (
        <Paper sx={{p: 2, height: "100%"}}>
            <Grid container direction="column" justifyContent="space-around" alignItems="center" p={3} spacing={3}
                  height="100%">
                <Grid item>
                    <Avatar alt={data?.firstName + " " + data?.lastName}
                            src={imageDisplayed}
                            sx={{
                                width: "200px", height: "235px", fontSize: "140px"
                            }}/>
                </Grid>
                <Grid item>
                    <Controller
                        name="avatar"
                        control={control}
                        render={({field}) => (
                            <label htmlFor="contained-button-file">
                                <Input {...field} id="contained-button-file" type="file"
                                       inputProps={{accept: "image/*"}}
                                       value={null}
                                       onChange={(e) => {
                                           handleChangeImage(e)
                                           field.onChange(e)
                                       }}
                                       sx={{display: "none"}}/>
                                <Button variant="contained" component="span" sx={{margin: "10px"}}>
                                    <EditIcon sx={{margin: "0px 10px 0px 0px"}}/>
                                    <FormattedMessage id={messages.genericChange.id}/>
                                </Button>
                            </label>
                        )}
                    />
                </Grid>
            </Grid>
        </Paper>
    )
}