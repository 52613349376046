import React from 'react';
import {Box} from "@mui/material";
import {DataGrid, GridValueGetterParams} from "@mui/x-data-grid";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {StatisticTopItemValues} from "../../../../../corelogic/models/stats";
import {FormattedNumber, useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../../i18n/messages";

interface TopTenItemByTotalWT {
    data?: StatisticTopItemValues[]
    fetching?:boolean
}

export const GridStatsTopTenItemByTotalWT = (props: TopTenItemByTotalWT) => {
    const {data = [], fetching} = props
    const intl = useIntl()
    const columns: GridColDef[] = [
        {field: 'id', hideable: false},
        {field: 'code', headerName: intl.formatMessage(getMessageDescriptor("statsGridCode")), flex: 0.8},
        {
            field: 'label',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridLabel")),
            headerAlign: 'left',
            align: 'left',
            flex: 1.1,
        },
        {
            field: 'quantity',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridQuantity")),
            headerAlign: 'left',
            align: 'left',
            type: 'number',
            flex: 0.6
        },
        {
            field: 'unit',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridUnit")),
            headerAlign: 'left',
            align: 'left',
            flex: 0.5,
        },
        {
            hideable: false,
            field: 'grossPrice',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridGrossPrice")),
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.grossPrice} €`,
        },
        {
            field: 'averagePrice',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridAveragePrice")),
            headerAlign: 'left',
            align: 'left',
            type: 'number',
            flex: 0.7,
            renderCell: (cellValues) => {
                return (
                    <Box>
                        {cellValues.row.averagePrice && <FormattedNumber
                            value={cellValues.row.averagePrice}
                            maximumFractionDigits={2}
                            style={"currency"} currency={"EUR"}/>}
                    </Box>
                )
            }
        },
        {
            field: 'totalWithoutTax',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridTotalExclTax")),
            headerAlign: 'left',
            align: 'left',
            description: 'This column has a value getter and is not sortable.',
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <Box>
                        {cellValues.row.totalWithoutTax && <FormattedNumber
                            value={cellValues.row.totalWithoutTax}
                            maximumFractionDigits={2}
                            style={"currency"} currency={"EUR"}/>}
                    </Box>
                )
            }
        },
        {
            field: 'percentageTotal',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridPercentage")),
            headerAlign: 'left',
            align: 'left',
            description: 'This column has a value getter and is not sortable.',
            flex: 0.7,
            renderCell: (cellValues) => {
                return (
                    <Box>
                        {cellValues.row.percentageTotal && <FormattedNumber
                            value={cellValues.row.percentageTotal}
                            maximumFractionDigits={2}
                            style={"percent"}/>}
                    </Box>
                )
            }
        }
    ]

    return (
        <Box sx={{height: "260px", width: '100%'}}>
            <DataGrid
                rows={data}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                            grossPrice: false
                        }
                    },
                    sorting: {
                        sortModel: [{ field: 'totalWithoutTax', sort: 'desc' }],
                    }
                }}
                disableColumnMenu
                getRowId={(row) => row.code}
                columns={columns.map(col => ({
                    ...col,
                    sortable: false
                }))}
                density="compact"
                hideFooter
                loading={fetching}
                disableRowSelectionOnClick
                sx={{
                    "& .MuiDataGrid-cell:focus": {
                        outline: "none"
                    },
                    "& .MuiDataGrid-columnHeader:focus": {
                        outline: "none"
                    }
                }}
            />
        </Box>
    )
}