import React, {useEffect, useState} from 'react';
import {
    Alert,
    BadgeProps,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Paper,
    Tooltip
} from "@mui/material";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import {SearchBar} from "../../Utils/SearchBar";
import messages, {getMessageDescriptor} from "../../../../../i18n/messages";
import {FormattedMessage, useIntl} from "react-intl";
import {Customer, CustomerFilterImpl} from "../../../../../corelogic/models/customer";
import ReturnButton from "../../Utils/ReturnButton";
import {DividingHR} from "../../Utils/DividingHR";
import {TextValueField} from "../../Utils/TextValueField";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {styled} from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {useDebounce} from "@uidotdev/usehooks";

interface CustomerHeaderInterface {
    counterClient?: number
    dataClient: Customer | null
    onChangeSearchInput?: (inputValue: string) => void
    getToggleFiltersState?: (state: boolean) => void
    filterCount?: number
}

const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

const CustomerHeader = (props: CustomerHeaderInterface) => {
    const {
        counterClient,
        dataClient,
        onChangeSearchInput,
        getToggleFiltersState,
        filterCount,
    } = props
    const navigate = useNavigate()
    const location = useLocation()
    const intl = useIntl()
    const [isCustomerCreationLocation, setIsCustomerCreationLocation] = useState(false)
    const [isCustomerListLocation, setIsCustomerListLocation] = useState(false)
    const [isCustomerDetailsLocation, setIsCustomerDetailsLocation] = useState(false)
    const [toggleFilters, setToggleFilters] = useState(true)
    const [openModal, setOpenModal] = useState(false)
    const [searchBarValue, setSearchBarValue] = useState("")
    const delaySearchValue = useDebounce(searchBarValue, 500)
    const [isFirstChange, setIsFirstChange] = useState(true)
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let params = CustomerFilterImpl.fromSearchParam(searchParams)
        setSearchBarValue(params.search ? params.search : "")
    }, [])

    useEffect(() => {
        if (onChangeSearchInput !== undefined && !isFirstChange) {
            onChangeSearchInput(delaySearchValue)
        }
    }, [onChangeSearchInput,delaySearchValue])

    useEffect(() => {
        if (location.pathname === "/customers/creation") {
            setIsCustomerCreationLocation(true)
        } else if (location.pathname === "/customers") {
            setIsCustomerListLocation(true)
        } else {
            setIsCustomerDetailsLocation(true)
        }
    }, [location.pathname, location.search])

    const handleCreateCustomer = () => {
        navigate("/customers/creation")
    }

    const handleOpenModal = () => {
        setOpenModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
    }

    const handleSearchBarChange = (inputValue: string) => {
        setSearchBarValue(inputValue)
        setIsFirstChange(false)
    }

    const handleResetSearchBar = () => {
        setSearchBarValue("")
    }

    const handleOpenFilters = () => {
        setToggleFilters(prevState => !prevState)
        if (getToggleFiltersState) {
            getToggleFiltersState(toggleFilters)
        }
    }

    return (
        <>
            {/*CUSTOMER LIST HEADER*/}
            {isCustomerListLocation &&
                <Paper elevation={3} sx={{backgroundColor: "common.main", p: 1}}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <ReturnButton/>
                                </Grid>
                                <Grid item mx={2}>
                                    <DividingHR
                                        subContent
                                        style={{height: "30px", width: 1, margin: 0}}/>
                                </Grid>
                                <Grid item mr={1}>
                                    <StyledBadge badgeContent={filterCount} color="error">
                                        {toggleFilters ?
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("itemListFilterOpenTitle"))}>
                                                <Button variant="contained" onClick={handleOpenFilters}>
                                                    <MenuIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                                </Button>
                                            </Tooltip>
                                            :
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("itemListFilterCloseTitle"))}>
                                                <Button variant="contained" onClick={handleOpenFilters}>
                                                    <MenuOpenIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                                </Button>
                                            </Tooltip>
                                        }
                                    </StyledBadge>
                                </Grid>
                                <Grid item mx={2}>
                                    <DividingHR
                                        subContent
                                        style={{height: "30px", width: 1, margin: 0}}/>
                                </Grid>
                                <Grid item>
                                    <TextValueField
                                        style={{
                                            fontWeight: "bolder"
                                        }}
                                        text={intl.formatMessage(getMessageDescriptor("customerListPageHeaderTitle"))}
                                        value={counterClient}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Button variant="contained" onClick={handleOpenModal}>
                                        <AddBusinessIcon sx={{marginRight: "8px"}}/>
                                        <FormattedMessage id={messages.customerNewCreation.id}/>
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <SearchBar
                                        label={intl.formatMessage(getMessageDescriptor("customerListPageHeaderTitleSearchBar"))}
                                        onChange={handleSearchBarChange} onResetClick={handleResetSearchBar}
                                        value={searchBarValue}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>}
            {/*CUSTOMER DETAILS HEADER*/}
            {isCustomerDetailsLocation && dataClient &&
                <Paper elevation={3} sx={{backgroundColor: "common.main", p: 1}}>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container alignItems="center" justifyContent="space-between">
                                <Grid item>
                                    <ReturnButton/>
                                </Grid>
                                <Grid item mx={2}>
                                    <DividingHR
                                        subContent
                                        style={{height: "30px", width: 1, margin: 0}}/>
                                </Grid>
                                <Grid item>
                                    <TextValueField
                                        style={{
                                            fontWeight: "bolder"
                                        }}
                                        text={intl.formatMessage(getMessageDescriptor("customerHeaderTitle"))}
                                        value={dataClient.businessName}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Button variant="outlined" disabled sx={{
                                color: "white !important",
                                backgroundColor: "green",
                                textTransform: "none",
                                width: "100%",
                            }}>Actif</Button>
                        </Grid>
                    </Grid>
                </Paper>}
            {/*CUSTOMER CREATION HEADER*/}
            {isCustomerCreationLocation &&
                <Paper elevation={3} sx={{backgroundColor: "common.main", p: 1}}>
                    <Grid container alignItems="center">
                        <Grid item>
                            <ReturnButton/>
                        </Grid>
                        <Grid item mx={2}>
                            <DividingHR
                                subContent
                                style={{height: "30px", width: 1, margin: 0}}/>
                        </Grid>
                        <Grid item>
                            <TextValueField
                                style={{
                                    fontWeight: "bolder"
                                }}
                                text={intl.formatMessage(getMessageDescriptor("customerHeaderTitle"))}
                                value={"En cours de création"}/>
                        </Grid>
                    </Grid>
                </Paper>}
            {/*CUSTOMER CREATION HEADER*/}
            {openModal &&
                <Dialog
                    maxWidth="lg"
                    open={openModal}
                >
                    <DialogTitle>
                        <FormattedMessage id={messages.customerNewCreationTitle.id}/>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Alert
                                severity="info">{intl.formatMessage(getMessageDescriptor("customerNewCreation"))}
                            </Alert>
                        </DialogContentText>
                    </DialogContent>
                    <DividingHR subContent/>
                    <DialogActions>
                        <Button variant="outlined" color="error" onClick={handleCloseModal}><FormattedMessage
                            id={messages.genericCancel.id}/></Button>
                        <Button variant="outlined" onClick={handleCreateCustomer}><FormattedMessage
                            id={messages.genericConfirm.id}/></Button>
                    </DialogActions>
                </Dialog>
            }
        </>
    )
}

export default CustomerHeader;