import {EnumData} from "../../../../corelogic/models/parameter";

export enum WeightUnit {
    KG = "Kilo-gramme",
    GR = "Gramme"
}

export enum VolumeUnit {
    M3 = "Mètre cube"
}

export enum DimensionUnit {
    CM = "Centimètre"
}

export enum BarecodeType {
    EAN13 = "EAN-13",
}

export enum UserStatus {
    ACTIVE = "Actif",
    INACTIVE = "Inactif",
    IN_PROGRESS = "En cours de création"
}

export enum UserStatusV2 {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    IN_PROGRESS = "IN_PROGRESS"
}

export enum OrderStatus {
    CREATION = "CREATION",
    NEW = "NEW",
    AWAITING_VALIDATION = "AWAITING_VALIDATION",
    DEALING_WITH = "DEALING_WITH",
    BLOCKED = "BLOCKED",
    PARTIALLY_SHIPPED = "PARTIALLY_SHIPPED",
    SHIPPED = "SHIPPED",
    PARTIALLY_BILLED = "PARTIALLY_BILLED",
    BILLED = "BILLED",
    CANCELED = "CANCELED",
    DELETED = "DELETED",
    UNDEFINED = "UNDEFINED"
}

export const getOrderStatusMessage = (status: OrderStatus): string => {
    switch (status) {
        case OrderStatus.CREATION:
            return "orderStatusCreation";
        case OrderStatus.NEW:
            return "orderStatusNew";
        case OrderStatus.AWAITING_VALIDATION:
            return "orderStatusAwaitingValidation";
        case OrderStatus.DEALING_WITH:
            return "orderStatusDealingWith";
        case OrderStatus.BLOCKED:
            return "orderStatusBlocked";
        case OrderStatus.PARTIALLY_SHIPPED:
            return "orderStatusPartiallyShipped";
        case OrderStatus.SHIPPED:
            return "orderStatusShipped";
        case OrderStatus.PARTIALLY_BILLED:
            return "orderStatusPartiallyBilled";
        case OrderStatus.BILLED:
            return "orderStatusBilled";
        case OrderStatus.CANCELED:
            return "orderStatusCanceled";
        case OrderStatus.DELETED:
            return "orderStatusDeleted";
        default:
            return "orderStatusUndefined";
    }
}

export enum CustomerItemReferencingType {
    REFERENCING_BY_SUPER_FAMILY = "sf",
    REFERENCING_BY_FAMILY = "f",
    REFERENCING_BY_CUSTOMER = "c"
}

export enum StatsModeView {
    BASICS,
    ITEMS_BY_TOTALWT,
    ITEMS_BY_QTY,
    CUSTOMERS_BY_TOTALWT,
}

export enum GraphsModeView {
    MODE_1 = "m1",
    MODE_2 = "m2",
}

export enum SalesTypeDoc {
    VC = "Commande",
    VF = "Facture",
    VA = "Avoir",
}

export const getSalesTypeDocMessage = (salesTypeDoc: SalesTypeDoc): string => {
    switch (salesTypeDoc) {
        case SalesTypeDoc.VC:
            return "salesTypeDocVC";
        case SalesTypeDoc.VF:
            return "salesTypeDocVF";
        case SalesTypeDoc.VA:
            return "salesTypeDocVA";
        default:
            return "salesTypeDocUndefined";
    }
}

export const enumToEnumData = (enumType: { [k: string]: string }, value: string): EnumData => {
    const keyEnum = getEnumKeyFromValue(enumType, value)
    if (keyEnum)
        return {value: keyEnum, label: value}

    return {value: "", label: ""}
}

export const getEnumKeyFromValue = (enumType: { [k: string]: any }, value: any) => {
    const keys = Object.keys(enumType).filter((x) => enumType[x] === value)
    return keys.length > 0 ? keys[0] : ''
}

export const getEnumValue = (enumType: { [k: string]: any }, key?: string) => {
    if (key)
        return enumType[key]
}

