import React, {useCallback, useEffect, useState} from "react";
import {Box, Container, Grid, IconButton, Paper} from "@mui/material";
import {HomeAmountInfo, HomeTextInfo} from "./Pages/Home/HomeInfoComponents";
import {DataGraphOrdersCountInterface, DataGraphOrdersSalesInterface} from "../../../corelogic/models/order";
import HomeHeader from "./Pages/Home/HomeHeader";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import {GridStatsTopTenItemByTotalWT} from "./Pages/Home/GridStatsTopTenItemByTotalWT";
import moment, {Moment} from "moment";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {GraphsModeView, StatsModeView} from "../../secondary/InMemory/Data/enums";
import {useDispatch, useSelector} from "react-redux";
import {getOrderStatsRepresentativeSelector, getOrderStatsSelector} from "../../../store/selectors/statsSelectors";
import {retrieveRepresentativeForStats, retrieveStatsOrder} from "../../../corelogic/usecase/stats/statsActions";
import {APP_ACTIONS_TYPES} from "../../../store/AppActionsTypes";
import {Representative} from "../../../corelogic/models/parameter";
import {GridStatsTopTenItemByQuantity} from "./Pages/Home/GridStatsTopTenItemByQuantity";
import {GridStatsTopTenCustomerByTotalWT} from "./Pages/Home/GridStatsTopTenCustomerByTotalWT";
import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../i18n/messages";

export const Home = () => {
    const dispatch = useDispatch()
    const {data, fetching} = useSelector((getOrderStatsSelector))
    const intl = useIntl()
    const {representative} = useSelector((getOrderStatsRepresentativeSelector))
    const [dataGraphOrdersCount, setDataGraphOrdersCount] = useState<DataGraphOrdersCountInterface[]>([])
    const [dataGraphOrdersSales, setDataGraphOrdersSales] = useState<DataGraphOrdersSalesInterface[]>([])
    const [statsModeView, setStatsModeView] = useState(StatsModeView.BASICS)
    const [graphsModeView, setGraphsModeView] = useState(GraphsModeView.MODE_1)
    const [startingDate, setStartingDate] = useState<Moment | null>(moment().subtract(15, 'd').set({h:0,m:0,s:0,ms:0}))
    const [endingDate, setEndingDate] = useState<Moment | null>(moment().set({h:23,m:59,s:59,ms:999}))
    const [representativeSelected, setRepresentativeSelected] = useState<Representative | null>(null)
    const handleChangeDataGraphOrderCount = useCallback(() => {
        if (startingDate && endingDate) {
            if (startingDate < endingDate) {
                const numberOfDaysBetweenBothDate = endingDate.diff(startingDate, "days")
                const newDataGraph = []

                for (let i = 0; i <= numberOfDaysBetweenBothDate; i++) {
                    const dateOrder = startingDate.clone().add(i, 'days').format("DD/MM").toString()
                    const ordersCount = Math.floor(Math.random() * 100)
                    newDataGraph.push({dateOrder, ordersCount})
                }
                setDataGraphOrdersCount(newDataGraph)
            }
        }
    }, [endingDate, startingDate])

    const handleChangeDataGraphOrderSales = useCallback(() => {
        if (startingDate && endingDate) {
            if (startingDate < endingDate) {
                const numberOfDaysBetweenBothDate = endingDate.diff(startingDate, "days")
                const newDataGraph = []

                for (let i = 0; i <= numberOfDaysBetweenBothDate; i++) {
                    const dateOrder = startingDate.clone().add(i, 'days').format("DD/MM").toString()
                    const ordersSales = Math.floor(Math.random() * 100)
                    newDataGraph.push({dateOrder, ordersSales})
                }
                setDataGraphOrdersSales(newDataGraph)
            }
        }
    }, [endingDate, startingDate])

    useEffect(() => {
        if (startingDate && endingDate)
            dispatch(retrieveStatsOrder(startingDate.toDate(), endingDate.toDate(), representativeSelected?.id))
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.stats.STATS_RESET_DATA})
    }, [dispatch, startingDate, endingDate, representativeSelected]);

    useEffect(() => {
        dispatch(retrieveRepresentativeForStats())
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.stats.STATS_REPR_RESET_DATA})
    }, [dispatch]);

    useEffect(() => {
        handleChangeDataGraphOrderCount()
        handleChangeDataGraphOrderSales()
    }, [handleChangeDataGraphOrderCount, handleChangeDataGraphOrderSales])

    const handleSaveFilters = (startingDate: Moment, endingDate: Moment, representative: Representative | null) => {
        setStartingDate(startingDate.set({h:0,m:0,s:0,ms:0}))
        setEndingDate(endingDate.set({h:23,m:59,s:59,ms:999}))
        setRepresentativeSelected(representative)
        handleChangeDataGraphOrderCount()
        handleChangeDataGraphOrderSales()
    }

    const handleChangeStatsView = (arrowDirection: string) => {
        const statsModeList = [StatsModeView.BASICS, StatsModeView.ITEMS_BY_TOTALWT, StatsModeView.ITEMS_BY_QTY, StatsModeView.CUSTOMERS_BY_TOTALWT]
        const currentIdx = statsModeList.indexOf(statsModeView)
        if (arrowDirection === "left") {
            if (currentIdx - 1 < 0)
                setStatsModeView(statsModeList[statsModeList.length - 1])
            else
                setStatsModeView(currentIdx - 1)
        } else {
            if (currentIdx + 1 >= statsModeList.length)
                setStatsModeView(statsModeList[0])
            else
                setStatsModeView(currentIdx + 1)
        }
    }

    const handleChangeGraphView = () => {
        if (graphsModeView === GraphsModeView.MODE_1) {
            setGraphsModeView(GraphsModeView.MODE_2)
        } else {
            setGraphsModeView(GraphsModeView.MODE_1)
        }
    }

    return (
        <>
            <Paper elevation={3} sx={{backgroundColor: "common.main", position: "sticky", top: "77px", zIndex: 1000}}>
                <HomeHeader sendValues={handleSaveFilters} representatives={representative}/>
            </Paper>
            <Container maxWidth="xl">
                <Grid container direction="column" spacing={1} p={1}>
                    <Grid item sm={5}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item sm={1}>
                                <Paper sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <IconButton onClick={() => handleChangeStatsView("left")}>
                                        <ArrowBackIcon fontSize="large"
                                                       sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    </IconButton>
                                </Paper>
                            </Grid>
                            <Grid item sm={10}>
                                <Box sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    width: "100%",
                                    position: "relative"
                                }}>
                                    {statsModeView === StatsModeView.BASICS &&
                                        <Paper sx={{
                                            width: "100%",
                                            minHeight: "280px",
                                            display: "flex",
                                            alignItems: "center"
                                        }}>
                                            <Grid container p={1} alignItems="center">
                                                <Grid item lg={3} sm={6}>
                                                    <Grid container justifyContent="center">
                                                        <Grid item>
                                                            <Paper elevation={5} sx={{m: 1, p: 1}}>
                                                                <HomeTextInfo
                                                                    fetching={fetching}
                                                                    title={`${data?.numberOfOrders === undefined ? "" : data?.numberOfOrders}`}
                                                                    primaryText={intl.formatMessage(getMessageDescriptor("statsNumberOfOrders"))}
                                                                    secondaryText={intl.formatMessage(getMessageDescriptor("statsInSelectedPeriod"))}/>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={3} sm={6}>
                                                    <Grid container justifyContent="center">
                                                        <Grid item>
                                                            <Paper elevation={5} sx={{m: 1, p: 1}}>
                                                                <HomeAmountInfo fetching={fetching}
                                                                                title={data?.totalSales}
                                                                                styleAmount={"currency"}
                                                                                currency={"EUR"}
                                                                                nbDigits={2}
                                                                                primaryText={intl.formatMessage(getMessageDescriptor("statsTotalSales"))}
                                                                                secondaryText={intl.formatMessage(getMessageDescriptor("statsInSelectedPeriod"))}/>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={3} sm={6}>
                                                    <Grid container justifyContent="center">
                                                        <Grid item>
                                                            <Paper elevation={5} sx={{m: 1, p: 1}}>
                                                                <HomeTextInfo
                                                                    fetching={fetching}
                                                                    title={`${data?.activeCustomers === undefined ? "" : data?.activeCustomers}`}
                                                                    primaryText={intl.formatMessage(getMessageDescriptor("statsActiveCustomers"))}
                                                                    secondaryText={intl.formatMessage(getMessageDescriptor("statsInSelectedPeriod"))}/>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item lg={3} sm={6}>
                                                    <Grid container justifyContent="center">
                                                        <Grid item>
                                                            <Paper elevation={5} sx={{m: 1, p: 1}}>
                                                                <HomeTextInfo
                                                                    fetching={fetching}
                                                                    title={`${data?.newCustomers === undefined ? "" : data?.newCustomers}`}
                                                                    primaryText={intl.formatMessage(getMessageDescriptor("statsNewCustomers"))}
                                                                    secondaryText={intl.formatMessage(getMessageDescriptor("statsInSelectedPeriod"))}/>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    }
                                    {statsModeView === StatsModeView.ITEMS_BY_TOTALWT &&
                                        <Paper sx={{width: "100%", minHeight: "280px"}}>
                                            <Grid container p={1}>
                                                <Grid item sm={3}>
                                                    <Grid container justifyContent="center" alignItems="center"
                                                          height="100%">
                                                        <Grid item>
                                                            <Paper elevation={5} sx={{p: 1, m: 1}}>
                                                                <HomeTextInfo title={"10"}
                                                                              primaryText={intl.formatMessage(getMessageDescriptor("statsTopArticlesByTotalPrice"))}
                                                                              secondaryText={intl.formatMessage(getMessageDescriptor("statsInSelectedPeriod"))}/>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item sm={9}>
                                                    <Grid container justifyContent="center">
                                                        <Grid item width="100%">
                                                            <GridStatsTopTenItemByTotalWT
                                                                data={data?.topItemValuesByTotalWT}
                                                                fetching={fetching}/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    }
                                    {statsModeView === StatsModeView.ITEMS_BY_QTY &&
                                        <Paper sx={{width: "100%", minHeight: "280px"}}>
                                            <Grid container p={1}>
                                                <Grid item sm={3}>
                                                    <Grid container justifyContent="center" alignItems="center"
                                                          height="100%">
                                                        <Grid item>
                                                            <Paper elevation={5} sx={{p: 1, m: 1}}>
                                                                <HomeTextInfo title={"10"}
                                                                              primaryText={intl.formatMessage(getMessageDescriptor("statsTopArticlesByQuantity"))}
                                                                              secondaryText={intl.formatMessage(getMessageDescriptor("statsInSelectedPeriod"))}/>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item sm={9}>
                                                    <Grid container justifyContent="center">
                                                        <Grid item width="100%">
                                                            <GridStatsTopTenItemByQuantity
                                                                data={data?.topItemValuesByQuantity}
                                                                fetching={fetching}/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    }
                                     {statsModeView === StatsModeView.CUSTOMERS_BY_TOTALWT &&
                                        <Paper sx={{width: "100%", minHeight: "280px"}}>
                                            <Grid container p={1}>
                                                <Grid item sm={3}>
                                                    <Grid container justifyContent="center" alignItems="center"
                                                          height="100%">
                                                        <Grid item>
                                                            <Paper elevation={5} sx={{p: 1, m: 1}}>
                                                                <HomeTextInfo title={"10"}
                                                                              primaryText={intl.formatMessage(getMessageDescriptor("statsTopCustomersByTotalPrice"))}
                                                                              secondaryText={intl.formatMessage(getMessageDescriptor("statsInSelectedPeriod"))}/>
                                                            </Paper>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                                <Grid item sm={9}>
                                                    <Grid container justifyContent="center">
                                                        <Grid item width="100%">
                                                            <GridStatsTopTenCustomerByTotalWT
                                                                data={data?.topCustomerValuesByTotalWT}
                                                                fetching={fetching}/>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Paper>
                                    }
                                </Box>
                            </Grid>
                            <Grid item sm={1}>
                                <Paper sx={{
                                    height: "100%",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <IconButton onClick={() => handleChangeStatsView("right")}>
                                        <ArrowForwardIcon fontSize="large"
                                                          sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    </IconButton>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*<Grid item sm={7}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item sm={1}>
                                <Paper sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <IconButton onClick={handleChangeGraphView}>
                                        <ArrowBackIcon fontSize="large"
                                                       sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    </IconButton>
                                </Paper>
                            </Grid>
                            <Grid item sm={10} minHeight={200}>
                                <Paper sx={{p: 1}}>
                                    {graphsModeView === GraphsModeView.MODE_1 &&
                                        <>
                                            <Typography fontWeight="bolder">
                                                Nombre de commandes (Dans la période sélectionnée)
                                            </Typography>
                                            <DividingHR/>
                                            <HomeChartOrdersCount data={dataGraphOrdersCount}/>
                                        </>
                                    }
                                    {graphsModeView === GraphsModeView.MODE_2 &&
                                        <>
                                            <Typography fontWeight="bolder">
                                                Chiffre d'affaire (Dans la période sélectionnée)
                                            </Typography>
                                            <DividingHR/>
                                            <HomeChartOrdersSales data={dataGraphOrdersSales}/>
                                        </>
                                    }
                                </Paper>
                            </Grid>
                            <Grid item sm={1}>
                                <Paper sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center"
                                }}>
                                    <IconButton onClick={handleChangeGraphView}>
                                        <ArrowForwardIcon fontSize="large"
                                                          sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                                    </IconButton>
                                </Paper>
                            </Grid>
                        </Grid>
                    </Grid>*/}
                </Grid>
            </Container>
        </>
    )
}