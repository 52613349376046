import {EnumData, Parameter} from "../../../../corelogic/models/parameter";
import {Society} from "../../../../corelogic/models/society";
import {Depot} from "../../../../corelogic/models/depot";
import {Image, User} from "../../../../corelogic/models/user";
import {Customer} from "../../../../corelogic/models/customer";

export interface UserApiRequest {
    id: number
    linkedCustomerId?: number
    login: string
    lastName: string
    firstName: string
    mail: string
    languageId?: number
    roleId?: number
    status?: string
    representativeId?: number
    defaultSocietyId: number
    defaultDepotId: number
    sendEmailAtOrderSubmission: boolean
    defaultOrderUnitPackage?: boolean
}

export interface UserApiWithCountResponse {
    count: number
    users: UserApiResponse[]
}

export interface UserApiResponse {
    id: number
    linkedCustomer?: Customer | Parameter
    login: string
    lastName: string
    firstName: string
    mail: string
    language?: Parameter
    role?: Parameter
    status?: EnumData
    representative?: Parameter
    defaultSociety: Society
    defaultDepot: Depot
    avatar?: Image
    sendEmailAtOrderSubmission: boolean
    defaultOrderUnitPackage?: boolean
}

export const mapUserToUserApiRequest = (user: User): UserApiRequest => {
    return {
        id: user.id,
        linkedCustomerId: user.linkedCustomer?.id,
        login: user.login,
        lastName: user.lastName,
        firstName: user.firstName,
        mail: user.mail,
        languageId: user.language?.id,
        roleId: user.role?.id,
        status: user.status?.value,
        defaultSocietyId: user.defaultSociety?.id,
        defaultDepotId: user.defaultDepot?.id,
        sendEmailAtOrderSubmission: user.sendEmailAtOrderSubmission,
        representativeId: user.representative?.id,
        defaultOrderUnitPackage: user.defaultOrderUnitPackage
    }
}

export const mapUserApiResponseToUser = (userResponse: UserApiResponse): User => {
    return {
        id: userResponse.id,
        linkedCustomer: userResponse.linkedCustomer,
        login: userResponse.login,
        lastName: userResponse.lastName,
        firstName: userResponse.firstName,
        mail: userResponse.mail,
        language: userResponse.language,
        role: userResponse.role,
        status: userResponse.status,
        representative: userResponse.representative,
        defaultSociety: userResponse.defaultSociety,
        defaultDepot: userResponse.defaultDepot,
        avatar: userResponse.avatar,
        sendEmailAtOrderSubmission: userResponse.sendEmailAtOrderSubmission,
        defaultOrderUnitPackage: userResponse.defaultOrderUnitPackage
    }
}