import {CustomFormatConfig, FormattedNumber} from "react-intl";
import * as React from "react";
import {NumberFormatOptions} from "@formatjs/ecma402-abstract";

export const FormattedMonetaryNumber: React.FC<NumberFormatOptions & CustomFormatConfig & {
    value: number | bigint;
}> = (props) => {
    return <FormattedNumber  minimumFractionDigits={0} maximumFractionDigits={2} {...props}/>
}

export const FormattedCustomNumber: React.FC<NumberFormatOptions & CustomFormatConfig & {
    value: number | bigint;
}> = (props) => {
    return <FormattedNumber minimumFractionDigits={0} maximumFractionDigits={2} {...props} />
}

export const FormattedPercentageNumber: React.FC<NumberFormatOptions & CustomFormatConfig & {
    value: number | bigint;
}> = (props) => {
    // eslint-disable-next-line react/style-prop-object
    return <FormattedNumber style="percent" {...props}  minimumFractionDigits={0} maximumFractionDigits={2}/>
}