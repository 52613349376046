import {AppState} from "store/appState";

export const getUserSelector = (state: AppState) => {
    return {
        data: state.users.data,
        fetching: state.users.fetching,
        count: state.users.count,
    }
}

export const getUserSelectedSelector = (state: AppState) => {
    return {
        userSelected: state.users.selected,
        fetchingSelected: state.users.fetching,
    }
}

export const getUserFormSelector = (state: AppState) => {
    return {
        dataFormFields: state.users.dataFormFields
    }
}