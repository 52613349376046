import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {
    Alert,
    BadgeProps,
    Button,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogProps,
    Grid,
    Pagination,
    Paper,
    Slide,
    Tooltip
} from '@mui/material';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import Box from "@mui/material/Box";
import {DividingHR} from "../../../Utils/DividingHR";
import {ItemDetailsPage} from "../../Item/ItemDetailsPage/ItemDetailsPage";
import {TransitionProps} from "@mui/material/transitions";
import {Item, ItemFilter} from "../../../../../../corelogic/models/item";
import {useDispatch, useSelector} from "react-redux";
import {
    getItemAllFormSelector,
    getItemSelectedSelector,
    getItemsSelector
} from "../../../../../../store/selectors/itemsSelectors";
import {
    retrieveAllItemDataFormFieldsForFilter,
    retrieveAllItemsFiltered,
    retrieveSelectedItem
} from "../../../../../../corelogic/usecase/items/itemsActions";
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import {OrderItemCard} from "./OrderItemCard";
import {SearchBar} from "../../../Utils/SearchBar";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {FormattedMessage, useIntl} from "react-intl";
import {getCustomerSelectedSelector} from "../../../../../../store/selectors/customersSelector";
import {TextValueField} from "../../../Utils/TextValueField";
import ItemFilterAccordion from "../../Item/ItemListPage/ItemFilterAccordion";
import {OrderItemCardHorizontal} from "./OrderItemCardHorizontal";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import {styled} from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {UserRoleCode} from "../../../../../../corelogic/models/user";
import {useDebounce} from "@uidotdev/usehooks";

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
})

const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

interface OrderItemListWithDrawerInterface {
    onItemAddClick: (idItem: number, quantity: number, packageSelected: string) => void
}

export const OrderItemListWithDrawer = (props: OrderItemListWithDrawerInterface) => {
    const {onItemAddClick} = props
    const dispatch = useDispatch()
    const intl = useIntl()
    const {userLogged} = useSelector(getUserLoggedSelector)
    const {data, fetching, count: dataCount} = useSelector(getItemsSelector)
    const {dataFormFields} = useSelector(getItemAllFormSelector)
    const {customerSelected} = useSelector(getCustomerSelectedSelector)
    const divElementCardList = useRef<HTMLDivElement>(null)
    const ITEMS_PER_PAGE = 12
    const [isVdc, setIsVdc] = useState(document.location.hostname === process.env.REACT_APP_CTX_CLI_VDC && userLogged?.role?.code === UserRoleCode.CUSTOMER ? true : undefined)
    const [page, setPage] = React.useState(1)
    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value)
        scrollToTop()
    }
    const [toggleCollapseFilter, setToggleCollapseFilter] = useState(false)
    const [inputSearch, setInputSearch] = useState("")
    const [displayHorizontalCards, setDisplayHorizontalCards] = useState(false)
    const [itemFilter, setItemFilter] = useState<ItemFilter>({
        search: "",
        familyCodes: [],
        superFamilyCodes: [],
        brandCodes: [],
        geographicalOriginCodes: [],
        authorizationToSellOnly: true,
        favorite: isVdc,
        isOrganic: undefined,
        isManagedInStock: undefined,
        isSold: true,
        isPurchased: undefined,
        isBlocked: false,
        isPublishedInTheCatalog: true,
        isExportECommerce: undefined,
        isOrderSchedule: undefined,
        familyIds: [],
        superFamilyIds: [],
        brandIds: [],
        geographicalOriginIds: []
    })
    const [searchValue, setSearchValue] = useState("")
    const delaySearchValue = useDebounce(searchValue, 500)
    const [dataDetailItem, setDataDetailItem] = useState<Item | undefined>(undefined)
    const [openDetailItemDialog, setOpenDetailItemDialog] = useState(false)
    const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('xl')
    const [fullWidth, setFullWidth] = useState(true)
    const [filterCount, setFilterCount] = useState(isVdc ? 2 : 1)
    const {itemSelected} = useSelector(getItemSelectedSelector)

    useEffect(() => {
        if (itemSelected != null) {
            setDataDetailItem(itemSelected)
            setOpenDetailItemDialog(true)
        }
    }, [itemSelected]);

    useEffect(() => {
        setItemFilter(prevState => ({...prevState, search: delaySearchValue}))
    }, [delaySearchValue])

    useEffect(() => {
        setIsVdc(document.location.hostname === process.env.REACT_APP_CTX_CLI_VDC && userLogged?.role?.code === UserRoleCode.CUSTOMER ? true : undefined)
    }, [userLogged])

    useEffect(() => {
        setItemFilter(x => ({
            ...x,
            favorite: isVdc
        }))
    }, [isVdc])

    useEffect(() => {
        dispatch(retrieveAllItemsFiltered(itemFilter.search ? itemFilter : {
            ...itemFilter,
            search: ""
        }, {size: ITEMS_PER_PAGE, page: page}, customerSelected?.id))
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.items.ITEM_RESET_DATA})
    }, [customerSelected?.id, dispatch, itemFilter, page])

    useEffect(() => {
        if (customerSelected)
            dispatch<any>(retrieveAllItemDataFormFieldsForFilter(customerSelected?.id))
        else
            dispatch<any>(retrieveAllItemDataFormFieldsForFilter())
    }, [dispatch, customerSelected])

    const scrollToTop = () => {
        divElementCardList?.current?.scrollTo({top: 0, behavior: "auto"})
    }

    const handleToggleCollapseFilterBtnClick = () => {
        setToggleCollapseFilter(!toggleCollapseFilter)
    }

    const handleFilterBySearchInput = (inputValue: string) => {
        setInputSearch(inputValue)
        setSearchValue(inputValue ? inputValue : "")
    }

    const handleResetSearchBar = () => {
        setInputSearch("")
        setSearchValue("")
        setPage(1)
    }

    const handleApplyAllFilters = (itemFilters: ItemFilter, filterCount: number) => {
        setItemFilter(itemFilters)
        setPage(1)
        setFilterCount(filterCount)
    }

    const handleResetAllFilters = () => {
        setItemFilter({
            search: "",
            familyCodes: [],
            superFamilyCodes: [],
            brandCodes: [],
            geographicalOriginCodes: [],
            favorite: isVdc,
            authorizationToSellOnly: true,
            isOrganic: undefined,
            isSold: undefined,
            isPurchased: undefined,
            isBlocked: undefined,
            isManagedInStock: undefined,
            isPublishedInTheCatalog: true,
            isExportECommerce: undefined,
            isOrderSchedule: undefined,
            familyIds: [],
            superFamilyIds: [],
            brandIds: [],
            geographicalOriginIds: []
        })
        setPage(1)
        setInputSearch("")
    }

    const handleChangeCardDisplay = () => {
        setDisplayHorizontalCards(!displayHorizontalCards)
    }

    const handleOpenItemDetailModal = (id: number) => {
        dispatch(retrieveSelectedItem(id,customerSelected?.id))
    }
    const handleCloseItemDetailModal = () => {
        setDataDetailItem(undefined)
        setOpenDetailItemDialog(false)
        dispatch<any>({type:APP_ACTIONS_TYPES.items.ITEM_RESET_SELECTION})
    }

    const renderDialogItemDetail = () => {
        return (
            <Dialog
                open={true}
                TransitionComponent={Transition}
                maxWidth={maxWidth}
                fullWidth={fullWidth}
                onClose={handleCloseItemDetailModal}
            >
                <DialogActions sx={{position: "absolute", top: "8px", right: "15px"}}>
                    <Button variant={"outlined"} onClick={handleCloseItemDetailModal}> <FormattedMessage
                        id={messages.genericClose.id}/>
                    </Button>
                </DialogActions>
                <DialogContent>
                    {dataDetailItem ? <ItemDetailsPage data={dataDetailItem} withoutHeader/> :
                        <Grid container justifyContent="center" alignItems="center"><CircularProgress
                            size={80}/></Grid>}
                </DialogContent>
            </Dialog>
        )
    }

    return (
        <>
            <Grid container direction="column">
                <Grid item>
                    <Grid container justifyContent="space-between" alignItems="center" py={0.5} px={3}>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <StyledBadge badgeContent={filterCount} color="error">
                                        {toggleCollapseFilter ?
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("itemListFilterCloseTitle"))}>
                                                <Button variant="contained"
                                                        onClick={handleToggleCollapseFilterBtnClick}>
                                                    <MenuOpenIcon/>
                                                </Button>
                                            </Tooltip>
                                            :
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("itemListFilterOpenTitle"))}>
                                                <Button variant="contained"
                                                        onClick={handleToggleCollapseFilterBtnClick}>
                                                    <MenuIcon/>
                                                </Button>
                                            </Tooltip>}
                                    </StyledBadge>
                                </Grid>
                                {/* <Grid item>
                                    <Tooltip
                                        title={intl.formatMessage(getMessageDescriptor("itemListDisplayChangeTitle"))}>
                                        <Button variant="contained" onClick={handleChangeCardDisplay}>
                                            <ArtTrackIcon onClick={handleChangeCardDisplay}/>
                                        </Button>
                                    </Tooltip>
                                </Grid>*/}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <ShoppingBasketIcon/>
                                </Grid>
                                <Grid item>
                                    <TextValueField
                                        fontSize="1.2rem"
                                        style={{fontSize: "1.2rem"}}
                                        text={intl.formatMessage(getMessageDescriptor("itemListPageHeaderTitle"))}
                                        value={dataCount}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <SearchBar label={intl.formatMessage(getMessageDescriptor("searchBarItem"))}
                                       value={inputSearch} onChange={handleFilterBySearchInput}
                                       onResetClick={handleResetSearchBar}/>
                        </Grid>
                    </Grid>
                    <Box>
                        <DividingHR style={{marginTop: 1}}/>
                    </Box>
                </Grid>
                <Grid item container height="75vh">
                    <Collapse orientation="horizontal" in={toggleCollapseFilter} sx={{borderRadius: "1%"}}>
                        <ItemFilterAccordion dataFormFields={dataFormFields}
                                             displayWithFixedHeight
                                             disableIsBlockedIsSold
                                             isCreationMode
                                             onClickApplyFilters={handleApplyAllFilters}
                                             onClickResetAllFilters={handleResetAllFilters}
                                             withOrderSchedule
                        />
                    </Collapse>
                    <Grid item md height="inherit">
                        {fetching ?
                            <Grid item flexGrow={1} height="100%">
                                <Grid container justifyContent="center" alignItems="center" height="100%">
                                    <CircularProgress size={60}/>
                                </Grid>
                            </Grid>
                            :
                            <Grid item md height="inherit">
                                <Grid ref={divElementCardList} container justifyContent="center" height="100%" overflow="auto">
                                    {data.map((item) => {
                                        return (
                                            <Grid item>
                                                {!displayHorizontalCards ?
                                                    <OrderItemCard data={item}
                                                                   onAddItemToOrderLineClick={onItemAddClick}
                                                                   onItemDetailClick={handleOpenItemDetailModal}/>
                                                    :
                                                    <OrderItemCardHorizontal data={item}
                                                                             onAddItemToOrderLineClick={onItemAddClick}
                                                                             onItemDetailClick={handleOpenItemDetailModal}/>
                                                }
                                            </Grid>
                                        )
                                    })}
                                    {dataCount > 0 &&
                                        <Grid item container p={3} justifyContent="center" alignSelf="flex-end">
                                            <Paper sx={{p: 1}}>
                                                <Pagination count={Math.ceil(dataCount / ITEMS_PER_PAGE)}
                                                            boundaryCount={1}
                                                            page={page}
                                                            onChange={handleChange} color="primary"/>
                                            </Paper>
                                        </Grid>}
                                    {(itemFilter.superFamilyCodes.length !== 0 || itemFilter.familyCodes.length !== 0 ||
                                            itemFilter.brandCodes.length !== 0 || itemFilter.favorite ||
                                            itemFilter.geographicalOriginCodes.length !== 0 || itemFilter.search.length > 0)
                                        && dataCount === 0 &&
                                        <Grid container justifyContent="center" alignItems="flex-start">
                                            <Alert severity="info" sx={{alignItems: "center"}}>
                                                {intl.formatMessage(getMessageDescriptor("itemListFilterNotFound"))}
                                            </Alert>
                                        </Grid>}
                                </Grid>
                            </Grid>
                        }
                    </Grid>
                </Grid>
            </Grid>
            {openDetailItemDialog && renderDialogItemDetail()}
        </>
    )
}