import {AnyAction, combineReducers} from "redux";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {Order, OrderComment, OrderLine, OrderLineUpdateError, OrderTotals} from "../../corelogic/models/order";

const data = (state: Order[] = [], action: AnyAction): Order[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.orders.ORDERS_RETRIEVED:
            return action.payload.orders
        case APP_ACTIONS_TYPES.orders.ORDER_RESET_DATA:
            return []
        case APP_ACTIONS_TYPES.orders.ORDER_ADD:
            return [...state, action.payload]
        case APP_ACTIONS_TYPES.orders.ORDER_UPDATE:
            return state.map(o => o.id === action.payload.id ? action.payload : o)
        default:
            return state;
    }
}

const selected = (state: Order | null = null, action: AnyAction): Order | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.orders.ORDER_SELECTING:
            return action.payload === undefined ? null : action.payload
        case APP_ACTIONS_TYPES.orders.ORDER_RESET_SELECTION:
            return null
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_ADD:
            const lineAddTot = action.payload[1] as OrderTotals
            if (state && state.id === action.target) {
                return {
                    ...state,
                    totals: lineAddTot
                }
            }
            return state
        case APP_ACTIONS_TYPES.orders.ORDER_UPDATE:
            if (state && state.id === action.payload.id) {
                return action.payload
            }
            return state
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_QTY:
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_PRICING:
            const lineNewTot = action.payload[1] as OrderTotals
            if (state)
                return {
                    ...state,
                    totals: lineNewTot
                }
            return state
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_QTY_CANCEL:
            if (state) {
                return {...state}
            }
            return state
        case APP_ACTIONS_TYPES.orders.ORDER_TOTAL_UPDATE:
            if (state)
                return {
                    ...state,
                    totals: action.payload
                }
            return state
        default:
            return state
    }
}

const selectedLines = (state: OrderLine[] = [], action: AnyAction): OrderLine[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.orders.ORDER_SELECTING:
        case APP_ACTIONS_TYPES.orders.ORDER_RESET_SELECTION:
            return []
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_RETRIEVED:
            return action.payload
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_ADD:
            const lineAdd = action.payload[0] as OrderLine
            return state.find(l => l.id === lineAdd.id) ?
                state.map(ol => ol.id === lineAdd.id ? {
                    ...ol,
                    quantity: (lineAdd.quantity || 0),
                    itemTotalExTax: (lineAdd.itemTotalExTax || 0),
                } : ol)
                : [lineAdd, ...state]
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_DELETE:
            return state.filter(ol => ol.id !== action.payload)
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_QTY:
            const lineUpd = action.payload[0] as OrderLine
            return state.map(ol => ol.id === lineUpd.id ? {
                ...ol,
                quantity: lineUpd.quantity,
                grossPrice: lineUpd.grossPrice,
                netPrice: lineUpd.netPrice,
                discount1: lineUpd.discount1,
                discount2: lineUpd.discount2,
                itemTotalExTax: lineUpd.itemTotalExTax
            } : ol)
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_QTY_CANCEL:
            return state?.map(ol => ol) || []
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_PRICING:
            const linePriceUpd = action.payload[0] as OrderLine
            return state.map(ol => ol.id === linePriceUpd.id ? {
                ...ol,
                grossPrice: linePriceUpd.grossPrice,
                netPrice: linePriceUpd.netPrice,
                discount1: linePriceUpd.discount1,
                discount2: linePriceUpd.discount2,
                free: linePriceUpd.free,
                itemTotalExTax: linePriceUpd.itemTotalExTax
            } : ol)
        case APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_LABEL:
            const lineLabelUpd = action.payload as OrderLine
            return state.map(ol => ol.id === lineLabelUpd.id ? {
                ...ol,
                label: lineLabelUpd.label
            } : ol)
        default:
            return state
    }

}

const updateLineError = (state: OrderLineUpdateError | null = null, action: AnyAction): OrderLineUpdateError | null => {
    if (action.type === APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_QTY_ERROR) {
        return action.payload
    }
    if (action.type === APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_QTY_ERROR_CLEAR) {
        return null
    }
    return state
}

const count = (state: number = 0, action: AnyAction) => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.orders.ORDERS_RETRIEVED:
            return action.payload.count
        case APP_ACTIONS_TYPES.orders.ORDER_RESET_DATA:
            return 0
        default:
            return state
    }
}

const fetching = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("ORDER")) {
        return action.type === APP_ACTIONS_TYPES.orders.ORDERS_RETRIEVING
    }
    return state
}

const fetchingOnAction = (state: boolean = false, action: AnyAction) => {
    return action.type === APP_ACTIONS_TYPES.orders.ORDER_FETCHING
}

/** ORDER LINES **/

const fetchingOrderLines = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("ORDER_LINE")) {
        return action.type === APP_ACTIONS_TYPES.orders.ORDER_LINES_RETRIEVING
    }
    return state
}
const fetchingUpdateOrderLines = (state: boolean = false, action: AnyAction) => {
    if (action.type === APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_FETCHING) {
        return action.payload
    }
    return state
}
/** ORDER COMMENTS **/

const fetchingOrderComments = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("ORDER_COMMENT")) {
        return action.type === APP_ACTIONS_TYPES.orders.ORDER_COMMENTS_RETRIEVING
    }
    return state
}

const activeComments = (state: OrderComment[] = [], action: AnyAction) => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.orders.ORDER_COMMENTS_RETRIEVED:
            return action.payload
        case APP_ACTIONS_TYPES.orders.ORDER_COMMENTS_CLEAR:
            return []
        case APP_ACTIONS_TYPES.orders.ORDER_COMMENTS_ADD:
            return [...state, action.payload]
        default:
            return state
    }
}

export default combineReducers({
    data,
    selected,
    fetching,
    fetchingOnAction,
    fetchingOrderLines,
    fetchingOrderComments,
    activeComments,
    selectedLines,
    count,
    updateLineError,
    fetchingUpdateOrderLines
})