import {getOrderStatusMessage, OrderStatus} from "../../../secondary/InMemory/Data/enums";
import * as React from "react";
import {Button} from "@mui/material";
import Box from "@mui/material/Box";
import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../i18n/messages";

export const OrderStatusButton = (props: { status: OrderStatus }) => {
    const intl = useIntl()
    const getStatusColor = () => {
        switch (props.status) {
            case OrderStatus.CREATION :
                return "#faa307"
            case OrderStatus.NEW :
                return "#f48c06"
            case OrderStatus.AWAITING_VALIDATION :
                return "#e85d04"
            case OrderStatus.DEALING_WITH :
                return "#90a955"
            case OrderStatus.BLOCKED:
                return "#a73232"
            case OrderStatus.PARTIALLY_SHIPPED:
                return "linear-gradient(to right bottom, #90a955 50%, #4f772d 50%)"
            case OrderStatus.SHIPPED :
                return "#4f772d"
            case OrderStatus.PARTIALLY_BILLED:
                return "linear-gradient(to right bottom, #4f772d 50%, #31572c 50%)"
            case OrderStatus.BILLED :
                return "#31572c"
            case OrderStatus.CANCELED :
                return "#d00000"
            case OrderStatus.DELETED :
                return "#9d0208"
            default :
                return "#6c757d"
        }
    }
    return <Box sx={{
        fontSize: "1rem",
        fontWeight: 20,
        width: "100%",
    }}>
        <Button variant="outlined" disabled sx={{
            color: "white !important",
            background: getStatusColor(),
            textTransform: "none",
            width: "100%",
        }}>{intl.formatMessage(getMessageDescriptor(getOrderStatusMessage(props.status))) || "???"}</Button>
    </Box>
}