import {Depot} from "./depot";
import {EnumData, Parameter} from "./parameter";
import {Society} from "./society";
import {Customer} from "./customer";

export interface UserFilter {
    search?: string
}
export interface UserWithCount {
    count: number
    users: User[]
}
export interface User {
    id: number
    representative?: Parameter | null
    linkedCustomer?: Customer | Parameter | null
    login: string
    lastName: string
    firstName: string
    mail: string
    password?: string
    language?: Parameter | undefined
    status?: EnumData
    defaultSociety: Society
    defaultDepot: Depot
    avatar?: Image | File | string
    role?: Parameter
    sendEmailAtOrderSubmission: boolean
    defaultOrderUnitPackage?: boolean
}

export interface UserForm {
    id?: number
    linkedCustomerId?: number
    login?: string
    lastName?: string
    firstName?: string
    mail?: string
    password?: string
    languageId?: number
    status?: string
    defaultSocietyId?: number
    defaultDepotId?: number
    representativeId?: number
    avatar?: File
    roleId?: number
    sendEmailAtOrderSubmission?: boolean
    defaultOrderUnitPackage?: boolean
}

export interface UserPasswordForm {
    login: string
    oldPassword: string
    newPassword: string
}

export interface UserPasswordAdminForm {
    newPassword: string
}

export enum UserRoleCode {
    SODIWIN = "SODIWIN",
    ADMIN = "ADMIN",
    COMMERCIAL = "COMMERCIAL",
    CUSTOMER = "CUSTOMER"
}

export interface Image {
    name?: string
    type?: string
    image?: string
}

export interface UserAllFormData {
    languages: Parameter[]
    roles: Parameter[]
    statuses: EnumData[]
    representatives: Parameter[]
    customers: Parameter[]
}

export const UserRoleUtils = {
    isAdmin(user?: User | null): boolean {
        return user?.role?.code === UserRoleCode.ADMIN || this.isSodiwin(user);
    },

    isCommercial(user?: User | null): boolean {
        return user?.role?.code === UserRoleCode.COMMERCIAL;
    },

    isCustomer(user?: User | null): boolean {
        return user?.role?.code === UserRoleCode.CUSTOMER;
    },

    isSodiwin(user?: User | null): boolean {
        return user?.role?.code === UserRoleCode.SODIWIN;
    }
}