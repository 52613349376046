import * as React from 'react';
import {useEffect, useState} from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoutIcon from '@mui/icons-material/Logout';
import {useNavigate} from "react-router-dom";
import '../Components/Style/_Navbar.scss';
import {FormattedMessage, useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../i18n/messages";
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import {styled} from "@mui/material/styles";
import {Avatar, Badge, Button, Grid} from "@mui/material";
import {useSelector} from "react-redux";
import {getUserLoggedSelector} from "../../../store/selectors/sessionSelector";
import {Image, UserRoleUtils} from "../../../corelogic/models/user";
import GroupIcon from '@mui/icons-material/Group';
import {DividingHR} from "./Utils/DividingHR";
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import DescriptionIcon from '@mui/icons-material/Description';
import StoreIcon from '@mui/icons-material/Store';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import {NavbarClassicButton, NavbarClassicButtonPersonnalized, NavbarDropDownButton} from "./Utils/NavbarButton";
import {Person} from "@mui/icons-material";
import HomeIcon from '@mui/icons-material/Home';

interface ResponsiveAppBarInterface {
    onLogoutClick: () => void
}

const ResponsiveAppBar = (props: ResponsiveAppBarInterface) => {
    const {onLogoutClick} = props
    const {userLogged} = useSelector(getUserLoggedSelector)
    const navigation = useNavigate()
    const intl = useIntl()
    const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null)
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null)
    const [themeValueStringToChangeSpecificColor, setThemeValueStringToChangeSpecificColor] = useState("")
    const [imageValue64Format, setImageValue64Format] = useState<string | undefined>("")

    useEffect(() => {
        const themeSodiwinAppValue = localStorage.getItem("themeSodiwinApp")
        if (themeSodiwinAppValue === "dark") {
            setThemeValueStringToChangeSpecificColor("dark")
        } else {
            setThemeValueStringToChangeSpecificColor("light")
        }
    }, [])

    useEffect(() => {
        if (userLogged && userLogged.avatar) {
            //Json stringify l'objet de type File pour pouvoir le manipuler
            const imageObjectToJsonString = JSON.stringify(userLogged.avatar)
            const imageJsonStringToObject: Image = JSON.parse(imageObjectToJsonString)
            //On envoit la value en base64 dans le use state pour preview image
            setImageValue64Format(imageJsonStringToObject.image)
        }
    }, [imageValue64Format, userLogged])

    const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElNav(event.currentTarget)
    }
    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleCloseNavMenu = () => {
        setAnchorElNav(null)
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    const handleUserManualClick = () => {
        handleCloseUserMenu()
    }

    const switchTheme = () => {
        handleCloseUserMenu()
        const themeSodiwinAppValue = localStorage.getItem("themeSodiwinApp")
        if (themeSodiwinAppValue === "dark") {
            localStorage.setItem("themeSodiwinApp", "light")
        } else {
            localStorage.setItem("themeSodiwinApp", "dark")
        }
        window.location.reload()
    }

    const StyledBadge = styled(Badge)(({theme}) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }))

    return (
        <AppBar position="sticky"
                className="appbar-style"
                sx={{
                    backgroundColor: "background.main"
                }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    {/***************************NAV LINKS RESPONSIVE SCREEN ****************************/}
                    <Box sx={{flexGrow: 1, display: {xs: 'flex', sm: 'flex', md: 'flex', lg: 'none'}}}>
                        <Button variant="contained" startIcon={<MenuIcon/>} onClick={handleOpenNavMenu}>
                            <Typography>
                                Menu
                            </Typography>
                        </Button>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: {xs: 'block', lg: 'none'},
                            }}
                        >
                            <MenuItem sx={{paddingLeft: "20px", width: "100%"}} onClick={handleCloseNavMenu}>
                                <NavbarClassicButton
                                    label={intl.formatMessage(getMessageDescriptor("homePageHeaderTitle"))}
                                    icon={<HomeIcon/>}
                                    path={"/"}/>
                            </MenuItem>
                            <MenuItem sx={{paddingLeft: "20px", width: "100%"}} onClick={handleCloseNavMenu}>
                                <NavbarClassicButton
                                    label={intl.formatMessage(getMessageDescriptor("orderListPageHeaderTitle"))}
                                    icon={<DescriptionIcon/>}
                                    path={"/orders"}/>
                            </MenuItem>
                            <MenuItem sx={{paddingLeft: "20px", width: "100%"}} onClick={handleCloseNavMenu}>
                                <NavbarClassicButton
                                    label={intl.formatMessage(getMessageDescriptor("itemListPageHeaderTitle"))}
                                    icon={<ShoppingBasketIcon/>}
                                    path={"/items"}/>
                            </MenuItem>
                            {(UserRoleUtils.isCommercial(userLogged) || UserRoleUtils.isAdmin(userLogged)) &&
                                <MenuItem sx={{paddingLeft: "20px", width: "100%"}} onClick={handleCloseNavMenu}>
                                    <NavbarDropDownButton
                                        label={intl.formatMessage(getMessageDescriptor("customerListPageHeaderTitle"))}
                                        icon={<StoreIcon/>}
                                        pathDropDown={[
                                            {
                                                label: intl.formatMessage(getMessageDescriptor("customerListPageHeaderSubTitle")),
                                                path: "/customers"
                                            },
                                            {
                                                label: intl.formatMessage(getMessageDescriptor("referencingFamiliesHeaderTitle")),
                                                path: "/customers/referencing/families"
                                            }
                                        ]}
                                    />
                                </MenuItem>
                            }
                            {UserRoleUtils.isAdmin(userLogged) &&
                                <MenuItem sx={{paddingLeft: "20px", width: "100%"}} onClick={handleCloseNavMenu}>
                                    <NavbarClassicButton
                                        label={intl.formatMessage(getMessageDescriptor("userListHeaderTitle"))}
                                        icon={<GroupIcon/>}
                                        path={"/users"}/>
                                </MenuItem>
                            }
                        </Menu>
                    </Box>
                    {/***************************NAV LOGO RESPONSIVE SCREEN ****************************/}
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            flexGrow: 1,
                            display: {xs: 'flex', sm: 'flex', md: 'flex', lg: 'none'},
                            alignItems: {xs: 'center'}
                        }}>
                        {userLogged?.defaultSociety.website ?
                            <a target="_blank" rel="noreferrer"
                               href={userLogged?.defaultSociety.website?.startsWith("http") ? `${userLogged?.defaultSociety.website}` : `https://${userLogged?.defaultSociety.website}`}>
                                <img src={`${process.env.REACT_APP_API_ENDPOINT}img/logo.png`}
                                     alt="logo" style={{width: "140px", height: "40px"}}/>
                            </a>
                            :
                            <img src={`${process.env.REACT_APP_API_ENDPOINT}img/logo.png`}
                                 alt="logo" style={{width: "140px", height: "40px"}}/>
                        }
                    </Typography>
                    {/***************************NAV LOGO DEFAULT SCREEN ****************************/}
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            mr: 2,
                            display: {xs: 'none', sm: 'none', md: 'none', lg: 'flex'},
                            alignItems: {xs: "center", md: "center"}
                        }}>
                        {userLogged?.defaultSociety.website ?
                            <a target="_blank" rel="noreferrer"
                               href={userLogged?.defaultSociety.website?.startsWith("http") ? `${userLogged?.defaultSociety.website}` : `https://${userLogged?.defaultSociety.website}`}>
                                <img src={`${process.env.REACT_APP_API_ENDPOINT}img/logo.png`}
                                     alt="logo" style={{width: "140px", height: "40px"}}/>
                            </a>
                            :
                            <img src={`${process.env.REACT_APP_API_ENDPOINT}img/logo.png`}
                                 alt="logo" style={{width: "140px", height: "40px"}}/>
                        }
                    </Typography>
                    {/***************************NAV LINKS DEFAULT LARGE SCREEN ****************************/}
                    <Box sx={{flexGrow: 1, display: {xs: 'none', sm: 'none', md: 'none', lg: 'flex'}}}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <NavbarClassicButton
                                    label={intl.formatMessage(getMessageDescriptor("homePageHeaderTitle"))}
                                    icon={<HomeIcon/>}
                                    path={"/"}/>
                            </Grid>
                            <Grid item>
                                <NavbarDropDownButton
                                    label={intl.formatMessage(getMessageDescriptor("salesPiecesHeaderTitle"))}
                                    icon={<DescriptionIcon/>}
                                    pathDropDown={[
                                        {
                                            label: intl.formatMessage(getMessageDescriptor("orderListPageHeaderTitle")),
                                            path: "/orders"
                                        },
                                        {
                                            label: intl.formatMessage(getMessageDescriptor("invoiceListPageHeaderTitle")),
                                            path: "/invoices"
                                        }
                                    ]}
                                />
                            </Grid>
                            <Grid item>
                                <NavbarClassicButton
                                    label={intl.formatMessage(getMessageDescriptor("itemListPageHeaderTitle"))}
                                    icon={<ShoppingBasketIcon/>}
                                    path={"/items"}/>
                            </Grid>
                            {(UserRoleUtils.isCommercial(userLogged) || UserRoleUtils.isAdmin(userLogged)) &&
                                <Grid item>
                                    <NavbarDropDownButton
                                        label={intl.formatMessage(getMessageDescriptor("customerListPageHeaderTitle"))}
                                        icon={<StoreIcon/>}
                                        pathDropDown={[
                                            {label: intl.formatMessage(getMessageDescriptor("customerListPageHeaderSubTitle")), path: "/customers"},
                                            {
                                                label: intl.formatMessage(getMessageDescriptor("referencingFamiliesHeaderTitle")),
                                                path: "/customers/referencing/families"
                                            }
                                        ]}
                                    />
                                </Grid>
                            }
                            {(UserRoleUtils.isAdmin(userLogged)) &&
                                <Grid item>
                                    <NavbarClassicButton
                                        label={intl.formatMessage(getMessageDescriptor("userListHeaderTitle"))}
                                        icon={<GroupIcon/>}
                                        path={"/users"}/>
                                </Grid>
                            }
                            {(UserRoleUtils.isSodiwin(userLogged)) &&
                                <Grid item>
                                    <NavbarClassicButton
                                        label={"SODIWIN TOOLS"}
                                        icon={<GroupIcon/>}
                                        path={"/admin"}/>
                                </Grid>
                            }
                        </Grid>
                    </Box>
                    {/*************************** AVATAR DEFAULT ****************************/}
                    <Box sx={{display: "flex", alignItems: "center", flexGrow: 0}}>
                        <Tooltip title={intl.formatMessage(getMessageDescriptor("genericTitleSwitchTheme"))}>
                            <IconButton onClick={switchTheme}
                                        sx={{p: "10px", m: "10px"}}>
                                {themeValueStringToChangeSpecificColor === "light" ?
                                    <Brightness4Icon sx={{fill: "black"}}/> :
                                    <Brightness7Icon sx={{fill: "white"}}/>}
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={`${userLogged?.lastName || ""} ${userLogged?.firstName || ""}`}>
                            <IconButton style={{color: "rgba(33, 150, 243, 1)"}}
                                        onClick={handleOpenUserMenu}
                                        sx={{p: 0, marginRight: "25px"}}>
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{vertical: 'bottom', horizontal: 'right'}}
                                    variant="dot"
                                >
                                    <Avatar alt={userLogged?.firstName + " " + userLogged?.lastName}
                                            src={`${process.env.REACT_APP_API_ENDPOINT}img/users/${userLogged?.avatar}`}/>
                                </StyledBadge>
                            </IconButton>
                        </Tooltip>
                        <Menu
                            sx={{
                                mt: '45px',
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                justifyContent: "center"
                            }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            onClose={handleCloseUserMenu}
                        >
                            <MenuItem sx={{paddingLeft: "20px"}} onClick={handleCloseUserMenu}>
                                <NavbarClassicButton
                                    label={intl.formatMessage(getMessageDescriptor("genericTitleMyProfile"))}
                                    icon={<Person/>}
                                    path={`/users/${userLogged?.id}`}/>
                            </MenuItem>
                            <MenuItem sx={{paddingLeft: "20px"}} onClick={handleCloseUserMenu}>
                                <NavbarClassicButton
                                    label={intl.formatMessage(getMessageDescriptor("societyPageHeaderTitle"))}
                                    icon={<StoreIcon/>}
                                    path={UserRoleUtils.isCustomer(userLogged) ? `customers/${userLogged?.linkedCustomer?.id}` : `society/${userLogged?.defaultSociety.id}`}/>
                            </MenuItem>
                            <DividingHR subContent/>
                            <MenuItem onClick={handleUserManualClick}>
                                <a target="_blank" rel="noreferrer"
                                   className={themeValueStringToChangeSpecificColor === "dark" ?
                                       "a_user_manual_guide_light_theme" : "a_user_manual_guide_dark_theme"}
                                   href={`${process.env.REACT_APP_API_ENDPOINT}docs/Documentation_utilisateur.pdf`}>
                                    <NavbarClassicButtonPersonnalized
                                        label={intl.formatMessage(getMessageDescriptor("genericTitleUserManual"))}
                                        icon={<MenuBookIcon/>}
                                        onClick={handleUserManualClick}/>
                                </a>
                            </MenuItem>
                            <MenuItem onClick={onLogoutClick}>
                                <NavbarClassicButtonPersonnalized
                                    isLogout={true}
                                    label={intl.formatMessage(getMessageDescriptor("genericTitleLogout"))}
                                    icon={<LogoutIcon/>}
                                />
                            </MenuItem>
                        </Menu>
                        <Box sx={{display: "flex", flexDirection: "column"}}>
                            <a target="_blank" rel="noreferrer" href="https://sodiwin.com/">
                                {themeValueStringToChangeSpecificColor === "light" ?
                                    <img src={`${process.env.PUBLIC_URL}/Logo300-blue.svg`}
                                         alt="logo sodiwin thème clair"
                                         style={{width: "100px", height: "70px"}}/>
                                    :
                                    <img src={`${process.env.PUBLIC_URL}/logo-sodiwin-blanc.svg`}
                                         alt="logo sodiwin thème sombre"
                                         style={{width: "100px", height: "70px"}}/>
                                }
                            </a>
                        </Box>
                    </Box>
                </Toolbar>
            </Container>
            <Typography
                variant="subtitle2"
                noWrap
                component="div"
                color="text.primary"
                sx={{
                    position: "absolute",
                    right: 5,
                    bottom: 0
                }}
            >
                <FormattedMessage
                    {...getMessageDescriptor("appVersion")}
                    values={{
                        version: process.env.REACT_APP_VERSION,
                    }}/>
                {process.env.NODE_ENV !== "production" &&
                    <>
                        <Typography variant="inherit"
                                    component="span" color="text.primary" marginX={0.5}>|</Typography>
                        <Typography variant="inherit" component="span" color="secondary">
                            {process.env.NODE_ENV}
                        </Typography>
                    </>
                }
            </Typography>
        </AppBar>
    )
        ;
};
export default ResponsiveAppBar;
