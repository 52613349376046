import {Society} from './society'
import {Parameter, Representative, VatType} from './parameter'
import {Customer} from './customer'
import {Item, PaginationFilter} from './item'

export interface InvoiceWithCount {
    count: number
    invoices: Invoice[]
}


export interface Invoice {
    id: number
    society?: Society
    isCreditNote?: boolean
    sodiwinNumInvoice?: string
    reference?: string
    creationDate?: Date
    billingDate?: Date
    invoiceAddressBusinessName?: string
    invoiceAddress1?: string
    invoiceAddress2?: string
    invoiceAddress3?: string
    invoiceAddressZipCode1?: string
    invoiceAddressZipCode2?: string
    invoiceAddressCity?: string
    invoiceAddressCountry?: Parameter
    billedCustomer?: Customer
    saleRepresentative?: Representative
    totals?: InvoiceTotals
    userLogin?: string
    dueDate?: Date
}

export interface InvoiceFilter extends PaginationFilter {
    sodiwinNumInvoice?: string
    searchInput?: string
    billingDate?: Date
    billedCustomerCode?: string
    originalSalesDocumentType?: string
    originalSalesDocumentNum?: string
    itemCode?: string
    settledOnly?: boolean
}

export class InvoiceFilterImpl implements InvoiceFilter {
    sodiwinNumInvoice?: string;
    searchInput?: string;
    billingDate?: Date;
    billedCustomerCode?: string;
    originalSalesDocumentType?: string;
    originalSalesDocumentNum?: string;
    itemCode?: string;
    settledOnly?: boolean;
    size?: number
    page?: number

    constructor(filter: InvoiceFilter) {
        this.sodiwinNumInvoice = filter.sodiwinNumInvoice;
        this.searchInput = filter.searchInput;
        this.billingDate = filter.billingDate;
        this.billedCustomerCode = filter.billedCustomerCode;
        this.originalSalesDocumentType = filter.originalSalesDocumentType;
        this.originalSalesDocumentNum = filter.originalSalesDocumentNum;
        this.itemCode = filter.itemCode;
        this.settledOnly = filter.settledOnly;
        this.size = filter.size;
        this.page = filter.page;
    }

    getFilters(): InvoiceFilter {
        return {
            sodiwinNumInvoice: this.sodiwinNumInvoice,
            searchInput: this.searchInput,
            billingDate: this.billingDate,
            billedCustomerCode: this.billedCustomerCode,
            originalSalesDocumentType: this.originalSalesDocumentType,
            originalSalesDocumentNum: this.originalSalesDocumentNum,
            itemCode: this.itemCode,
            settledOnly: this.settledOnly
        }
    }

    toSearchParam(): URLSearchParams {
        const params = new URLSearchParams();
        const p: any = {};
        if (this.sodiwinNumInvoice) p.sni = this.sodiwinNumInvoice;
        if (this.searchInput) p.si = this.searchInput;
        if (this.billingDate) p.bd = this.billingDate.getTime();
        if (this.billedCustomerCode) p.bcc = this.billedCustomerCode;
        if (this.originalSalesDocumentType) p.osdt = this.originalSalesDocumentType;
        if (this.originalSalesDocumentNum) p.osdn = this.originalSalesDocumentNum;
        if (this.itemCode) p.ic = this.itemCode;
        if (this.settledOnly !== undefined && this.settledOnly != null && String(this.settledOnly) !== "") p.so = String(this.settledOnly);
        let json = JSON.stringify(p);
        if (json !== '{}') {
            const encodedP = btoa(json);
            params.append('pa', encodedP);
        }
        if (this.size !== undefined) params.append('size', String(this.size));
        if (this.page !== undefined) params.append('page', String(this.page));
        return params;
    }

    static fromSearchParam(params: URLSearchParams): InvoiceFilterImpl {
        const encodedP = params.get('pa');
        let p: any = {};
        try {
            if (encodedP !== null)
                p = JSON.parse(atob(encodedP));
        } catch (e) {
            console.error('Failed to decode parameter:', e);
        }
        const filter: InvoiceFilter = {
            sodiwinNumInvoice: p.sni,
            searchInput: p.si,
            billingDate: p.bd ? new Date(p.bd) : undefined,
            billedCustomerCode: p.bcc,
            originalSalesDocumentType: p.osdt,
            originalSalesDocumentNum: p.osdn,
            itemCode: p.ic,
            settledOnly: p.so ? p.so === 'true' : undefined,
        };
        const filterImpl = new InvoiceFilterImpl(filter);
        filterImpl.size = params.get('size') ? Number(params.get('size')) : undefined;
        filterImpl.page = params.get('page') ? Number(params.get('page')) : undefined;
        return filterImpl;
    }

    count(): number {
        return Object.entries(this)
            .filter(([key, value]) => value !== undefined && key !== 'size' && key !== 'page' && key !== 'searchInput')
            .length;
    }
}

export interface InvoiceLine {
    id: number
    item?: Item
    label?: string
    quantity?: number
    unit?: string
    byHowMuch?: number
    secondUnit?: string
    quantitySecondUnit?: number
    grossPrice?: number
    discount1?: number
    discount2?: number
    discount3?: number
    netPrice?: number
    vat?: VatType
    netWeight?: number
    grossWeight?: number
    sodiwinLineId?: string
    free?: boolean
    originalSalesDocumentType?: string
    originalSalesDocument?: SalesDocument
}

export interface InvoiceTotals {
    discount?: number
    totalGrossTaxExcluded?: number
    shippingCostsVat?: VatType
    totalShippingCosts?: number
    totalVat?: number
    totalTaxExcluded?: number
    totalWithTax?: number
    totalPaid?: number
}

export interface SalesDocument {
    id: number
    numDocument?: string
}