import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {ThunkResult} from "store/appState";
import {User, UserFilter, UserForm, UserPasswordForm} from "../../models/user";
import {UserGateway} from "../../gateways/UserGateway";
import {AuthenticationGateway} from "../../gateways/AuthenticationGateway";
import {PaginationFilter} from "../../models/item";

export const retrieveAllUsersFiltered = (filterValue: UserFilter, paginationFilter: PaginationFilter):
    ThunkResult<{ userGateway: UserGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {userGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.users.USERS_RETRIEVING})
        try {
            const usersData = await userGateway.retrieveAllFiltered(filterValue, paginationFilter)
            dispatch({type: APP_ACTIONS_TYPES.users.USERS_RETRIEVED, payload: usersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveSelectedUser = (userId: number):
    ThunkResult<{ userGateway: UserGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {userGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.users.USER_FETCHING})
        try {
            const usersData = await userGateway.retrieveById(userId)
            dispatch({type: APP_ACTIONS_TYPES.users.USER_SELECTING, payload: usersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveUserFormDataValues = ():
    ThunkResult<{ userGateway: UserGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {userGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.users.USER_FETCHING})
        try {
            const usersFormDataVal = await userGateway.retrieveAllDataFormField()
            dispatch({type: APP_ACTIONS_TYPES.users.USERS_FORM_DATA_FIELDS, payload: usersFormDataVal})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const createUser = (user: UserForm):
    ThunkResult<{ userGateway: UserGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {userGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.users.USER_FETCHING})
        try {
            const userData = await userGateway.create(user)
            dispatch({type: APP_ACTIONS_TYPES.users.USER_ADD, payload: userData})
            dispatch({type: APP_ACTIONS_TYPES.users.USER_SELECTING, payload: userData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateUser = (user: User):
    ThunkResult<{ userGateway: UserGateway, authenticationGateway: AuthenticationGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {userGateway, authenticationGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.users.USER_FETCHING})
        try {
            const usersData = await userGateway.update(user)
            const currentUser = await authenticationGateway.retrieveCurrent()
            dispatch({type: APP_ACTIONS_TYPES.users.USER_UPDATE, payload: usersData})
            if (currentUser && usersData.id === currentUser.id) {
                dispatch({type: APP_ACTIONS_TYPES.session.SESSION_DATA_UPDATE, payload: currentUser})
            }
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateUserPassword = (userPasswordForm: UserPasswordForm):
    ThunkResult<{ userGateway: UserGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {userGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.users.USER_FETCHING})
        try {
            const usersData = await userGateway.updatePassword(userPasswordForm)
            dispatch({type: APP_ACTIONS_TYPES.users.USER_UPDATE_PASSWORD, payload: usersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateUserPasswordAsAdmin = (userPasswordForm: string, userId: number):
    ThunkResult<{ userGateway: UserGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {userGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.users.USER_FETCHING})
        try {
            const usersData = await userGateway.updatePasswordAdmin(userPasswordForm, userId)
            dispatch({type: APP_ACTIONS_TYPES.users.USER_UPDATE_PASSWORD, payload: usersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const deleteUser = (id: number):
    ThunkResult<{ userGateway: UserGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {userGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.users.USER_FETCHING})
        try {
            await userGateway.delete(id)
            dispatch({type: APP_ACTIONS_TYPES.users.USER_DELETE, payload: id})
            const usersData = await userGateway.retrieveAllFiltered({},{})
            dispatch({type: APP_ACTIONS_TYPES.users.USERS_RETRIEVED, payload: usersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}