import React, {useEffect, useState} from 'react';
import {BadgeProps, Button, Grid, Paper, Tooltip} from "@mui/material";
import {SearchBar} from "../../Utils/SearchBar";
import {getMessageDescriptor} from "../../../../../i18n/messages";
import {useIntl} from "react-intl";
import ReturnButton from "../../Utils/ReturnButton";
import {DividingHR} from "../../Utils/DividingHR";
import {TextValueField} from "../../Utils/TextValueField";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import {styled} from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {CustomerItemReferencingType} from "../../../../secondary/InMemory/Data/enums";
import {useDebounce} from "@uidotdev/usehooks";

interface CustomerItemReferencingHeaderInterface {
    type?: CustomerItemReferencingType
    counter?: number
    data?: { code: string, label: string }
    onChangeSearchInput?: (inputValue: string) => void
    getToggleFiltersState?: (state: boolean) => void
    filterCount?: number
    onAddReferencingClick?: () => void
    fetching?: boolean
}

const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

export const CustomerItemReferencingHeader = (props: CustomerItemReferencingHeaderInterface) => {
    const {
        type,
        counter,
        data,
        onChangeSearchInput,
        getToggleFiltersState,
        filterCount,
        onAddReferencingClick,
        fetching
    } = props
    const intl = useIntl()
    const [toggleFilters, setToggleFilters] = useState(true)
    const [searchBarValue, setSearchBarValue] = useState("")
    const delaySearchValue = useDebounce(searchBarValue, 500)

    useEffect(() => {
        if (onChangeSearchInput) {
            onChangeSearchInput(delaySearchValue)
        }
    }, [onChangeSearchInput, delaySearchValue])

    const handleSearchBarChange = (inputValue: string) => {
        setSearchBarValue(inputValue)
    }

    const handleResetSearchBar = () => {
        setSearchBarValue("")
    }

    const handleOpenFilters = () => {
        setToggleFilters(prevState => !prevState)
        if (getToggleFiltersState) {
            getToggleFiltersState(toggleFilters)
        }
    }

    const handleAddReferencingClick = () => {
        if (onAddReferencingClick) {
            onAddReferencingClick()
        }
    }

    const getTextFromType = (type: CustomerItemReferencingType) => {
        switch (type) {
            case CustomerItemReferencingType.REFERENCING_BY_CUSTOMER:
                return intl.formatMessage(getMessageDescriptor("referencingTypeCustomer"))
            case CustomerItemReferencingType.REFERENCING_BY_SUPER_FAMILY:
                return intl.formatMessage(getMessageDescriptor("referencingTypeSuperFamily"))
            case CustomerItemReferencingType.REFERENCING_BY_FAMILY:
                return intl.formatMessage(getMessageDescriptor("referencingTypeFamily"))
            default:
                return ""
        }
    }

    return (
        <>
            {(data && type) &&
                <Paper elevation={3} sx={{backgroundColor: "common.main", p: 1}}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <ReturnButton/>
                                </Grid>
                                <Grid item mx={2}>
                                    <DividingHR
                                        subContent
                                        style={{height: "30px", width: 1, margin: 0}}/>
                                </Grid>
                                <Grid item mr={1}>
                                    <StyledBadge badgeContent={filterCount} color="error">
                                        {toggleFilters ?
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("itemListFilterOpenTitle"))}>
                                                <Button variant="contained" onClick={handleOpenFilters}>
                                                    <MenuIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                                </Button>
                                            </Tooltip>
                                            :
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("itemListFilterCloseTitle"))}>
                                                <Button variant="contained" onClick={handleOpenFilters}>
                                                    <MenuOpenIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                                </Button>
                                            </Tooltip>
                                        }
                                    </StyledBadge>
                                </Grid>
                                <Grid item mx={2}>
                                    <DividingHR
                                        subContent
                                        style={{height: "30px", width: 1, margin: 0}}/>
                                </Grid>
                                {<TextValueField
                                    style={{
                                        fontWeight: "bolder"
                                    }}
                                    text={getTextFromType(type)}
                                    value={fetching ? "" : data?.code + " - " + data?.label}/>}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <SearchBar
                                label={intl.formatMessage(getMessageDescriptor("searchBarItem"))}
                                onChange={handleSearchBarChange} onResetClick={handleResetSearchBar}
                                value={searchBarValue}/>
                        </Grid>
                    </Grid>
                </Paper>}
            {(!data && !type) &&
                <Paper elevation={3} sx={{backgroundColor: "common.main", p: 1}}>
                    <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <ReturnButton/>
                                </Grid>
                                <Grid item mx={2}>
                                    <DividingHR
                                        subContent
                                        style={{height: "30px", width: 1, margin: 0}}/>
                                </Grid>
                                <Grid item mr={1}>
                                    <StyledBadge badgeContent={filterCount} color="error">
                                        {toggleFilters ?
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("itemListFilterOpenTitle"))}>
                                                <Button variant="contained" onClick={handleOpenFilters}>
                                                    <MenuIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                                </Button>
                                            </Tooltip>
                                            :
                                            <Tooltip
                                                title={intl.formatMessage(getMessageDescriptor("itemListFilterCloseTitle"))}>
                                                <Button variant="contained" onClick={handleOpenFilters}>
                                                    <MenuOpenIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                                </Button>
                                            </Tooltip>
                                        }
                                    </StyledBadge>
                                </Grid>
                                <Grid item mx={2}>
                                    <DividingHR
                                        subContent
                                        style={{height: "30px", width: 1, margin: 0}}/>
                                </Grid>
                                <Grid item>
                                    <TextValueField
                                        style={{
                                            fontWeight: "bolder"
                                        }}
                                        text={intl.formatMessage(getMessageDescriptor("referencingCustomerFamilies"))}
                                        value={counter}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container alignItems="center">
                                <Grid item>
                                    <Button variant="contained" onClick={handleAddReferencingClick}>
                                        {intl.formatMessage(getMessageDescriptor("referencingAdd"))}
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <SearchBar
                                        label={intl.formatMessage(getMessageDescriptor("searchBarFamilyCode"))}
                                        onChange={handleSearchBarChange} onResetClick={handleResetSearchBar}
                                        value={searchBarValue}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>}
        </>
    )
}