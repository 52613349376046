import React, {useCallback, useEffect, useState} from "react";
import {ErrorDialogAPIStore} from "./Utils/ErrorAPIDialog";
import Navbar from "./Navbar";
import NavigationRoutes from "./Config/routingConfig";
import {useDispatch, useSelector} from "react-redux";
import {getActiveAuthTokenSelector, getUserLoggedSelector} from "../../../store/selectors/sessionSelector";
import SignIn from "./SignIn";
import {getAPINetworkErrorSelector} from "../../../store/selectors/genericsSelector";
import {
    authenticationRefreshToken,
    retrieveCurrentlyLoggedInUser,
    userLogout
} from "corelogic/usecase/session/sessionActions";
import {getExpirationDateFromAuthToken} from "../../../corelogic/utils/tools";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../i18n/messages";
import {useNavigate} from "react-router-dom";
import {
    retrieveCustomerSpecificData,
    retrieveServerConfig,
    retrieveServerVersion
} from "../../../corelogic/usecase/server/server";

export const Main = () => {
    /*  const handleOnIdle = () => {
          console.log('user is idle')
          console.log('last active', getLastActiveTime())
          setOpenLogoutDialog(true)
      }

      const handleOnActive = (event?: Event) => {
          console.log('user is active', event)
          console.log('time remaining', getRemainingTime())
          setOpenLogoutDialog(false)
      }

      const handleOnAction = (event?: Event) => {
          console.log('user did something', event)
      }*/

    const MS_IN_ONE_SECOND = 1000
    const minBeforeLogout = (60 * MS_IN_ONE_SECOND)

    const dispatch = useDispatch()
    const intl = useIntl()
    const navigate = useNavigate()
    const {userLogged, fetching} = useSelector(getUserLoggedSelector)
    const [externalLogoutMsg, setExternalLogoutMsg] = useState("")
    const {activeAuthToken} = useSelector(getActiveAuthTokenSelector)
    const {apiNetworkError} = useSelector(getAPINetworkErrorSelector)
    const [authTokenVerification, setAuthTokenVerification] = useState(true)
    // const [openLogoutDialog, setOpenLogoutDialog] = useState(false)
    /*  const {getRemainingTime, getLastActiveTime} = useIdleTimer({
          timeout: 15_000,
          onIdle: handleOnIdle,
          onActive: handleOnActive,
          onAction: handleOnAction,
          debounce: 500,
          crossTab: true
      })*/

    useEffect(() => {
        if (!fetching && userLogged) {
            dispatch(retrieveServerVersion())
            dispatch(retrieveServerConfig())
            dispatch(retrieveCustomerSpecificData())
        }
    }, [fetching, userLogged,dispatch]);

    const handleLogout = useCallback(() => {
        dispatch<any>(userLogout())
        localStorage.removeItem('sodiwin-logout')
        navigate("/")
    }, [navigate, dispatch])

    /* const handleDisconnectSession = useCallback(() => {
         handleLogout()
         setOpenLogoutDialog(false)
     }, [handleLogout])

     const handleContinueSession = useCallback(() => {
         // dispatch<any>(authenticationRefreshToken())
         setOpenLogoutDialog(false)
     }, [])*/

    const handleUnauthorizedUser = useCallback(() => {
        localStorage.removeItem("sodiwin-token-exp")
        if (userLogged)
            setExternalLogoutMsg(intl.formatMessage(getMessageDescriptor("appLoginTokenExp")))
        handleLogout()
    }, [handleLogout, intl, userLogged])

    const handleBrowserStorageEvent = useCallback((event: StorageEvent) => {
        if (userLogged && event.key === 'sodiwin-logout' && !localStorage.getItem("sodiwin-logout")) {
            setExternalLogoutMsg(intl.formatMessage(getMessageDescriptor("appLogoutOtherTab")))
            handleLogout()
        } else if (userLogged && event.key === 'sodiwin-token-exp' && !localStorage.getItem("sodiwin-token-exp")) {
            setExternalLogoutMsg(intl.formatMessage(getMessageDescriptor("appLoginTokenExp")))
            handleLogout()
        }
    }, [handleLogout, intl, userLogged])

    useEffect(() => {
        dispatch<any>(authenticationRefreshToken())
    }, [dispatch])

    useEffect(() => {
        // if (!userLogged && !activeAuthToken && apiNetworkError)
        if (!userLogged && !activeAuthToken)
            setAuthTokenVerification(false)
    }, [apiNetworkError, userLogged, activeAuthToken])

    useEffect(() => {
        window.addEventListener("UNAUTHORIZED_USER", handleUnauthorizedUser)
        window.addEventListener('storage', handleBrowserStorageEvent);
        return () => {
            window.removeEventListener("UNAUTHORIZED_USER", handleUnauthorizedUser)
            window.removeEventListener("storage", handleUnauthorizedUser)
        }
    }, [handleUnauthorizedUser, handleBrowserStorageEvent])

    useEffect(() => {
        if (fetching)
            setExternalLogoutMsg("")
        if (!userLogged && !fetching && activeAuthToken) {
            dispatch<any>(retrieveCurrentlyLoggedInUser())
        } else if (userLogged && activeAuthToken) {
            if (!localStorage.getItem("sodiwin-logout")) {
                localStorage.setItem("sodiwin-logout", "logout")
            }
            if (!localStorage.getItem("sodiwin-token-exp")) {
                localStorage.setItem("sodiwin-token-exp", "token-exp")
            }
            setAuthTokenVerification(false)
            setExternalLogoutMsg("")
        }
    }, [userLogged, activeAuthToken, fetching, dispatch])

    useEffect(() => {
        let refreshCall: NodeJS.Timeout | null = null
        if (activeAuthToken) {
            const now = new Date()
            const tokenExpDate = getExpirationDateFromAuthToken(activeAuthToken, now)
            let refreshTime = 10 * minBeforeLogout
            if (tokenExpDate !== now) {
                refreshTime = (tokenExpDate.getTime() - now.getTime()) - minBeforeLogout // Lance le refresh à expiration du token - 1 min
            }

            refreshCall = setTimeout(() => {
                dispatch<any>(authenticationRefreshToken())
                // setOpenLogoutDialog(true)
            }, refreshTime)
        }
        return () => {
            if (refreshCall) {
                clearTimeout(refreshCall)
            }
        }
    }, [minBeforeLogout, activeAuthToken, dispatch])

    if (authTokenVerification)
        return React.Fragment

    return <>
        {userLogged ? <>
                <Navbar onLogoutClick={handleLogout}/>
                <NavigationRoutes/>
                <ErrorDialogAPIStore/>
                {/* <LogoutDialog open={openLogoutDialog} onDisconnectClick={handleDisconnectSession}
                              onContinueClick={handleContinueSession}
                              autoValidateTimer={minBeforeLogout / MS_IN_ONE_SECOND}/>*/}
            </>
            : <SignIn initialMsg={externalLogoutMsg}/>}
    </>
}

const LogoutDialog = (props: {
    open: boolean,
    onDisconnectClick: () => void,
    onContinueClick: () => void,
    autoValidateTimer?: number
}) => {
    const {open, onDisconnectClick, onContinueClick, autoValidateTimer = 60} = props
    const [timer, setTimer] = useState(autoValidateTimer)

    useEffect(() => {
        let timerCalc: NodeJS.Timeout | null = null
        if (open) {
            setTimer(autoValidateTimer)
            timerCalc = setInterval(() => {
                setTimer(prevVal => {
                    return prevVal - 1
                })
            }, 1000)
        }
        return () => {
            if (timerCalc)
                clearInterval(timerCalc)
        }
    }, [open, autoValidateTimer])

    useEffect(() => {
        if (timer === 0)
            onDisconnectClick()
    }, [timer, onDisconnectClick])

    return <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {"Info session"}
        </DialogTitle>
        <DialogContent>
            <DialogContentText>
                Votre session est sur le point de se terminer.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onDisconnectClick}>Déconnecter {timer && `(${timer})`}</Button>
            <Button onClick={onContinueClick}> Rester connecter </Button>
        </DialogActions>
    </Dialog>
}