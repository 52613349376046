import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";

export const columnsCustomerReferencingFamilies: GridColDef[] = [
    // {field: 'id', headerName: 'ID', width: 90},
    {
        field: 'codeSF',
        headerName: 'headerGridSuperFamilyCode',
        width: 400,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
            return params.row.codeSF
        }
    },
    {
        field: 'labelSF',
        headerName: 'headerGridSuperFamilyLabel',
        width: 400,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
            return params.row.labelSF
        }
    },
    {
        field: 'codeF',
        headerName: 'headerGridFamilyCode',
        type: 'number',
        width: 400,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
            return params.row.codeF
        }
    },
    {
        field: 'labelF',
        headerName: 'headerGridFamilyLabel',
        type: 'number',
        width: 400,
        headerAlign: "left",
        align: "left",
        renderCell: (params) => {
            return params.row.labelF
        }
    }
]