import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {API_PREFIX} from "../../../corelogic/config";
import {buildUrl} from "../../../corelogic/utils/tools";
import {ServerGateway} from "../../../corelogic/gateways/ServerGateway";
import {CustomerSpecificData} from "../../../corelogic/models/CustomerSpecificData";
import {
    CustomerSpecificDataResponse,
    mapCustomerSpecificDataResponseApiResponseToCustomerSpecificDataResponse
} from "./Model/serverApi";
import {ServerConfigData} from "corelogic/models/serverConfigData";

export class ApiServerGateway implements ServerGateway {
    async retrieveCustomerSpecificData(): Promise<CustomerSpecificData | undefined> {
        return apiFetchApiResponse<CustomerSpecificDataResponse>({
            url: buildUrl(API_PREFIX.server, "specific-data"),
        }).then((x) => {
            if (x) {
                return mapCustomerSpecificDataResponseApiResponseToCustomerSpecificDataResponse(x)
            }
        })
    }

    async retrieveServerVersion(): Promise<string | undefined> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.server, "version"),
        })
    }

    async retrieveServerConfig(): Promise<ServerConfigData | undefined> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.server, "config"),
        })
    }
}