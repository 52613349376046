export const APP_ACTIONS_TYPES = {
    generics: {
        NETWORK_ERROR_CATCH: "ERROR_CATCH",
        NETWORK_ERROR_CLEAN: "ERROR_CLEAN",
        API_ERROR_CATCH: "API_ERROR_CATCH",
        API_ERROR_CLEAN: "API_ERROR_CLEAN",
    },
    errorApi: {
        ERROR_API_RETRIEVED: "ERROR_API_RETRIEVED",
        ERROR_API_RETRIEVING: "ERROR_API_RETRIEVED",
        ERROR_API_ADD: "ERROR_API_ADD",
        ERROR_API_REMOVE: "ERROR_API_REMOVE"
    },
    session: {
        SESSION_LOGIN: "SESSION_LOGIN",
        SESSION_LOGOUT: "SESSION_LOGOUT",
        SESSION_AUTH_TOKEN: "SESSION_AUTH_TOKEN",
        SESSION_SWITCH_LANGUAGE: "SESSION_SWITCH_LANGUAGE",
        SESSION_CLEAN_LANGUAGE: "SESSION_CLEAN_LANGUAGE",
        LOGIN_FETCHING: "LOGIN_FETCHING",
        SESSION_DATA_UPDATE: "SESSION_DATA_UPDATE"
    },
    server: {
        CUSTOMER_SPECIFIC_SELECTING: "CUSTOMER_SPECIFIC_RETRIEVING",
        CUSTOMER_SPECIFIC_FETCHING: "CUSTOMER_SPECIFIC_RETRIEVED",
        CUSTOMER_SPECIFIC_RESET_SELECTING: "CUSTOMER_SPECIFIC_RESET_SELECTING",
        SERVER_VERSION_SELECTING: "SERVER_VERSION_SELECTING",
        SERVER_VERSION_FETCHING: "SERVER_VERSION_FETCHING",
        SERVER_CONFIG_SELECTING: "SERVER_CONFIG_SELECTING",
        SERVER_CONFIG_FETCHING: "SERVER_CONFIG_FETCHING"

    },
    items: {
        ITEMS_RETRIEVING: "ITEMS_RETRIEVING",
        ITEMS_RETRIEVED: "ITEMS_RETRIEVED",
        ITEM_FETCHING: "ITEM_FETCHING",
        ITEM_SELECTING: "ITEM_SELECTING",
        ITEM_RESET_SELECTION: "ITEM_RESET_SELECTION",
        ITEM_RESET_DATA: "ITEM_RESET_DATA",
        ITEM_ADD_FAVORITE: "ITEM_ADD_FAVORITE",
        ITEM_REMOVE_FAVORITE: "ITEM_REMOVE_FAVORITE",
        ITEM_FORM_DATA_FIELDS: "ITEM_FORM_DATA_FIELDS",
        ITEM_PACKAGING_TYPES_FETCHING: "ITEM_PACKAGING_TYPES_FETCHING",
        ITEM_PACKAGING_TYPES_RETRIEVED: "ITEM_PACKAGING_TYPES_RETRIEVED",
        ITEM_PACKAGING_TYPES_ADD: "ITEM_PACKAGING_TYPES_ADD",
        ITEM_PACKAGING_TYPES_UPDATE: "ITEM_PACKAGING_TYPES_UPDATE",
        ITEM_PACKAGING_TYPES_DELETE: "ITEM_PACKAGING_TYPES_DELETE"
    },
    orders: {
        ORDERS_RETRIEVING: "ORDERS_RETRIEVING",
        ORDERS_RETRIEVED: "ORDERS_RETRIEVED",
        ORDER_FETCHING: "ORDER_FETCHING",
        ORDER_SELECTING: "ORDER_SELECTING",
        ORDER_RESET_SELECTION: "ORDER_RESET_SELECTION",
        ORDER_RESET_DATA: "ORDER_RESET_DATA",
        ORDER_ADD: "ORDER_ADD",
        ORDER_UPDATE: "ORDER_UPDATE",
        ORDER_DELETE: "ORDER_DELETE",
        /** ORDER LINES **/
        ORDER_LINES_RETRIEVING: "ORDER_LINES_RETRIEVING",
        ORDER_LINES_RETRIEVED: "ORDER_LINES_RETRIEVED",
        ORDER_LINES_RESET_DATA: "ORDER_LINES_RESET_DATA",
        ORDER_LINES_ADD: "ORDER_LINES_ADD",
        ORDER_LINES_DELETE: "ORDER_LINES_DELETE",
        ORDER_LINES_UPDATE_QTY: "ORDER_LINES_UPDATE_QTY",
        ORDER_LINES_UPDATE_PRICING: "ORDER_LINES_UPDATE_PRICING",
        ORDER_LINES_UPDATE_LABEL: "ORDER_LINES_UPDATE_LABEL",
        ORDER_LINES_UPDATE_QTY_ERROR: "ORDER_LINES_UPDATE_QTY_ERROR",
        ORDER_LINES_UPDATE_QTY_ERROR_CLEAR: "ORDER_LINES_UPDATE_QTY_ERROR_CLEAR",
        ORDER_LINES_UPDATE_QTY_CANCEL: "ORDER_LINES_UPDATE_QTY_CANCEL",
        ORDER_LINES_UPDATE_FETCHING: "ORDER_LINES_UPDATE_FETCHING",
        /** ORDER COMMENTS **/
        ORDER_COMMENTS_RETRIEVING: "ORDER_COMMENTS_RETRIEVING",
        ORDER_COMMENTS_RETRIEVED: "ORDER_COMMENTS_RETRIEVED",
        ORDER_COMMENTS_CLEAR: "ORDER_COMMENTS_CLEAR",
        ORDER_COMMENTS_ADD: "ORDER_COMMENTS_ADD",
        ORDER_TOTAL_UPDATE: "ORDER_TOTAL_UPDATE",

    },
    customers: {
        CUSTOMERS_RETRIEVING: "CUSTOMERS_RETRIEVING",
        CUSTOMERS_RETRIEVED: "CUSTOMERS_RETRIEVED",
        CUSTOMERS_F_RETRIEVING: "CUSTOMERS_F_RETRIEVING",
        CUSTOMERS_F_RETRIEVED: "CUSTOMERS_F_RETRIEVED",
        CUSTOMER_RESET_DATA: "CUSTOMER_RESET_DATA",
        CUSTOMER_FETCHING: "CUSTOMER_FETCHING",
        CUSTOMER_SELECTING: "CUSTOMER_SELECTING",
        CUSTOMER_RESET_SELECTION: "CUSTOMER_RESET_SELECTION",
        CUSTOMER_FORM_DATA_FIELDS: "CUSTOMER_FORM_DATA_FIELDS",
        CUSTOMER_ADD: "CUSTOMER_ADD",
        CUSTOMER_UPDATE: "CUSTOMER_UPDATE",
        CUSTOMER_DELETE: "CUSTOMER_DELETE",
        CUSTOMER_ADDRESSES_FETCHING: "CUSTOMER_ADDRESSES_FETCHING",
        CUSTOMER_ADDRESSES_RETRIEVED: "CUSTOMER_ADDRESSES_RETRIEVED",
        CUSTOMER_ADDRESSES_RESET_DATA: "CUSTOMER_ADDRESSES_RESET_DATA",
        CUSTOMER_ADDRESSES_ADD: "CUSTOMER_ADDRESSES_ADD",
        CUSTOMER_ADDRESSES_UPDATE: "CUSTOMER_ADDRESSES_UPDATE",
        CUSTOMER_ADDRESSES_DELETE: "CUSTOMER_ADDRESSES_DELETE"
    },
    users: {
        USER_FETCHING: "USER_FETCHING",
        USERS_RETRIEVING: "USERS_RETRIEVING",
        USERS_RETRIEVED: "USERS_RETRIEVED",
        USERS_RESET_DATA: "USERS_RESET_DATA",
        USER_SELECTING: "USER_SELECTING",
        USER_RESET_SELECTING: "USER_RESET_SELECTING",
        USER_UPDATE: "USER_UPDATE",
        USER_ADD: "USER_ADD",
        USER_UPDATE_PASSWORD: "USER_UPDATE_PASSWORD",
        USERS_FORM_DATA_FIELDS: "USERS_FORM_DATA_FIELDS",
        USER_DELETE: "USER_DELETE"
    },
    societies: {
        SOCIETY_FETCHING: "SOCIETY_FETCHING",
        SOCIETIES_RETRIEVING: "SOCIETIES_RETRIEVING",
        SOCIETIES_RETRIEVED: "SOCIETIES_RETRIEVED",
        SOCIETIES_RESET_DATA: "SOCIETIES_RESET_DATA",
        SOCIETY_SELECTING: "SOCIETY_SELECTING",
        SOCIETY_RESET_SELECTING: "SOCIETY_RESET_SELECTING",
        SOCIETIES_FORM_DATA_FIELDS: "SOCIETIES_FORM_DATA_FIELDS",
        SOCIETY_UPDATE: "SOCIETY_UPDATE",
        SOCIETY_ADD: "SOCIETY_ADD",
        SOCIETY_DELETE: "SOCIETY_DELETE"
    },
    customerItemReference: {
        CUSTOMER_ITEM_REF_SF_AND_F_REF_RETRIEVED: "CUSTOMER_ITEM_REF_SF_AND_F_REF_RETRIEVED",
        CUSTOMER_ITEM_REF_SF_AND_F_REF_RETRIEVING: "CUSTOMER_ITEM_REF_SF_AND_F_REF_RETRIEVING",
        CUSTOMER_ITEM_REF_ADD_REFERENCE: "CUSTOMER_ITEM_REF_ADD_REFERENCE",
        CUSTOMER_ITEM_REF_REMOVE_REFERENCE: "CUSTOMER_ITEM_REF_REMOVE_REFERENCE",
        CUSTOMER_ITEM_REF_RETRIEVING: "CUSTOMER_ITEM_REF_RETRIEVING",
        CUSTOMER_ITEM_REF_RETRIEVED: "CUSTOMER_ITEM_REF_RETRIEVED",
        CUSTOMER_ITEM_REF_RESET_DATA: "CUSTOMER_ITEM_REF_RESET_DATA",
        CUSTOMER_ITEM_REF_RESET_DATA_SF_AND_F: "CUSTOMER_ITEM_REF_RESET_DATA_SF_AND_F"
    },
    parameters: {
        PARAMETER_FETCHING: "PARAMETER_FETCHING",
        PARAMETER_RETRIEVED: "PARAMETER_RETRIEVED"
    },
    stats: {
        STATS_RETRIEVING: "STATS_RETRIEVING",
        STATS_RETRIEVED: "STATS_RETRIEVED",
        STATS_RESET_DATA: "STATS_RESET_DATA",
        STATS_REPR_RETRIEVING: "STATS_REPR_RETRIEVING",
        STATS_REPR_RETRIEVED: "STATS_REPR_RETRIEVED",
        STATS_REPR_RESET_DATA: "STATS_REPR_RESET_DATA"

    },
    admin: {
        ADMIN_RUN_IMPORT: "ADMIN_RUN_IMPORT",
        ADMIN_IMPORTS_RUNNING: "ADMIN_IMPORTS_RUNNING"
    },

    invoices: {
        INVOICES_RETRIEVING: "INVOICES_RETRIEVING",
        INVOICES_RETRIEVED: "INVOICES_RETRIEVED",
        INVOICE_FETCHING: "INVOICE_FETCHING",
        INVOICE_SELECTING: "INVOICE_SELECTING",
        INVOICE_LINES_RETRIEVING: "INVOICE_LINES_RETRIEVING",
        INVOICE_LINES_RETRIEVED: "INVOICE_LINES_RETRIEVED",
        INVOICE_RESET_SELECTION: "INVOICE_RESET_SELECTION",
        INVOICE_RESET_DATA: "INVOICE_RESET_DATA",
    }
}