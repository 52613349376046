import React from 'react';
import {Box} from "@mui/material";
import {DataGrid} from "@mui/x-data-grid";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {StatisticTopItemValues} from "../../../../../corelogic/models/stats";
import {FormattedNumber, useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../../i18n/messages";

interface TopTenItemByTotalWT {
    data?: StatisticTopItemValues[]
    fetching?: boolean
}

export const GridStatsTopTenCustomerByTotalWT = (props: TopTenItemByTotalWT) => {
    const {data = [], fetching} = props
    const intl = useIntl()
    const columns: GridColDef[] = [
        {field: 'id', hideable: false},
        {field: 'code', headerName: intl.formatMessage(getMessageDescriptor("statsGridCode")), flex: 0.8},
        {
            field: 'label',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridLabel")),
            headerAlign: 'left',
            align: 'left',
            flex: 1.1,
        },
        {
            field: 'nbCustomerOrder',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridNbOrder")),
            headerAlign: 'left',
            align: 'left',
            type: 'number',
            flex: 0.5
        },
        {
            field: 'averageNbOfDistinctItems',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridItemDistinctPerOrder")),
            headerAlign: 'left',
            align: 'left',
            type: 'number',
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <Box>
                        {cellValues.row.averageNbOfDistinctItems && <FormattedNumber
                            value={cellValues.row.averageNbOfDistinctItems}
                            maximumFractionDigits={1}
                            style={"decimal"}/>}
                    </Box>
                )
            }
        },
        {
            field: 'averageSalesPerOrder',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridAverageSalesPerOrder")),
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <Box>
                        {cellValues.row.averageSalesPerOrder && <FormattedNumber
                            value={cellValues.row.averageSalesPerOrder}
                            maximumFractionDigits={2}
                            style={"currency"} currency={"EUR"}/>}
                    </Box>
                )
            }
        },
        {
            hideable: false,
            field: 'globalTurnover',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridTotalSales")),
            headerAlign: 'left',
            align: 'left',
            flex: 1,
            renderCell: (cellValues) => {
                return (
                    <Box>
                        {cellValues.row.globalTurnover && <FormattedNumber
                            value={cellValues.row.globalTurnover}
                            maximumFractionDigits={2}
                            style={"currency"} currency={"EUR"}/>}
                    </Box>
                )
            }
        },
        {
            field: 'percentageGlobalTurnover',
            headerName: intl.formatMessage(getMessageDescriptor("statsGridPercentage")),
            headerAlign: 'left',
            align: 'left',
            description: 'This column has a value getter and is not sortable.',
            flex: 0.7,
            renderCell: (cellValues) => {
                return (
                    <Box>
                        {cellValues.row.percentageGlobalTurnover && <FormattedNumber
                            value={cellValues.row.percentageGlobalTurnover}
                            maximumFractionDigits={2}
                            style={"percent"}/>}
                    </Box>
                )
            }
        }
    ]
    return (
        <Box sx={{height: "260px", width: '100%'}}>
            <DataGrid
                rows={data}
                initialState={{
                    columns: {
                        columnVisibilityModel: {
                            id: false,
                        }
                    },
                    sorting: {
                        sortModel: [{field: 'globalTurnover', sort: 'desc'}],
                    }
                }}
                disableColumnMenu
                getRowId={(row) => row.code}
                columns={columns.map(col => ({...col, sortable: false}))}
                density="compact"
                hideFooter
                loading={fetching}
                disableRowSelectionOnClick
                sx={{
                    "& .MuiDataGrid-cell:focus": {
                        outline: "none"
                    },
                    "& .MuiDataGrid-columnHeader:focus": {
                        outline: "none"
                    }
                }}
            />
        </Box>
    )
}