import {apiFetchApiResponse} from "../../../corelogic/utils/api";
import {buildUrl} from "../../../corelogic/utils/tools";
import {API_PREFIX} from "../../../corelogic/config";
import {CustomerItemReferenceGateway} from "../../../corelogic/gateways/CustomerItemReferenceGateway";
import {CustomerSuperFamiliesAndFamilies} from "../../../corelogic/models/customer";
import qs from "qs";
import {Item, ItemFilter} from "../../../corelogic/models/item";

export class ApiCustomerItemReferenceGateway implements CustomerItemReferenceGateway {
    addForCustomer(itemId: number, customerId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, itemId.toString(), "customer", customerId.toString()),
            method: "POST"
        })
    }

    removeForCustomer(itemId: number, customerId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, itemId.toString(), "customer", customerId.toString()),
            method: "DELETE"
        })
    }

    retrieveAllCustomersSuperFamiliesAndFamiliesReferenced(): Promise<CustomerSuperFamiliesAndFamilies[]> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, "customerFamilies")
        })
    }

    retrieveAllItemRefByCustomerId(filterValue: ItemFilter, customerId: number): Promise<Item[]> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, "customer", customerId.toString()),
            params: {
                ...filterValue,
                br: filterValue.brandIds,
                sf: filterValue.superFamilyIds,
                fa: filterValue.familyIds,
                go: filterValue.geographicalOriginIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }

    retrieveAllItemRefByCustomerSuperFamilyId(filterValue: ItemFilter, customerSuperFamilyId: number): Promise<Item[]> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, "customerSuperFamily", customerSuperFamilyId.toString()),
            params: {
                ...filterValue,
                br: filterValue.brandIds,
                sf: filterValue.superFamilyIds,
                fa: filterValue.familyIds,
                go: filterValue.geographicalOriginIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }

    retrieveAllItemRefByCustomerFamilyId(filterValue: ItemFilter, customerFamilyId: number): Promise<Item[]> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, "customerFamily", customerFamilyId.toString()),
            params: {
                ...filterValue,
                br: filterValue.brandIds,
                sf: filterValue.superFamilyIds,
                fa: filterValue.familyIds,
                go: filterValue.geographicalOriginIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }

    addForCustomerFamily(itemId: number, customerFamilyId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, itemId.toString(), "customerFamily", customerFamilyId.toString()),
            method: "POST"
        })
    }

    addForCustomerSuperFamily(itemId: number, customerSuperFamilyId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, itemId.toString(), "customerSuperFamily", customerSuperFamilyId.toString()),
            method: "POST"
        })
    }

    removeForCustomerFamily(itemId: number, customerFamilyId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, itemId.toString(), "customerFamily", customerFamilyId.toString()),
            method: "DELETE"
        })
    }

    removeForCustomerSuperFamily(itemId: number, customerSuperFamilyId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, itemId.toString(), "customerSuperFamily", customerSuperFamilyId.toString()),
            method: "DELETE"
        })
    }

    addAllForCustomerFromFilter(filterValue: ItemFilter, customerId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, "customer", customerId.toString()),
            method: "POST",
            params: {
                ...filterValue,
                br: filterValue.brandIds,
                sf: filterValue.superFamilyIds,
                fa: filterValue.familyIds,
                go: filterValue.geographicalOriginIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }

    removeAllForCustomerFromFilter(filterValue: ItemFilter, customerId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, "customer", customerId.toString()),
            method: "DELETE",
            params: {
                ...filterValue,
                br: filterValue.brandIds,
                sf: filterValue.superFamilyIds,
                fa: filterValue.familyIds,
                go: filterValue.geographicalOriginIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }

    addAllForCustomerFamilyFromFilter(filterValue: ItemFilter, customerFamilyId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, "customerFamily", customerFamilyId.toString()),
            method: "POST",
            params: {
                ...filterValue,
                br: filterValue.brandIds,
                sf: filterValue.superFamilyIds,
                fa: filterValue.familyIds,
                go: filterValue.geographicalOriginIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }

    removeAllForCustomerFamilyFromFilter(filterValue: ItemFilter, customerId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, "customerFamily", customerId.toString()),
            method: "DELETE",
            params: {
                ...filterValue,
                br: filterValue.brandIds,
                sf: filterValue.superFamilyIds,
                fa: filterValue.familyIds,
                go: filterValue.geographicalOriginIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }

    addAllForCustomerSuperFamilyFromFilter(filterValue: ItemFilter, customerSuperFamilyId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, "customerSuperFamily", customerSuperFamilyId.toString()),
            method: "POST",
            params: {
                ...filterValue,
                br: filterValue.brandIds,
                sf: filterValue.superFamilyIds,
                fa: filterValue.familyIds,
                go: filterValue.geographicalOriginIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }

    removeAllForCustomerSuperFamilyFromFilter(filterValue: ItemFilter, customerId: number): Promise<boolean> {
        return apiFetchApiResponse({
            url: buildUrl(API_PREFIX.customerItemReference, "customerSuperFamily", customerId.toString()),
            method: "DELETE",
            params: {
                ...filterValue,
                br: filterValue.brandIds,
                sf: filterValue.superFamilyIds,
                fa: filterValue.familyIds,
                go: filterValue.geographicalOriginIds
            },
            paramsSerializer: params => {
                return qs.stringify(params, {arrayFormat: 'comma'})
            }
        })
    }
}