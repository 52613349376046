import * as React from 'react';
import {useCallback, useEffect, useMemo, useState} from 'react';
import {DataGrid, GridActionsCellItem, GridRowParams} from '@mui/x-data-grid';
import {
    Alert,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Paper,
    Stack,
    Tooltip
} from "@mui/material";
import {InvoiceListPageHeader} from "./InvoiceListPageHeader";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useIntl} from "react-intl";
import 'react-toastify/dist/ReactToastify.css';
import InvoiceFilterAccordeon from "./InvoiceFilterAccordeon";
import {InvoiceFilter, InvoiceFilterImpl} from "../../../../../corelogic/models/invoice";
import {columnsInvoicesListPageListDescription} from "../../Descriptions/invoiceListDescription";
import {APP_ACTIONS_TYPES} from "../../../../../store/AppActionsTypes";
import {getMessageDescriptor} from "../../../../../i18n/messages";
import {retrieveAllInvoicesFiltered} from "../../../../../corelogic/usecase/invoices/invoicesActions";
import {getInvoicesSelector} from "../../../../../store/selectors/invoicesSelectors";
import Box from "@mui/material/Box";
import RefreshIcon from "@mui/icons-material/Refresh";
import ReorderIcon from "@mui/icons-material/Reorder";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import {API_URL} from "../../../../../corelogic/config";
import {axiosInstance} from "../../../../../corelogic/utils/api";

export const InvoiceListPage = () => {
    const dispatch = useDispatch()
    const {data, fetching, invoiceCount} = useSelector(getInvoicesSelector)
    const intl = useIntl()
    const navigation = useNavigate()
    const [toggleFilters, setToggleFilters] = useState<boolean>(false)
    const [invoiceFilter, setInvoiceFilter] = useState<InvoiceFilter>({
        searchInput: "",
        page: 1,
        size: 25
    })
    const [density, setDensity] = useState<boolean>(false)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    })
    const [filterCount, setFilterCount] = useState(0)
    const [dialogErrorOpen, setDialogErrorOpen] = useState(false)
    const [dialogErrorMessage, setDialogErrorMessage] = useState('')
    const [searchParams, setSearchParams] = useSearchParams()
    const handleDialogClose = () => {
        setDialogErrorOpen(false);
    }

    const handlePdfClick = async (id: number) => {
        try {
            const response = await axiosInstance.get(`invoice/documents/${id}`)
            if (response.status === 200) {
                window.open(`${API_URL}invoice/documents/${id}`, '_blank')
            } else {
                setDialogErrorMessage(`Failed to open PDF: ${response.status} ${response.statusText}`)
                setDialogErrorOpen(true);
            }
        } catch (error: any) {
            if (error.response?.status === 404) {
                setDialogErrorMessage("Pas de document disponible pour cette facture")
            } else {
                setDialogErrorMessage(`Error fetching PDF: ${error.message}`)
            }
            setDialogErrorOpen(true)
        }
    }

    const handlePaginationChange = (newPage: number, newPageSize: number) => {
        setPaginationModel(() => ({
            pageSize: newPageSize,
            page: newPage,
        }))
        let filters: InvoiceFilter = {
            ...invoiceFilter,
            size: newPageSize,
            page: newPage + 1
        }
        setInvoiceFilter({
            ...filters,
        })
        const filtersImpl = new InvoiceFilterImpl(filters);
        setSearchParams(filtersImpl.toSearchParam().toString())
    }

    const handleRefresh = useCallback(() => {
        dispatch(retrieveAllInvoicesFiltered(invoiceFilter, {
            page: paginationModel.page + 1,
            size: paginationModel.pageSize
        }))
    }, [dispatch, invoiceFilter, paginationModel.page, paginationModel.pageSize])

    const handleRowDoubleClick = useCallback((params: GridRowParams) => {
        navigation(`/invoices/${params.id}`);
    }, [navigation])

    const columns = useMemo<GridColDef[]>(
        () => [...columnsInvoicesListPageListDescription.map(cd => ({
            ...cd,
            headerName: intl.formatMessage(getMessageDescriptor(cd.headerName)),
            description: intl.formatMessage(getMessageDescriptor(cd.headerName)),
        })),
            {
                field: 'actions',
                type: 'actions',
                width: 140,
                sortable: false,
                editable: false,
                filterable: false,
                disableReorder: true,
                resizable: false,
                hideable: false,
                renderHeader: () => {
                    return (
                        <Box sx={{display: "flex", justifyContent: "space-between"}}>
                            <Tooltip title={intl.formatMessage(getMessageDescriptor("genericDataGridRefresh"))}>
                                <RefreshIcon onClick={handleRefresh}
                                             sx={{
                                                 fill: "rgba(33, 150, 243, 1)",
                                                 fontSize: "1.5rem",
                                                 cursor: "pointer"
                                             }}/>
                            </Tooltip>
                            <Divider variant="middle"/>
                            <Tooltip title={intl.formatMessage(getMessageDescriptor("genericDataGridChangeDensity"))}>
                                <ReorderIcon onClick={() => {
                                    handlePaginationChange(paginationModel.page, paginationModel.pageSize < 50 ? 50 : paginationModel.pageSize)
                                    setDensity(prevState => !prevState)
                                }}
                                             sx={{
                                                 fill: "rgba(33, 150, 243, 1)",
                                                 fontSize: "1.5rem",
                                                 cursor: "pointer"
                                             }}/>
                            </Tooltip>
                        </Box>
                    )
                },
                getActions: (params: GridRowParams) => [
                    <Tooltip title={intl.formatMessage(getMessageDescriptor("invoiceGridLineActionSeeInvoice"))}>
                        <GridActionsCellItem
                            icon={<RemoveRedEyeIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("invoiceGridLineActionSeeInvoice"))}
                            onClick={() => handleRowDoubleClick(params)}
                            showInMenu={false}/>
                    </Tooltip>,
                    <Tooltip title={intl.formatMessage(getMessageDescriptor("genericDataGridPrintPdf"))}>
                        <GridActionsCellItem
                            icon={<PictureAsPdfIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}
                            label={intl.formatMessage(getMessageDescriptor("genericDataGridPrintPdf"))}
                            onClick={isNaN(Number(params.id)) ? undefined : async () => handlePdfClick(Number(params.id))}
                            showInMenu={false}/>
                    </Tooltip>
                ]
            }]
        , [handleRefresh, handleRowDoubleClick, intl])

    useEffect(() => {
        if (searchParams.size === 0) {
            dispatch(retrieveAllInvoicesFiltered(invoiceFilter, {page: invoiceFilter.page, size: invoiceFilter.size}))
            return
        }
        let params = InvoiceFilterImpl.fromSearchParam(searchParams)
        setInvoiceFilter(params.getFilters())
        setPaginationModel(
            {
                pageSize: params.size ? params.size : 25,
                page: params.page ? params.page - 1 : 0
            }
        )
        setFilterCount(params.count())
        dispatch(retrieveAllInvoicesFiltered(params.getFilters(), {
            size: params.size ? params.size : 25,
            page: params.page ? params.page : 1
        }))
    }, [searchParams, dispatch])

    useEffect(() => {
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.invoices.INVOICE_RESET_DATA})
    }, [dispatch])

    const getInputValue = useCallback((inputValue: string) => {
        if (inputValue !== invoiceFilter.searchInput) {
            setInvoiceFilter(prev => ({...prev, searchInput: inputValue}))
            setSearchParams(new InvoiceFilterImpl({
                ...invoiceFilter,
                searchInput: inputValue
            }).toSearchParam().toString())
        }
    }, [invoiceFilter, setSearchParams, setInvoiceFilter])

    const handleToggleFilterClick = (state: boolean) => {
        setToggleFilters(state)
    }

    const handleApplyAllFilters = (filters: InvoiceFilter, filterCount: number) => {
        const updatedFilters = Object.fromEntries(
            Object.entries(filters).map(([key, value]) => [
                key,
                typeof value === 'string' && value.trim() === '' ? undefined : value
            ])
        )
        setInvoiceFilter({
            ...invoiceFilter,
            ...updatedFilters
        })
        const filtersImpl = new InvoiceFilterImpl(filters);
        setSearchParams(filtersImpl.toSearchParam().toString())
        setFilterCount(filterCount)
        setPaginationModel(prev => ({
            pageSize: prev.pageSize,
            page: 0,
        }))
    }

    const handleResetAllFilters = () => {
        setInvoiceFilter({
            ...Object.fromEntries(
                Object.keys(invoiceFilter).map(key => [key, key === 'searchInput' ? invoiceFilter[key] : undefined])
            ),
            settledOnly: undefined
        })
        const filtersImpl = new InvoiceFilterImpl({size: paginationModel.pageSize, page: 1});
        setSearchParams(filtersImpl.toSearchParam().toString())
        setFilterCount(0)
        setPaginationModel(prev => ({
            pageSize: prev.pageSize,
            page: 0,
        }))
    }

    return (
        <>
            <Paper elevation={3}
                   sx={{backgroundColor: "common.main", position: "sticky", top: "77px", zIndex: 1000}}>
                <InvoiceListPageHeader onChange={getInputValue} invoicesCount={invoiceCount}
                                       filterCount={filterCount}
                                       getToggleFiltersState={handleToggleFilterClick}/>
            </Paper>
            <Grid container height="84vh" p={1}>
                <Grid item>
                    <Collapse orientation="horizontal" in={toggleFilters} sx={{borderRadius: "1%"}}>
                        <InvoiceFilterAccordeon onClickApplyAllFilters={handleApplyAllFilters}
                                                onClickResetAllFilters={handleResetAllFilters}/>
                    </Collapse>
                </Grid>
                <DataGrid columns={columns}
                          columnVisibilityModel={{id: false, deliveryDate: false}}
                          rows={data}
                          rowCount={invoiceCount}
                          paginationMode="server"
                          paginationModel={paginationModel}
                          pageSizeOptions={density ? [50, 75, 100] : [25, 50, 75, 100]}
                          onPaginationModelChange={(newPaginationModel) => handlePaginationChange(newPaginationModel.page, newPaginationModel.pageSize)}
                          loading={fetching}
                          disableRowSelectionOnClick
                          density={density ? "compact" : "standard"}
                          onRowDoubleClick={handleRowDoubleClick}
                          slots={{
                              noRowsOverlay: () => (
                                  <Stack height="100%" alignItems="center" justifyContent="center">
                                      <Alert severity="info">
                                          {intl.formatMessage(getMessageDescriptor("orderListNoData"))}
                                      </Alert>
                                  </Stack>
                              )
                          }}
                          sx={{
                              "& .MuiDataGrid-cell:focus": {
                                  outline: "none"
                              },
                              "& .MuiDataGrid-columnHeader:focus": {
                                  outline: "none"
                              }
                          }}/>
            </Grid>
            {dialogErrorOpen && (
                <Dialog open={true} onClose={handleDialogClose}>
                    <DialogTitle>Erreur</DialogTitle>
                    <DialogContent>
                        {dialogErrorMessage}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Fermer
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}