import {ThunkResult} from "../../../store/appState";
import {Dispatch} from "react";
import {APP_ACTIONS_TYPES} from "../../../store/AppActionsTypes";
import {OrderGateway} from "../../gateways/OrderGateway";
import {OrderLineGateway} from "../../gateways/OrderLineGateway";
import {Order, OrderFilter, OrderInitialize, OrderLineFilter, OrderLinePricing} from "../../models/order";
import {PaginationFilter} from "../../models/item";
import {ItemGateway} from "../../gateways/ItemGateway";

export const retrieveAllOrders = ():
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDERS_RETRIEVING})
        try {
            const ordersData = await orderGateway.retrieveAll()
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDERS_RETRIEVED, payload: ordersData})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveAllOrdersFiltered = (filterValue: OrderFilter, paginationFilter: PaginationFilter):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDERS_RETRIEVING})
        try {
            const ordersList = await orderGateway.retrieveAllFiltered(filterValue, paginationFilter)
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDERS_RETRIEVED, payload: ordersList})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveSelectedOrder = (id: number):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_FETCHING})
        try {
            const order = await orderGateway.retrieveById(id)
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_SELECTING, payload: order})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const initNewOrder = (idCustomer: number, orderInit?: OrderInitialize):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_FETCHING})
        try {
            const newOrder = await orderGateway.initNewOrder(idCustomer, orderInit)
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_ADD, payload: newOrder})
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_SELECTING, payload: newOrder})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const saveData = (order: Order):
    ThunkResult<{ orderGateway: OrderGateway, orderLineGateway: OrderLineGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway, orderLineGateway}) => {
        try {
            const orderUpd = await orderGateway.saveData(order)
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_UPDATE, payload: orderUpd})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const validateOrder = (order: Order, submit?: boolean):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_FETCHING})
        try {
            const orderUpd = await orderGateway.validateOrder(order, submit)
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_UPDATE, payload: orderUpd})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const submitOrder = (orderId: number):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_FETCHING})
        try {
            const orderSubmit = await orderGateway.submitOrder(orderId)
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_UPDATE, payload: orderSubmit})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const duplicateOrder = (orderId: number):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_FETCHING})
        try {
            const orderSubmit = await orderGateway.duplicateOrder(orderId)
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_ADD, payload: orderSubmit})
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_SELECTING, payload: orderSubmit})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const deleteOrder = (idOrder: number):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_FETCHING})
        try {
            const orderDelete = await orderGateway.deleteOrder(idOrder)
            if (orderDelete)
                dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_UPDATE, payload: orderDelete})
            else {
                dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_DELETE, payload: idOrder})
            }
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateShippingCost = (idOrder: number, newShippingCost: number):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_FETCHING})
        try {
            const orderTotalsUpd = await orderGateway.updateShippingCost(idOrder, newShippingCost)
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_TOTAL_UPDATE, payload: orderTotalsUpd})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const recalculateShippingCost = (idOrder: number):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_FETCHING})
        try {
            const orderTotalsUpd = await orderGateway.recalculateShippingCost(idOrder)
            dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_TOTAL_UPDATE, payload: orderTotalsUpd})
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

/** ORDER LINES **/

export const retrieveOrderLinesByOrderId = (orderId: number):
    ThunkResult<{ orderLineGateway: OrderLineGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderLineGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_LINES_RETRIEVING})
        try {
            const orderLinesData = await orderLineGateway.retrieveOrderLinesByOrderId(orderId)
            dispatch({
                type: APP_ACTIONS_TYPES.orders.ORDER_LINES_RETRIEVED,
                payload: orderLinesData,
                target: orderId
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const retrieveFilteredOrderLinesByOrderId = (orderId: number, filterValue: OrderLineFilter):
    ThunkResult<{ orderLineGateway: OrderLineGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderLineGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_LINES_RETRIEVING})
        try {
            const orderLineWithOrderTotals = await orderLineGateway.retrieveAllLinesByOrderIdFiltered(orderId, filterValue)
            dispatch({
                type: APP_ACTIONS_TYPES.orders.ORDER_LINES_RETRIEVED,
                payload: orderLineWithOrderTotals,
                target: orderId
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const addOrderLine = (orderId: number, idItem: number, quantity: number, unit: string):
    ThunkResult<{ orderLineGateway: OrderLineGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderLineGateway}) => {
        try {
            const newOrderLineWithTotals = await orderLineGateway.addOrderLine(orderId, idItem, quantity, unit)
            dispatch({
                type: APP_ACTIONS_TYPES.orders.ORDER_LINES_ADD,
                payload: [newOrderLineWithTotals.orderLine, newOrderLineWithTotals.orderTotals],
                target: orderId
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateOrderLineQuantity = (orderLineId: number, itemId: number, newQuantity: number):
    ThunkResult<{ orderLineGateway: OrderLineGateway, itemGateway: ItemGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderLineGateway, itemGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_FETCHING, payload: true})
        try {
            const itemFound = await itemGateway.retrieveById(itemId)
            if (itemFound?.authorizationToSellByConsumerUnit !== true && itemFound?.authorizationToSellByPackage === true &&
                newQuantity % (itemFound?.byHowMuch ?? 1) !== 0) {
                dispatch({
                    type: APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_QTY_ERROR,
                    payload: {
                        idTxt: "order-line-grid-unpackaging-not-allowed",
                        values: {pcb:itemFound.byHowMuch}
                    },
                })
                dispatch({
                    type: APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_QTY_CANCEL,
                })
            } else {
                const newOrderLineWithTotals = await orderLineGateway.updateOrderLineQuantity(orderLineId, newQuantity)
                dispatch({
                    type: APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_QTY,
                    payload: [newOrderLineWithTotals.orderLine, newOrderLineWithTotals.orderTotals],
                })
                dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_FETCHING, payload: false})
            }
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateOrderLinePricing = (orderLineId: number, orderLinePricing: OrderLinePricing):
    ThunkResult<{ orderLineGateway: OrderLineGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderLineGateway}) => {
        try {
            const newOrderLineWithTotals = await orderLineGateway.updateOrderLinePricing(orderLineId, orderLinePricing)
            dispatch({
                type: APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_PRICING,
                payload: [newOrderLineWithTotals.orderLine, newOrderLineWithTotals.orderTotals],
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const updateOrderLineLabel = (orderLineId: number, label: string):
    ThunkResult<{ orderLineGateway: OrderLineGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderLineGateway}) => {
        try {
            const orderLineUpd = await orderLineGateway.updateOrderLineItemLabel(orderLineId, label)
            dispatch({
                type: APP_ACTIONS_TYPES.orders.ORDER_LINES_UPDATE_LABEL,
                payload: orderLineUpd,
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const removeOrderLine = (orderLineId: number):
    ThunkResult<{ orderLineGateway: OrderLineGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderLineGateway}) => {
        try {
            const newTotal = await orderLineGateway.removeOrderLine(orderLineId)
            dispatch({
                type: APP_ACTIONS_TYPES.orders.ORDER_LINES_DELETE,
                payload: orderLineId
            })
            dispatch({
                type: APP_ACTIONS_TYPES.orders.ORDER_TOTAL_UPDATE,
                payload: newTotal
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

/** ORDER COMMENTS **/

export const retrieveOrderCommentsByOrderId = (orderId: number):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        dispatch({type: APP_ACTIONS_TYPES.orders.ORDER_COMMENTS_RETRIEVING})
        try {
            const orderCommentsData = await orderGateway.retrieveOrderCommentsByOrderId(orderId)
            dispatch({
                type: APP_ACTIONS_TYPES.orders.ORDER_COMMENTS_RETRIEVED,
                payload: orderCommentsData
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}

export const addOrderComment = (orderId: number, comment: string):
    ThunkResult<{ orderGateway: OrderGateway }> => {
    return async (dispatch: Dispatch<any>, _getState, {orderGateway}) => {
        try {
            const newCommentAdded = await orderGateway.addOrderComment(orderId, comment)
            dispatch({
                type: APP_ACTIONS_TYPES.orders.ORDER_COMMENTS_ADD,
                payload: newCommentAdded
            })
        } catch (e) {
            dispatch({type: APP_ACTIONS_TYPES.generics.NETWORK_ERROR_CATCH, payload: e})
        }
    }
}