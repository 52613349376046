import {EnumData, Parameter, ParameterRateCode} from "./parameter";
import {Society} from "./society";
import {PaginationFilter} from "./item";

export interface CustomerAllFormData {
    countries: Parameter[]
    currencies: Parameter[]
    languages: Parameter[]
    businessForms: Parameter[]
    accountingCodes: Parameter[]
    customerCategories: Parameter[]
    superCustomerFamilies: Parameter[]
    customerFamilies: Parameter[]
    paymentChoices: Parameter[]
    promotionalRateCodes: Parameter[]
    vatManagements: EnumData[]
    representatives: Parameter[]
    rateCodes: Parameter[]
}

export interface CustomerWithCount {
    count: number
    customers: Customer[]
}

export interface Customer {
    id: number
    code: string
    society: Society
    businessForm?: Parameter
    businessName?: string
    language?: Parameter
    currency?: Parameter
    accountingCode?: Parameter
    siret?: string
    intraCommunityVAT?: string
    apeNaf?: string
    eanCode?: string
    stockCapital?: number
    rcs?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    country?: Parameter
    phone?: string
    phone2?: string
    fax?: string
    mail?: string
    website?: string
    iban?: string
    bic?: string
    blocked?: boolean
    rateCode?: ParameterRateCode
    status?: Parameter
    saleRepresentative1?: Parameter
    deliveryAddresses: Address[]
    vatManagement?: EnumData
    superFamily?: Parameter
    family?: Parameter
    category?: Parameter
    paymentChoice?: Parameter
    transitTime?: number
    acceptRemainder?: boolean
    saleRatePercentage?: number
    promotionalRateCode?: ParameterRateCode
    sendEmailAtOrderSubmission?: boolean
    customerOutstanding?: number
    amountDue?: number
    promotionalRatePercentage?: number
    observations?: string
    deliveryInformation?: string
}

export interface CustomerFilter extends PaginationFilter {
    search?: string
    si?: string
    ra?: number | string
    iv?: string
    rp?: number | string
    zc?: string
    block?: boolean
    ca?: string[]
    sf?: string[]
    fa?: string[]
    va?: string[]
    re?: string[]
}

export class CustomerFilterImpl implements CustomerFilter {
    search?: string;
    si?: string;
    ra?: number | string;
    iv?: string;
    rp?: number | string;
    zc?: string;
    block?: boolean;
    ca?: string[];
    sf?: string[];
    fa?: string[];
    va?: string[];
    re?: string[];
    size?: number;
    page?: number;

    constructor(filter: CustomerFilter) {
        this.search = filter.search;
        this.si = filter.si;
        this.ra = filter.ra;
        this.iv = filter.iv;
        this.rp = filter.rp;
        this.zc = filter.zc;
        this.block = filter.block;
        this.ca = filter.ca;
        this.sf = filter.sf;
        this.fa = filter.fa;
        this.va = filter.va;
        this.re = filter.re;
        this.size = filter.size;
        this.page = filter.page;
    }

    getFilters(): CustomerFilter {
        return {
            search: this.search,
            si: this.si,
            ra: this.ra,
            iv: this.iv,
            rp: this.rp,
            zc: this.zc,
            block: this.block,
            ca: this.ca,
            sf: this.sf,
            fa: this.fa,
            va: this.va,
            re: this.re,
            size: this.size,
            page: this.page
        };
    }

    toSearchParam(): URLSearchParams {
        const params = new URLSearchParams();
        const p: any = {};
        if (this.search) p.search = this.search;
        if (this.si) p.si = this.si;
        if (this.ra) p.ra = this.ra;
        if (this.iv) p.iv = this.iv;
        if (this.rp) p.rp = this.rp;
        if (this.zc) p.zc = this.zc;
        if (this.block !== undefined) p.block = String(this.block);
        if (this.ca) p.ca = this.ca.join(',');
        if (this.sf) p.sf = this.sf.join(',');
        if (this.fa) p.fa = this.fa.join(',');
        if (this.va) p.va = this.va.join(',');
        if (this.re) p.re = this.re.join(',');
        let json = JSON.stringify(p);
        if (json !== '{}') {
            const encodedP = btoa(json);
            params.append('pa', encodedP);
        }
        if (this.size !== undefined) params.append('size', String(this.size));
        if (this.page !== undefined) params.append('page', String(this.page));
        return params;
    }

    static fromSearchParam(params: URLSearchParams): CustomerFilterImpl {
        const encodedP = params.get('pa');
        let p: any = {};
        try {
            if (encodedP !== null)
                p = JSON.parse(atob(encodedP));
        } catch (e) {
            console.error('Failed to decode parameter:', e);
        }
        const filter: CustomerFilter = {
            search: p.search,
            si: p.si,
            ra: p.ra,
            iv: p.iv,
            rp: p.rp,
            zc: p.zc,
            block: p.block ? p.block === 'true' : undefined,
            ca: p.ca ? p.ca.split(',') : undefined,
            sf: p.sf ? p.sf.split(',') : undefined,
            fa: p.fa ? p.fa.split(',') : undefined,
            va: p.va ? p.va.split(',') : undefined,
            re: p.re ? p.re.split(',') : undefined,
            size: params.get('size') ? Number(params.get('size')) : undefined,
            page: params.get('page') ? Number(params.get('page')) : undefined
        };
        return new CustomerFilterImpl(filter);
    }

    count(): number {
        return Object.entries(this)
            .filter(([key, value]) =>
                value !== undefined &&
                key !== 'size' &&
                key !== 'page' &&
                key !== 'superFamilyCodes' &&
                key !== 'geographicalOriginCodes' &&
                key !== 'familyCodes' &&
                key !== 'brandCodes' &&
                key !== 'authorizationToSellOnly' &&
                key !== 'search'
            )
            .reduce((acc, [key, value]) => acc + (Array.isArray(value) ? value.length : 1), 0);    }
}

export interface CustomerForm {
    id?: number
    societyId?: number
    code?: string
    businessFormId?: number
    businessName?: string
    languageId?: number
    currencyId?: number
    accountingCodeId?: number
    siret?: string
    intraCommunityVAT?: string
    apeNaf?: string
    eanCode?: string
    stockCapital?: number
    rcs?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    countryId?: number
    phone?: string
    phone2?: string
    fax?: string
    mail?: string
    website?: string
    iban?: string
    bic?: string
    isBlocked?: boolean
    statusId?: number
    saleRepresentative1Id?: number
    vatManagement?: string
    superFamilyId?: number
    familyId?: number
    categoryId?: number
    paymentChoiceId?: number
    transitTime?: number
    acceptRemainder?: boolean
    saleRatePercentage?: number
    promotionalRateCodeId?: number
    rateCodeId?: number
    sendEmailAtOrderSubmission?: boolean
    customerOutstanding?: number
    amountDue?: number
    promotionalRatePercentage?: number
}

export interface Address {
    id?: number
    title: string
    businessName?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    country?: Parameter | null
}

export interface CustomerSuperFamiliesAndFamilies {
    idSF: number,
    codeSF: string,
    labelSF: string,
    idF?: number,
    codeF?: string,
    labelF?: string
}

export const customerToForm = (customer: Customer): CustomerForm => {
    return {
        id: customer.id,
        societyId: customer.society.id,
        code: customer.code,
        businessFormId: customer.businessForm?.id,
        businessName: customer.businessName,
        languageId: customer.language?.id,
        currencyId: customer.currency?.id,
        accountingCodeId: customer.accountingCode?.id,
        siret: customer.siret,
        intraCommunityVAT: customer.intraCommunityVAT,
        apeNaf: customer.apeNaf,
        eanCode: customer.eanCode,
        stockCapital: customer.stockCapital,
        rcs: customer.rcs,
        address1: customer.address1,
        address2: customer.address2,
        address3: customer.address3,
        zipCode1: customer.zipCode1,
        zipCode2: customer.zipCode2,
        city: customer.city,
        countryId: customer.country?.id,
        phone: customer.phone,
        phone2: customer.phone2,
        fax: customer.fax,
        mail: customer.mail,
        website: customer.website,
        iban: customer.iban,
        bic: customer.bic,
        isBlocked: customer.blocked,
        rateCodeId: customer.rateCode?.id,
        statusId: customer.status?.id,
        saleRepresentative1Id: customer.saleRepresentative1?.id,
        vatManagement: customer.vatManagement?.value,
        superFamilyId: customer.superFamily?.id,
        familyId: customer.family?.id,
        categoryId: customer.category?.id,
        paymentChoiceId: customer.paymentChoice?.id,
        transitTime: customer.transitTime,
        acceptRemainder: customer.acceptRemainder,
        saleRatePercentage: customer.saleRatePercentage,
        promotionalRateCodeId: customer.promotionalRateCode?.id,
        sendEmailAtOrderSubmission: customer.sendEmailAtOrderSubmission,
        customerOutstanding: customer.customerOutstanding,
        amountDue: customer.amountDue,
        promotionalRatePercentage: customer.promotionalRatePercentage,
    }
}