import {OrderStatus} from "../../adapters/secondary/InMemory/Data/enums";
import {EnumData, Parameter, VatType} from "./parameter";
import {Item, PaginationFilter} from "./item";
import {Moment} from "moment";
import {Address} from "./customer";

export interface DataGraphOrdersCountInterface {
    dateOrder: string | Moment,
    ordersCount: number,
}

export interface DataGraphOrdersSalesInterface {
    dateOrder: string | Moment,
    ordersSales: number,
}

export interface OrderWithCount {
    count: number
    orders: Order[]
}

export interface Order {
    id: number
    numOrder?: string
    sodiwinNumOrder?: string
    reference?: string
    status?: OrderStatus
    orderDate?: Date
    deliveryDate?: Date
    comment?: string
    hasComments?: boolean
    totals?: OrderTotals
    delayRequested?: Date
    delayConfirmed?: Date
    orderedCustomer?: OrderedCustomer
    deliveryAddressBusinessName?: string
    deliveryAddress1?: string
    deliveryAddress2?: string
    deliveryAddress3?: string
    deliveryAddressZipCode1?: string
    deliveryAddressZipCode2?: string
    deliveryAddressCity?: string
    deliveryAddressCountry?: Parameter | null
    vatManagement?: EnumData
}

export interface OrderInitialize {
    delayRequested?: Date | Moment
    reference?: string
    address?: Address
}

export interface OrderFilter extends PaginationFilter {
    search: string,
    st?: string[],
    cd?: Date | Moment
    dr?: Date | Moment
    cc?: string
    cbn?: string
    tgte?: number | string
    ic?: string
}


export class OrderFilterImpl implements OrderFilter {
    search: string
    st?: string[]
    cd?: Date | Moment
    dr?: Date | Moment
    cc?: string
    cbn?: string
    tgte?: number | string
    ic?: string
    size?: number
    page?: number

    constructor(filter: OrderFilter) {
        this.search = filter.search;
        this.st = filter.st;
        this.cd = filter.cd;
        this.dr = filter.dr;
        this.cc = filter.cc;
        this.cbn = filter.cbn;
        this.tgte = filter.tgte;
        this.ic = filter.ic;
        this.size = filter.size;
        this.page = filter.page;
    }

    getFilters(): OrderFilter {
        return {
            search: this.search,
            st: this.st,
            cd: this.cd,
            dr: this.dr,
            cc: this.cc,
            cbn: this.cbn,
            tgte: this.tgte,
            ic: this.ic,
            size: this.size,
            page: this.page
        };
    }

    toSearchParam(): URLSearchParams {
        const params = new URLSearchParams();
        const p: any = {};
        if (this.search) p.search = this.search;
        if (this.st) p.st = this.st.join(',');
        if (this.cd) p.cd = this.cd.toString();
        if (this.dr) p.dr = this.dr.toString();
        if (this.cc) p.cc = this.cc;
        if (this.cbn) p.cbn = this.cbn;
        if (this.tgte) p.tgte = this.tgte.toString();
        if (this.ic) p.ic = this.ic;
        let json = JSON.stringify(p);
        if (json !== '{}') {
            const encodedP = btoa(encodeURIComponent(json));
            params.append('pa', encodedP);
        }
        if (this.size !== undefined) params.append('size', String(this.size));
        if (this.page !== undefined) params.append('page', String(this.page));
        return params;
    }

    static fromSearchParam(params: URLSearchParams): OrderFilterImpl {
        const encodedP = params.get('pa');
        let p: any = {};
        try {
            if (encodedP !== null)
                p = JSON.parse(decodeURIComponent(atob(encodedP)));
        } catch (e) {
            console.error('Failed to decode parameter:', e);
        }
        const filter: OrderFilter = {
            search: p.search,
            st: p.st ? p.st.split(',') : undefined,
            cd: p.cd,
            dr: p.dr,
            cc: p.cc,
            cbn: p.cbn,
            tgte: p.tgte,
            ic: p.ic,
            size: params.get('size') ? Number(params.get('size')) : undefined,
            page: params.get('page') ? Number(params.get('page')) : undefined
        };
        return new OrderFilterImpl(filter);
    }

    count(): number {
        return Object.entries(this)
            .filter(([key, value]) => value !== undefined && key !== 'size' && key !== 'page' && key !== 'search')
            .reduce((acc, [key, value]) => acc + (Array.isArray(value) ? value.length : 1), 0);
    }
}

export interface OrderLineWithOrderTotals {
    orderLine: OrderLine
    orderTotals: OrderTotals
}

export interface OrderLineUpdateError {
    idTxt: string,
    values: any
}

export interface OrderLine {
    id: number
    item: Item
    label?: string
    itemCode?: string
    itemLabel?: string
    quantity?: number
    grossPrice?: number
    netPrice?: number
    discount1?: number
    discount2?: number
    vat?: VatType
    itemTotalExTax?: number
    free?: boolean
}

export interface OrderLinePricing {
    grossPrice?: number
    customerRateR1?: number
    customerPromotionalRateR2?: number
    customerLineDiscountR3?: number
    free?: boolean
}

export interface TemporaryOrderLine {
    id: number
    quantity: number
    unit: string
}

export interface OrderComment {
    id: number
    profile: string
    createdAt: Date
    message: string
}

export interface OrderTotals {
    totalProductExcludingTax?: number
    shippingCostsExcludingTax?: number
    totalGrossTaxExcluded?: number
    totalVat?: number
    totalOrderIncludingTax?: number
    discount?: number
    totalShippingCost?: number
    shippingCostVat?: number
    totalTaxExcluded?: number
    totalWithTax?: number
    shippingCostForced: boolean
}

export interface OrderedCustomer {
    id?: number
    code?: number
    businessName?: number
}

export interface OrderLineFilter {
    search?: string
}

export interface OrderInfoDialog {
    reference: string,
    delayRequested: Date | Moment | null,
    deliveryAddress: Address,
    comment: string
}