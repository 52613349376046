import {AnyAction, combineReducers} from "redux";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {Customer, CustomerAllFormData} from "../../corelogic/models/customer";
import {CustomerFamily} from "../../corelogic/models/parameter";

const emptyFormData = () => ({
    countries: [],
    currencies: [],
    languages: [],
    businessForms: [],
    accountingCodes: [],
    customerCategories: [],
    superCustomerFamilies: [],
    customerFamilies: [],
    paymentChoices: [],
    promotionalRateCodes: [],
    vatManagements: [],
    representatives: [],
    rateCodes: []
} as CustomerAllFormData)

const emptyFormDataParameterFamily = () => ({} as CustomerFamily)

const dataFormFields = (state: CustomerAllFormData = emptyFormData(), action: AnyAction): CustomerAllFormData | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.customers.CUSTOMER_FORM_DATA_FIELDS:
            return action.payload
        default:
            return state;
    }
}

const dataFamilies = (state: CustomerFamily = emptyFormDataParameterFamily(), action: AnyAction): CustomerFamily | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.customers.CUSTOMERS_F_RETRIEVED:
            return action.payload
        default:
            return state;
    }
}

const data = (state: Customer[] = [], action: AnyAction): Customer[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.customers.CUSTOMERS_RETRIEVED:
            return action.payload.customers
        case APP_ACTIONS_TYPES.customers.CUSTOMER_RESET_DATA:
            return []
        default:
            return state;
    }
}

const selected = (state: Customer | null = null, action: AnyAction): Customer | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.customers.CUSTOMER_SELECTING:
            return action.payload === undefined ? null : action.payload
        case APP_ACTIONS_TYPES.customers.CUSTOMER_RESET_SELECTION:
            return null
        case APP_ACTIONS_TYPES.customers.CUSTOMER_UPDATE:
            return action.payload === undefined ? null : action.payload
        case APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_RETRIEVED:
            if (action.target === state?.id)
                return {...state, deliveryAddresses: action.payload} as Customer
            return state
        case APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_ADD:
            if (state && action.target === state?.id)
                return {
                    ...state,
                    deliveryAddresses: state.deliveryAddresses ? [...state.deliveryAddresses, {...action.payload}] : [{...action.payload}]
                }
            return state
        case APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_UPDATE:
            if (state && state.deliveryAddresses.find((da) => da.id === action.payload.id))
                return {
                    ...state,
                    deliveryAddresses: state.deliveryAddresses.map(da => da.id === action.payload.id ? {...action.payload} : da)
                }
            return state
        case APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_DELETE:
            if (state && state.deliveryAddresses.find((da) => da.id === action.payload))
                return {
                    ...state,
                    deliveryAddresses: state.deliveryAddresses.filter(ol => ol.id !== action.payload)
                }
            return state
        default:
            return state
    }

}

const fetching = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("CUSTOMERS")) {
        return action.type === APP_ACTIONS_TYPES.customers.CUSTOMERS_RETRIEVING
    }
    return state
}

const fetchingOnAction = (state: boolean = false, action: AnyAction) => {
    return action.type === APP_ACTIONS_TYPES.customers.CUSTOMER_FETCHING
}

const addressesFetching = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("CUSTOMER_ADDRESSES")) {
        return action.type === APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_FETCHING
    }
    return state
}
const count = (state: number = 0, action: AnyAction) => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.customers.CUSTOMERS_RETRIEVED:
            return action.payload.count
        case APP_ACTIONS_TYPES.customers.CUSTOMER_RESET_DATA:
            return 0
        default:
            return state
    }
}
export default combineReducers({
    data,
    dataFamilies,
    selected,
    fetching,
    fetchingOnAction,
    addressesFetching,
    dataFormFields,
    count
})