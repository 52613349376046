import * as React from "react";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import {Tooltip} from "@mui/material";
import moment from "moment";
import {FormattedNumber} from "react-intl";

export const columnsInvoicesListPageListDescription: GridColDef[] = [
    {
        field: 'id',
        headerName: "invoiceGridColId",
        headerAlign: "center",
        type: 'number',
        flex: 1,
        renderCell: (cellValues) => {
            return (
                <div style={{
                    color: "rgba(255, 82, 82, 1)",
                    fontSize: "1rem",
                    width: "100%",
                    textAlign: "center"
                }}>
                    {cellValues.value}
                </div>
            )
        }
    },
    {
        field: 'sodiwinNumInvoice',
        headerName: "invoiceGridColNum",
        headerAlign: "left",
        type: 'string',
        align: 'left',
        flex: 0.5,
        renderCell: (cellValues) => {
            return (
                <span style={{color: "rgba(255, 82, 82, 1)", fontWeight: "600"}}>
                    {cellValues.row.sodiwinNumInvoice || ""}
                </span>
            )
        },
        valueGetter: (cellValues) => cellValues.row.sodiwinNumInvoice
    },
    {
        field: 'reference',
        type: 'string',
        headerName: "invoiceGridColRef",
        flex: 1,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.reference || ""}>
                    <span>{cellValues.row.reference || ""}</span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.reference
    },
    {
        field: 'billedCustomerCode',
        headerName: "invoiceGridColCodeClient",
        type: 'string',
        align: 'left',
        flex: 0.5,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.billedCustomer?.code}>
                    <span style={{fontWeight: "600"}}>
                        {cellValues.row.billedCustomer?.code || ""}
                    </span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.billedCustomer?.code
    },
    {
        field: 'billedCustomerLabel',
        headerName: "invoiceGridColLabelClient",
        type: 'string',
        flex: 1,
        renderCell: (cellValues) => {
            return (
                <Tooltip title={cellValues.row.billedCustomer?.businessName || ""}>
                    <span>{cellValues.row.billedCustomer?.businessName || ""}</span>
                </Tooltip>
            )
        },
        valueGetter: (cellValues) => cellValues.row.billedCustomer?.businessName
    },
    {
        field: 'creationDate',
        headerName: "invoiceGridColCrtDate",
        type: 'date',
        flex: 0.6,
        renderCell: (cellValues) => {
            moment.locale("fr")
            return (
                <span>
                    {cellValues.row.creationDate ? moment(cellValues.row.creationDate).format("DD/MM/YYYY") : ""}
                </span>
            )
        },
        valueGetter: (cellValues) => moment(cellValues.row.creationDate).toDate()
    },
    {
        field: 'billingDate',
        headerName: "invoiceGridColBillingDate",
        type: 'date',
        flex: 0.6,
        renderCell: (cellValues) => {
            return (
                cellValues.row.billingDate ? moment(cellValues.row.billingDate).format("DD/MM/YYYY") : ""
            )
        },
        valueGetter: (cellValues) => moment(cellValues.row.billingDate).toDate()
    },
    {
        field: 'totalGrossTaxExcluded',
        headerName: "invoiceGridColTotalGrossExclTax",
        headerAlign: "left",
        type: 'number',
        align: 'left',
        flex: 0.8,
        renderCell: (cellValues) => {
            return (
                <FormattedNumber
                    value={cellValues.row?.totals?.totalGrossTaxExcluded || 0}
                    maximumFractionDigits={2}
                    style="currency" currency="EUR"/>
            )
        },
        valueGetter: (cellValues) => cellValues.row?.totals?.totalGrossTaxExcluded
    },
    {
        field: 'totalShippingCosts',
        headerName: "invoiceGridColTotalShippingCost",
        headerAlign: "left",
        type: 'number',
        align: 'left',
        flex: 0.8,
        renderCell: (cellValues) => {
            return (
                <FormattedNumber
                    value={cellValues.row?.totals?.totalShippingCosts || 0}
                    maximumFractionDigits={2}
                    style="currency" currency="EUR"/>
            )
        },
        valueGetter: (cellValues) => cellValues.row?.totals?.totalShippingCosts
    },
    {
        field: 'totalTaxExcluded',
        headerName: "invoiceGridColTotalExclTax",
        headerAlign: "left",
        type: 'number',
        align: 'left',
        flex: 0.8,
        renderCell: (cellValues) => {
            return (
                <FormattedNumber
                    value={cellValues.row?.totals?.totalTaxExcluded || 0}
                    maximumFractionDigits={2}
                    style="currency" currency="EUR"/>
            )
        },
        valueGetter: (cellValues) => cellValues.row?.totals?.totalTaxExcluded
    },
    {
        field: 'totalVat',
        headerName: "invoiceGridColTotalTaxes",
        headerAlign: "left",
        type: 'number',
        align: 'left',
        flex: 0.8,
        renderCell: (cellValues) => {
            return (
                <FormattedNumber
                    value={cellValues.row?.totals?.totalVat || 0}
                    maximumFractionDigits={2}
                    style="currency" currency="EUR"/>
            )
        },
        valueGetter: (cellValues) => cellValues.row?.totals?.totalVat
    },
    {
        field: 'totalWithTax',
        headerName: "invoiceLineGridColTotalInclTax",
        headerAlign: "left",
        type: 'number',
        align: 'left',
        flex: 0.8,
        renderCell: (cellValues) => {
            return (
                <FormattedNumber
                    value={cellValues.row?.totals?.totalWithTax || 0}
                    maximumFractionDigits={2}
                    style="currency" currency="EUR"/>
            )
        },
    },
    {
        field: 'dueDate',
        headerName: "invoiceGridColDueDate",
        headerAlign: "left",
        type: 'date',
        align: 'left',
        flex: 0.6,
        renderCell: (cellValues) => {
            return (
                cellValues.row.dueDate ? moment(cellValues.row.dueDate).format("DD/MM/YYYY") : ""
            )
        },
        valueGetter: (cellValues) => moment(cellValues.row.dueDate).toDate()
    },
    {
        field: 'totalPaid',
        headerName: "invoiceGridColTotalPaid",
        headerAlign: "left",
        type: 'number',
        align: 'left',
        flex: 0.8,
        renderCell: (cellValues) => {
            return (
                <FormattedNumber
                    value={cellValues.row?.totals?.totalPaid || 0}
                    maximumFractionDigits={2}
                    style="currency" currency="EUR"/>
            )
        },
        valueGetter: (cellValues) => cellValues.row?.totalPaid
    }
]