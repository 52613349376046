import React from 'react';
import {Button, Grid} from "@mui/material";
import {UserRoleCode} from "../../../../corelogic/models/user";
import PersonIcon from "@mui/icons-material/Person";
import BusinessCenterIcon from "@mui/icons-material/BusinessCenter";
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {useIntl} from "react-intl";
import {getMessageDescriptor} from "../../../../i18n/messages";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

interface GetUserRoleButtonInterface {
    userRoleCode?: string
    dataGridMode?: boolean
}

const GetUserRoleButton = (props: GetUserRoleButtonInterface) => {
    const {userRoleCode, dataGridMode} = props
    const intl = useIntl()

    return (
        userRoleCode === UserRoleCode.COMMERCIAL
            ?
            <Button variant="outlined" disabled sx={{
                color: "white !important",
                backgroundColor: "rgba(33, 150, 243, 1)",
                textTransform: "none",
                width: "100%",
            }}>
                {dataGridMode ?
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <Grid container alignItems="center">
                                <BusinessCenterIcon/>
                            </Grid>
                        </Grid>
                        <Grid item xs={10}>
                            {intl.formatMessage(getMessageDescriptor("userRoleButtonCommercial"))}
                        </Grid>
                    </Grid>
                    :
                    <>
                        <BusinessCenterIcon sx={{marginRight: "10px"}}/>
                        {intl.formatMessage(getMessageDescriptor("userRoleButtonCommercial"))}
                    </>
                }
            </Button>
            : userRoleCode === UserRoleCode.CUSTOMER ?
                <Button variant="outlined" disabled sx={{
                    color: "white !important",
                    backgroundColor: "rgba(33, 150, 243, 1)",
                    textTransform: "none",
                    width: "100%",
                }}>
                    {dataGridMode ?
                        <Grid container alignItems="center">
                            <Grid item xs={2}>
                                <Grid container alignItems="center">
                                    <PersonIcon/>
                                </Grid>
                            </Grid>
                            <Grid item xs={10}>
                                {intl.formatMessage(getMessageDescriptor("userRoleButtonCustomer"))}
                            </Grid>
                        </Grid>
                        :
                        <>
                            <PersonIcon sx={{marginRight: "10px"}}/>
                            {intl.formatMessage(getMessageDescriptor("userRoleButtonCustomer"))}
                        </>
                    }
                </Button>
                : userRoleCode === UserRoleCode.ADMIN ?
                    <Button variant="outlined" disabled sx={{
                        color: "white !important",
                        backgroundColor: "rgba(33, 150, 243, 1)",
                        textTransform: "none",
                        width: "100%",
                    }}>
                        {dataGridMode ?
                            <Grid container alignItems="center">
                                <Grid item xs={2}>
                                    <Grid container alignItems="center">
                                        <AdminPanelSettingsIcon/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={10}>
                                    {intl.formatMessage(getMessageDescriptor("userRoleButtonAdmin"))}
                                </Grid>
                            </Grid>
                            :
                            <>
                                <AdminPanelSettingsIcon sx={{marginRight: "10px"}}/>
                                {intl.formatMessage(getMessageDescriptor("userRoleButtonAdmin"))}
                            </>
                        }
                    </Button>

                : userRoleCode === UserRoleCode.SODIWIN ?
                    <Button variant="outlined" disabled sx={{
                        color: "white !important",
                        backgroundColor: "rgba(33, 150, 243, 1)",
                        textTransform: "none",
                        width: "100%",
                    }}>
                        {dataGridMode ?
                            <Grid container alignItems="center">
                                <Grid item xs={2}>
                                    <Grid container alignItems="center">
                                        <AdminPanelSettingsIcon/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={10}>
                                    {"SODIWIN"}
                                </Grid>
                            </Grid>
                            :
                            <>
                                <AdminPanelSettingsIcon sx={{marginRight: "10px"}}/>
                                {"SODIWIN"}
                            </>
                        }
                    </Button>
                    :
                    <Button variant="outlined" disabled sx={{
                        color: "white !important",
                        backgroundColor: "grey",
                        textTransform: "none",
                        width: "100%",
                    }}>
                        {dataGridMode ?
                            <Grid container alignItems="center">
                                <Grid item xs={2}>
                                    <Grid container alignItems="center">
                                        <HelpOutlineIcon/>
                                    </Grid>
                                </Grid>
                                <Grid item xs={10}>
                                    {intl.formatMessage(getMessageDescriptor("userRoleButtonUnknown"))}
                                </Grid>
                            </Grid>
                            :
                            <>
                                <HelpOutlineIcon sx={{marginRight: "10px"}}/>
                                {intl.formatMessage(getMessageDescriptor("userRoleButtonUnknown"))}
                            </>
                        }
                    </Button>
    )
}

export default GetUserRoleButton;