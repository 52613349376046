import {FormattedMessage, useIntl} from "react-intl";
import React, {useState} from "react";
import {User, UserPasswordForm, UserRoleUtils} from "../../../../../../corelogic/models/user";
import * as yup from "yup";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {Button, Grid, IconButton, InputAdornment, TextField} from "@mui/material";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {useSelector} from "react-redux";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";

type InputsPasswordForm = {
    password: string
    newPassword: string
    confirmNewPassword: string
}

type InputsPasswordFormAdmin = {
    passwordAdmin: string
}

interface UserPasswordFormInterface {
    data: User
    onSubmit: (passwordForm: UserPasswordForm) => void
    onSubmitAdmin: (password: string) => void
    onReset: () => void
}

export const UserPasswordFormComponent = (props: UserPasswordFormInterface) => {
    const {data, onSubmit, onReset, onSubmitAdmin} = props
    const {userLogged} = useSelector(getUserLoggedSelector)
    const intl = useIntl()
    const [showPassword, setShowPassword] = useState({
        actualPassword: false,
        newPassword: false,
        confirmNewPassword: false,
        newPassWordAdmin: false,
    })
    const [passwordForm, setSPasswordForm] = useState<UserPasswordForm>({
        login: data.login,
        oldPassword: "",
        newPassword: ""
    })
    const [passwordAdmin, setPasswordAdmin] = useState("")

    const schemaValidationPasswordForm = yup.object({
        newPassword: yup.string().required("Please enter your new password").matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase, one number and one special case character"
        ).notOneOf([yup.ref('password'), null], "Your new password should be different from the previous one."),
        confirmNewPassword: yup.string().required("Please confirm your new password").oneOf([yup.ref('newPassword'), null], "Passwords don't match.")
    }).required();

    const schemaValidationPasswordFormAdmin = yup.object({
        passwordAdmin: yup.string().required("Please enter your new password").matches(
            /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
            "Password must contain at least 8 characters, one uppercase, one number and one special case character")
    }).required();

    const {handleSubmit, reset, formState: {errors}, control} = useForm<InputsPasswordForm>(
        {
            resolver: yupResolver(schemaValidationPasswordForm)
        }
    )

    const {
        handleSubmit: handleSubmit2,
        formState: {errors: errors2},
        control: control2
    } = useForm<InputsPasswordFormAdmin>(
        {
            resolver: yupResolver(schemaValidationPasswordFormAdmin)
        }
    )

    //Fonction submit click
    const onSubmitPasswordForm: SubmitHandler<InputsPasswordForm> = (data) => {
        onSubmit({...passwordForm, oldPassword: data.password, newPassword: data.newPassword})
    }

    const onSubmitPasswordFormAdmin: SubmitHandler<InputsPasswordFormAdmin> = (data) => {
        onSubmitAdmin((data.passwordAdmin))
    }

    //Fonction reset click
    const handleReset = () => {
        onReset()
        reset({password: "", newPassword: "", confirmNewPassword: ""})
    }

    const handleSubmitPasswordFormAdmin = () => {
        onSubmitAdmin(passwordAdmin)
    }

    return (
        <>
            {UserRoleUtils.isAdmin(userLogged) ?
                <form onSubmit={handleSubmit2(onSubmitPasswordFormAdmin)}>
                    <Grid container direction="column" alignItems="center" spacing={3} p={3}>
                        <Grid item container>
                            <Controller
                                name="passwordAdmin"
                                control={control2}
                                render={({field}) =>
                                    <TextField
                                        {...field}
                                        label={intl.formatMessage((getMessageDescriptor("userPageFormPasswordNew")))}
                                        variant="standard"
                                        placeholder="Your new password..."
                                        type={showPassword.actualPassword ? 'text' : 'password'}
                                        error={!!errors2.passwordAdmin}
                                        helperText={errors2.passwordAdmin?.message}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword({
                                                            ...showPassword,
                                                            actualPassword: !showPassword.actualPassword
                                                        })}
                                                        edge="end"
                                                    >
                                                        {showPassword.actualPassword ? <VisibilityOff/> :
                                                            <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                            />
                        </Grid>
                        <Grid item container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <Button onClick={handleReset} variant="contained" color="error" type="reset">
                                    <FormattedMessage id={messages.genericCancel.id}/>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" type="submit">
                                    <FormattedMessage id={messages.genericConfirm.id}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
                :
                <form onSubmit={handleSubmit(onSubmitPasswordForm)}>
                    <Grid container direction="column" alignItems="center" spacing={3} p={3}>
                        <Grid item width="100%">
                            <Controller
                                name="password"
                                control={control}
                                render={({field}) =>
                                    <TextField
                                        {...field}
                                        label={intl.formatMessage((getMessageDescriptor("userPageFormPasswordActual")))}
                                        variant="standard"
                                        placeholder="Your actual password..."
                                        type={showPassword.actualPassword ? 'text' : 'password'}
                                        error={!!errors.password}
                                        helperText={errors.password?.message}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword({
                                                            ...showPassword,
                                                            actualPassword: !showPassword.actualPassword
                                                        })}
                                                        edge="end"
                                                    >
                                                        {showPassword.actualPassword ? <VisibilityOff/> :
                                                            <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                            />
                        </Grid>
                        <Grid item width="100%">
                            <Controller
                                name="newPassword"
                                control={control}
                                render={({field}) =>
                                    <TextField
                                        {...field}
                                        label={intl.formatMessage((getMessageDescriptor("userPageFormPasswordNew")))}
                                        variant="standard"
                                        placeholder="Your new password..."
                                        type={showPassword.newPassword ? 'text' : 'password'}
                                        error={!!errors.newPassword}
                                        helperText={errors.newPassword?.message}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword({
                                                            ...showPassword,
                                                            newPassword: !showPassword.newPassword
                                                        })}
                                                        edge="end"
                                                    >
                                                        {showPassword.newPassword ? <VisibilityOff/> :
                                                            <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                            />
                        </Grid>
                        <Grid item width="100%">
                            <Controller
                                name="confirmNewPassword"
                                control={control}
                                render={({field}) =>
                                    <TextField
                                        {...field}
                                        label={intl.formatMessage((getMessageDescriptor("userPageFormPasswordNewConfirmation")))}
                                        variant="standard"
                                        placeholder="Confirm your new password..."
                                        type={showPassword.confirmNewPassword ? 'text' : 'password'}
                                        error={!!errors.confirmNewPassword}
                                        helperText={errors.confirmNewPassword?.message}
                                        fullWidth
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => setShowPassword({
                                                            ...showPassword,
                                                            confirmNewPassword: !showPassword.confirmNewPassword
                                                        })}
                                                        edge="end"
                                                    >
                                                        {showPassword.confirmNewPassword ? <VisibilityOff/> :
                                                            <Visibility/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />}
                            />
                        </Grid>
                        <Grid item container spacing={2} justifyContent="flex-end">
                            <Grid item>
                                <Button onClick={handleReset} variant="contained" color="error" type="reset">
                                    <FormattedMessage id={messages.genericCancel.id}/>
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button variant="contained" type="submit">
                                    <FormattedMessage id={messages.genericConfirm.id}/>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>}
        </>
    )
}
