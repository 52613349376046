import {AnyAction, combineReducers} from "redux";
import {APP_ACTIONS_TYPES} from "store/AppActionsTypes";
import {Item, ItemAllFormData} from "../../corelogic/models/item";

const data = (state: Item[] = [], action: AnyAction): Item[] => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.items.ITEMS_RETRIEVED:
            return action.payload.items
        case APP_ACTIONS_TYPES.items.ITEM_RESET_DATA:
            return []
        case APP_ACTIONS_TYPES.items.ITEM_ADD_FAVORITE:
            return state.map(i => i.id === action.payload ? {...i, favorite: true} : i)
        case APP_ACTIONS_TYPES.items.ITEM_REMOVE_FAVORITE:
            return state.map(i => i.id === action.payload ? {...i, favorite: false} : i)
        default:
            return state
    }
}

function emptyFormData(): ItemAllFormData {
    return {
        superFamilies: [],
        geographicalOrigins: [],
        families: [],
        brands: [],
        items: [],
        accountingAnalyticalCodes: [],
        accountingCodes: [],
        barcodeTypes: [],
        batchCodificationTypes: [],
        calculationMethods: [],
        categories: [],
        dayTypes: [],
        formats: [],
        dimensionUnits: [],
        inventoryTurnoverClasses: [],
        managers: [],
        originArea: [],
        packagingTypes: [],
        rates: [],
        suppliers: [],
        subCategories: [],
        subFamilies: [],
        subFormats: [],
        supplyTypes: [],
        types: [],
        units: [],
        valuationTypes: [],
        vatTypes: [],
        volumeUnits: [],
        weightUnits: []
    }
}

const dataFormFields = (state: ItemAllFormData = emptyFormData(), action: AnyAction): ItemAllFormData => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.items.ITEM_FORM_DATA_FIELDS:
            return action.payload
       /* case APP_ACTIONS_TYPES.items.ITEM_RESET_SELECTION:
            return emptyFormData()*/
        default:
            return state;
    }
}
const selected = (state: Item | null = null, action: AnyAction): Item | null => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.items.ITEM_SELECTING:
            return action.payload === undefined ? null : action.payload
        case APP_ACTIONS_TYPES.items.ITEM_RESET_SELECTION:
            return null
        case APP_ACTIONS_TYPES.items.ITEM_PACKAGING_TYPES_RETRIEVED:
            if (action.target === state?.id)
                return {...state, packagings: action.payload} as Item
            return state
        case APP_ACTIONS_TYPES.items.ITEM_PACKAGING_TYPES_ADD:
            if (state && action.target === state?.id)
                return {
                    ...state,
                    packagings: state.packagings ? [...state.packagings, {...action.payload}] : [{...action.payload}]
                }
            return state
        case APP_ACTIONS_TYPES.items.ITEM_PACKAGING_TYPES_UPDATE:
            if (state && state.packagings?.find((da) => da.id === action.payload.id))
                return {
                    ...state,
                    packagings: state.packagings.map(da => da.id === action.payload.id ? {...action.payload} : da)
                }
            return state
        case APP_ACTIONS_TYPES.customers.CUSTOMER_ADDRESSES_DELETE:
            if (state && state.packagings?.find((da) => da.id === action.payload))
                return {
                    ...state,
                    packagings: state.packagings.filter(ol => ol.id !== action.payload)
                }
            return state
        default:
            return state
    }

}

const fetching = (state: boolean = false, action: AnyAction) => {
    if (action.type.startsWith("ITEM") && !action.type.startsWith("ITEM_FORM")) {
        return action.type === APP_ACTIONS_TYPES.items.ITEMS_RETRIEVING
    }
    return state
}

const fetchingOnAction = (state: boolean = false, action: AnyAction) => {
    return action.type === APP_ACTIONS_TYPES.items.ITEM_FETCHING
}

const count = (state: number = 0, action: AnyAction) => {
    switch (action.type) {
        case APP_ACTIONS_TYPES.items.ITEMS_RETRIEVED:
            return action.payload.count
        case APP_ACTIONS_TYPES.items.ITEM_RESET_DATA:
            return 0
        default:
            return state
    }
}

export default combineReducers({
    data,
    dataFormFields,
    selected,
    fetching,
    fetchingOnAction,
    count
})