import {EnumData, Parameter} from "../../../../corelogic/models/parameter";
import {Order, OrderFilter, OrderInitialize} from "../../../../corelogic/models/order";
import {getEnumKeyFromValue, getEnumValue, OrderStatus} from "../../InMemory/Data/enums";
import {getDateFromMilliseconds, getMillisecondsFromDate} from "./utils";
import {Address} from "../../../../corelogic/models/customer";

export interface OrderApiRequest {
    id: number
    numOrder?: string
    reference?: string
    status?: string
    orderDate?: number
    deliveryDate?: number
    comment?: string
    delayRequested?: number
    delayConfirmed?: number
    deliveryAddressBusinessName?: string
    deliveryAddress1?: string
    deliveryAddress2?: string
    deliveryAddress3?: string
    deliveryAddressZipCode1?: string
    deliveryAddressZipCode2?: string
    deliveryAddressCity?: string
    deliveryAddressCountryId?: number
}

export interface OrderApiWithCountResponse {
    count: number
    orders: OrderApiResponse[]
}

export interface OrderApiResponse {
    id: number
    numOrder?: string
    reference?: string
    status?: EnumData
    orderDate?: number
    deliveryDate?: number
    comment?: string
    hasComments?: boolean
    delayRequested?: number
    delayConfirmed?: number
    deliveryAddressBusinessName?: string
    deliveryAddress1?: string
    deliveryAddress2?: string
    deliveryAddress3?: string
    deliveryAddressZipCode1?: string
    deliveryAddressZipCode2?: string
    deliveryAddressCity?: string
    deliveryAddressCountry?: Parameter
    vatManagement?: EnumData
}

export interface OrderFilterApi {
    search: string,
    st?: string[],
    cd?: number
    dr?: number
    cc?: string
    cbn?: string
    tgte?: number | string
    ic?: string
}

export interface OrderInitializeApi {
    delayRequested?: number
    reference?: string
    address?: Address
}

export const mapOrderFilterToOrderFilterApi = (orderFilter: OrderFilter): OrderFilterApi => {
   return {
        search: orderFilter.search,
        st: orderFilter.st,
        cd: orderFilter.cd ? getMillisecondsFromDate(orderFilter.cd) : undefined,
        dr: orderFilter.dr ? getMillisecondsFromDate(orderFilter.dr) : undefined,
        cc: orderFilter.cc,
        cbn: orderFilter.cbn,
        tgte: orderFilter.tgte,
        ic: orderFilter.ic
    }
}

export const mapOrderApiResponseToOrder = (orderApiResponse: OrderApiResponse): Order => {
    return {
        ...orderApiResponse,
        status: getEnumValue(OrderStatus, orderApiResponse.status?.value),
        orderDate: getDateFromMilliseconds(orderApiResponse.orderDate),
        deliveryDate: getDateFromMilliseconds(orderApiResponse.deliveryDate),
        delayRequested: getDateFromMilliseconds(orderApiResponse.delayRequested),
        delayConfirmed: getDateFromMilliseconds(orderApiResponse.delayConfirmed),
    }
}
export const mapOrderInitializeResponseToOrderInitializeApi = (orderInitialize?: OrderInitialize): OrderInitializeApi => {
    if (orderInitialize)
        return {
            ...orderInitialize,
            delayRequested: getMillisecondsFromDate(orderInitialize.delayRequested),
        }
    return {}
}

export const mapOrderToOrderApiRequest = (order: Order): OrderApiRequest => {
    return {
        id: order.id,
        numOrder: order.numOrder,
        reference: order.reference,
        comment: order.comment,
        deliveryAddressBusinessName: order.deliveryAddressBusinessName,
        deliveryAddress1: order.deliveryAddress1,
        deliveryAddress2: order.deliveryAddress2,
        deliveryAddress3: order.deliveryAddress3,
        deliveryAddressZipCode1: order.deliveryAddressZipCode1,
        deliveryAddressZipCode2: order.deliveryAddressZipCode2,
        deliveryAddressCity: order.deliveryAddressCity,
        deliveryAddressCountryId: order.deliveryAddressCountry?.id,
        status: getEnumKeyFromValue(OrderStatus, order.status),
        orderDate: getMillisecondsFromDate(order.orderDate),
        deliveryDate: getMillisecondsFromDate(order.deliveryDate),
        delayRequested: getMillisecondsFromDate(order.delayRequested),
        delayConfirmed: getMillisecondsFromDate(order.delayConfirmed)
    }
}