import React, {useCallback, useEffect, useState} from 'react';
import Box from "@mui/material/Box";
import {DataGrid} from "@mui/x-data-grid";
import {useDispatch, useSelector} from "react-redux";
import {getCustomerSelectedSelector} from "../../../../../store/selectors/customersSelector";
import {retrieveCustomerById} from "../../../../../corelogic/usecase/customer/customersActions";
import {APP_ACTIONS_TYPES} from "../../../../../store/AppActionsTypes";
import {useNavigate, useSearchParams} from "react-router-dom";
import {
    Alert,
    Button,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Switch,
    Tooltip
} from "@mui/material";
import {getItemAllFormSelector} from "../../../../../store/selectors/itemsSelectors";
import {GridColDef} from "@mui/x-data-grid/models/colDef/gridColDef";
import RefreshIcon from "@mui/icons-material/Refresh";
import PublishedWithChanges from "@mui/icons-material/PublishedWithChanges";
import {columnsCustomerReferencingItemListDescription} from "../../Descriptions/customerReferencingItemListDescription";
import {retrieveAllItemDataFormFields} from "../../../../../corelogic/usecase/items/itemsActions";
import {ItemFilter} from "../../../../../corelogic/models/item";
import ReorderIcon from "@mui/icons-material/Reorder";
import messages, {getMessageDescriptor} from "../../../../../i18n/messages";
import {FormattedMessage, useIntl} from "react-intl";
import {UserRoleCode} from "../../../../../corelogic/models/user";
import ItemFilterAccordion from "../Item/ItemListPage/ItemFilterAccordion";
import {getUserLoggedSelector} from "../../../../../store/selectors/sessionSelector";
import {CustomerItemReferencingType} from "../../../../secondary/InMemory/Data/enums";
import {
    addAllCustomerItemReferenceForCustomerFamilyFromFilter,
    addAllCustomerItemReferenceForCustomerFromFilter,
    addAllCustomerItemReferenceForCustomerSuperFamilyFromFilter,
    addCustomerItemReferenceForCustomer,
    addCustomerItemReferenceForCustomerFamily,
    addCustomerItemReferenceForCustomerSuperFamily,
    removeAllCustomerItemReferenceForCustomerFamilyFromFilter,
    removeAllCustomerItemReferenceForCustomerFromFilter,
    removeAllCustomerItemReferenceForCustomerSuperFamilyFromFilter,
    removeCustomerItemReferenceForCustomer,
    removeCustomerItemReferenceForCustomerFamily,
    removeCustomerItemReferenceForCustomerSuperFamily,
    retrieveAllItemRefByCustomerFamilyId,
    retrieveAllItemRefByCustomerId,
    retrieveAllItemRefByCustomerSuperFamilyId
} from "../../../../../corelogic/usecase/customerItemReference/customerItemReferencesActions";
import {CustomerItemReferencingHeader} from "./CustomerItemReferencingHeader";
import {getCustomerItemReferencesSelector} from "../../../../../store/selectors/customerItemRefSelectors";
import {
    retrieveCustomerFamilyById,
    retrieveCustomerSuperFamilyById
} from "../../../../../corelogic/usecase/parameter/parametersActions";
import {getParameterSelectedSelector} from "../../../../../store/selectors/parameterSelectors";
import {DividingHR} from "../../Utils/DividingHR";

const CustomerReferencingPage = () => {
    const {dataFormFields} = useSelector(getItemAllFormSelector)
    const {data, fetching} = useSelector(getCustomerItemReferencesSelector)
    const {userLogged} = useSelector(getUserLoggedSelector)
    const {customerSelected} = useSelector(getCustomerSelectedSelector)
    const {selected: parameterSelected, fetching: parameterFetching} = useSelector(getParameterSelectedSelector)
    const dispatch = useDispatch()
    const {formatMessage} = useIntl()
    const navigate = useNavigate()
    const [searchParams] = useSearchParams()
    const [headerData, setHeaderData] = useState<{ code: string, label: string }>({code: "", label: ""})
    const [type, setType] = useState<CustomerItemReferencingType>(CustomerItemReferencingType.REFERENCING_BY_CUSTOMER)
    const [idNum, setIdNum] = useState<number>(0)
    const [dialogAllActionOpen, setDialogAllActionOpen] = useState(false)
    const [toggleFilters, setToggleFilters] = useState(false)
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: 25,
        page: 0,
    })
    const [density, setDensity] = useState(false)
    const [filterCount, setFilterCount] = useState(3)
    const [itemFilter, setItemFilter] = useState<ItemFilter>({
        search: "",
        familyCodes: [],
        superFamilyCodes: [],
        brandCodes: [],
        geographicalOriginCodes: [],
        authorizationToSellOnly: userLogged?.role?.code === UserRoleCode.COMMERCIAL || userLogged?.role?.code === UserRoleCode.CUSTOMER,
        favorite: undefined,
        isManagedInStock: undefined,
        isSold: true,
        isPurchased: undefined,
        isOrganic: undefined,
        isBlocked: false,
        isReferenced: true,
        isOrderSchedule: undefined,
        familyIds: [],
        superFamilyIds: [],
        brandIds: [],
        geographicalOriginIds: []
    })

    const getSearchParam = useCallback(() => {
        return {
            type: searchParams.get("type"),
            idNum: Number(searchParams.get("id"))
        }
    }, [searchParams])


    useEffect(() => {
        const {type, idNum} = getSearchParam()
        if (type === CustomerItemReferencingType.REFERENCING_BY_CUSTOMER && idNum) {
            setType(CustomerItemReferencingType.REFERENCING_BY_CUSTOMER)
            setIdNum(idNum)
            // setItemFilter(prevState => ({...prevState, isOrderSchedule: true}))
            dispatch(retrieveCustomerById(idNum))
        } else if (type === CustomerItemReferencingType.REFERENCING_BY_SUPER_FAMILY && idNum) {
            setType(CustomerItemReferencingType.REFERENCING_BY_SUPER_FAMILY)
            setIdNum(idNum)
            dispatch(retrieveCustomerSuperFamilyById(idNum))
        } else if (type === CustomerItemReferencingType.REFERENCING_BY_FAMILY && idNum) {
            setType(CustomerItemReferencingType.REFERENCING_BY_FAMILY)
            setIdNum(idNum)
            dispatch(retrieveCustomerFamilyById(idNum))
        } else {
            alert(formatMessage(getMessageDescriptor("referencingTypeNotFound")))
            navigate("/")
        }
        return () => {
            dispatch<any>({type: APP_ACTIONS_TYPES.customers.CUSTOMER_RESET_SELECTION})
        }
    }, [dispatch, getSearchParam, navigate])

    const columns: GridColDef[] = [
        {
            field: 'actions2',
            type: 'actions',
            headerName: 'bic',
            width: 100,
            headerAlign: "center",
            sortable: false,
            editable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            hideable: false,
            renderHeader: () => {
                return (
                    <Box sx={{width: "100%", height: "100%"}}>
                        <Tooltip title={formatMessage(getMessageDescriptor("customerItemMassActionButton"))}>
                            <Button variant="outlined" onClick={() => setDialogAllActionOpen(true)}>
                                <PublishedWithChanges/>
                            </Button>
                        </Tooltip>
                    </Box>
                )
            },
            renderCell: (params) => {
                return (
                    <Box sx={{display: "flex", justifyContent: "space-between"}}>
                        <Tooltip title={formatMessage(getMessageDescriptor("customerActivateReferencing"))}>
                            <Switch
                                name={params.row.id}
                                checked={params.row.referenced}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                                color={"primary"}
                                sx={{
                                    "& .MuiSwitch-track": {
                                        background: "hsl(0deg 0% 31.18%)"
                                    }
                                }}
                            />
                        </Tooltip>
                    </Box>
                )
            }
        },
        ...columnsCustomerReferencingItemListDescription.map(cd => ({
            ...cd,
            headerName: formatMessage(getMessageDescriptor(cd.headerName)),
            description: formatMessage(getMessageDescriptor(cd.headerName))
        })),
        {
            field: 'actions',
            type: 'actions',
            width: 100,
            headerAlign: "center",
            sortable: false,
            editable: false,
            filterable: false,
            disableReorder: true,
            resizable: false,
            hideable: false,
            renderHeader: () => {
                return (
                    <Box sx={{width: "100%", height: "100%"}}>
                        <Tooltip title={formatMessage(getMessageDescriptor("genericDataGridRefresh"))}>
                            <IconButton onClick={handleRefresh} sx={{
                                fontSize: "1.5rem",
                                cursor: "pointer"
                            }}>
                                <RefreshIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={formatMessage(getMessageDescriptor("genericDataGridChangeDensity"))}>
                            <IconButton onClick={handleChangeDensity}
                                        sx={{
                                            fontSize: "1.5rem",
                                            cursor: "pointer"
                                        }}>
                                <ReorderIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                )
            },
            renderCell: () => {
                return (
                    <Box sx={{width: "100%", height: "100%"}}>
                    </Box>
                )
            }
        }
    ]


    const loadData = useCallback(() => {
        if (type === CustomerItemReferencingType.REFERENCING_BY_CUSTOMER && idNum) {
            dispatch(retrieveAllItemRefByCustomerId(itemFilter, idNum))
        } else if (type === CustomerItemReferencingType.REFERENCING_BY_SUPER_FAMILY && idNum) {
            dispatch(retrieveAllItemRefByCustomerSuperFamilyId(itemFilter, idNum))
        } else if (type === CustomerItemReferencingType.REFERENCING_BY_FAMILY && idNum) {
            dispatch(retrieveAllItemRefByCustomerFamilyId(itemFilter, idNum))
        }
    }, [dispatch, idNum, itemFilter, type])

    useEffect(() => {
        loadData()
        return () => {
            dispatch<any>({type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RESET_DATA})
            dispatch<any>({type: APP_ACTIONS_TYPES.customerItemReference.CUSTOMER_ITEM_REF_RESET_DATA_SF_AND_F})
        }
    }, [dispatch, itemFilter, loadData, searchParams])

    useEffect(() => {
        dispatch<any>(retrieveAllItemDataFormFields())
    }, [dispatch])

    useEffect(() => {
        if (customerSelected)
            setHeaderData({code: customerSelected.code, label: customerSelected.businessName || ""})
        else if (parameterSelected)
            setHeaderData({code: parameterSelected.code, label: parameterSelected.label || ""})
        else setHeaderData({code: "", label: ""})
    }, [customerSelected, parameterSelected]);

    const handleRefresh = () => {
        loadData()
    }

    const handleChangeDensity = () => {
        setDensity(prevState => !prevState)
        if (!density && paginationModel.pageSize <= 50) {
            setPaginationModel({...paginationModel, pageSize: 50})
        }
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const itemFound = data.find(x => x.id === Number(event.target.name))
        if (itemFound) {
            if (type === CustomerItemReferencingType.REFERENCING_BY_CUSTOMER && idNum && customerSelected) {
                if (!itemFound.referenced) {
                    dispatch<any>(addCustomerItemReferenceForCustomer(itemFound.id, customerSelected.id))
                } else {
                    dispatch<any>(removeCustomerItemReferenceForCustomer(itemFound.id, customerSelected.id))
                }
            } else if (type === CustomerItemReferencingType.REFERENCING_BY_SUPER_FAMILY && idNum && parameterSelected) {
                if (!itemFound.referenced) {
                    dispatch<any>(addCustomerItemReferenceForCustomerSuperFamily(itemFound.id, parameterSelected.id))
                } else {
                    dispatch<any>(removeCustomerItemReferenceForCustomerSuperFamily(itemFound.id, parameterSelected.id))
                }
            } else if (type === CustomerItemReferencingType.REFERENCING_BY_FAMILY && idNum && parameterSelected) {
                if (!itemFound.referenced) {
                    dispatch<any>(addCustomerItemReferenceForCustomerFamily(itemFound.id, parameterSelected.id))
                } else {
                    dispatch<any>(removeCustomerItemReferenceForCustomerFamily(itemFound.id, parameterSelected.id))
                }
            }
        }
    }

    const handleChangeAll = (add: boolean) => {
        if (type === CustomerItemReferencingType.REFERENCING_BY_CUSTOMER && customerSelected) {
            if (add) {
                dispatch<any>(addAllCustomerItemReferenceForCustomerFromFilter(itemFilter, customerSelected.id))
            } else {
                dispatch<any>(removeAllCustomerItemReferenceForCustomerFromFilter(itemFilter, customerSelected.id))
            }
        } else if (type === CustomerItemReferencingType.REFERENCING_BY_FAMILY && parameterSelected) {
            if (add) {
                dispatch<any>(addAllCustomerItemReferenceForCustomerFamilyFromFilter(itemFilter, parameterSelected.id))
            } else {
                dispatch<any>(removeAllCustomerItemReferenceForCustomerFamilyFromFilter(itemFilter, parameterSelected.id))
            }
        } else if (type === CustomerItemReferencingType.REFERENCING_BY_SUPER_FAMILY && parameterSelected) {
            if (add) {
                dispatch<any>(addAllCustomerItemReferenceForCustomerSuperFamilyFromFilter(itemFilter, parameterSelected.id))
            } else {
                dispatch<any>(removeAllCustomerItemReferenceForCustomerSuperFamilyFromFilter(itemFilter, parameterSelected.id))
            }
        }
    }

    const handleChangeSearchBar = useCallback((input: string) => {
        setItemFilter(prev => ({
            ...prev,
            search: input
        }))
    }, [])

    const handleApplyFilters = (itemFilters: ItemFilter, filterCount: number) => {
        console.log("itemFilters", itemFilters)
        setItemFilter(prev => ({...itemFilters, search: prev.search}))
        setFilterCount(filterCount)
    }
    const getToggleFilterBooleanState = (toggleFiltersValue: boolean) => {
        if (toggleFiltersValue) {
            setToggleFilters(true)
        } else {
            setToggleFilters(false)
        }
    }

    const handleDialogMassActionClose = () => {
        setDialogAllActionOpen(false)
    }
    const handleDialogMassActionAdd = () => {
        handleChangeAll(true)
        setDialogAllActionOpen(false)
    }
    const handleDialogMassActionRemove = () => {
        handleChangeAll(false)
        setDialogAllActionOpen(false)
    }

    const getStringFromType = (type: CustomerItemReferencingType) => {
        switch (type) {
            case CustomerItemReferencingType.REFERENCING_BY_CUSTOMER:
                return formatMessage(getMessageDescriptor("customer")).toLowerCase()
            case CustomerItemReferencingType.REFERENCING_BY_FAMILY:
                return formatMessage(getMessageDescriptor("customerFamily")).toLowerCase()
            case CustomerItemReferencingType.REFERENCING_BY_SUPER_FAMILY:
                return formatMessage(getMessageDescriptor("customerSuperFamily")).toLowerCase()
            default:
                return formatMessage(getMessageDescriptor("unknown")).toLowerCase()
        }
    }

    return (
        <>
            <Box sx={{position: "sticky", top: "77px", zIndex: 1000}}>
                <CustomerItemReferencingHeader data={headerData} onChangeSearchInput={handleChangeSearchBar}
                                               getToggleFiltersState={getToggleFilterBooleanState}
                                               filterCount={filterCount} type={type} counter={data.length}
                                               fetching={parameterFetching}/>
            </Box>
            <Grid container height="84vh" p={1}>
                <Collapse orientation="horizontal" in={toggleFilters} sx={{borderRadius: "1%"}}>
                    <ItemFilterAccordion dataFormFields={dataFormFields} onClickApplyFilters={handleApplyFilters}
                                         withReferenced
                                         withOrderSchedule={type === CustomerItemReferencingType.REFERENCING_BY_CUSTOMER}/>
                </Collapse>
                <DataGrid
                    rows={data}
                    columns={columns}
                    columnVisibilityModel={{id: false}}
                    paginationModel={paginationModel}
                    pageSizeOptions={density ? [50, 75, 100] : [25, 50, 75, 100]}
                    onPaginationModelChange={(newPaginationModel) => setPaginationModel(newPaginationModel)}
                    density={density ? "compact" : "standard"}
                    loading={fetching}
                    disableRowSelectionOnClick
                    slots={{
                        noRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                <Alert severity="info">
                                    {formatMessage(getMessageDescriptor("itemListFilterNotFound"))}
                                </Alert>
                            </Stack>
                        )
                    }}
                    sx={{
                        "& .MuiDataGrid-cell:focus": {
                            outline: "none"
                        },
                        "& .MuiDataGrid-columnHeader:focus": {
                            outline: "none"
                        }
                    }}
                />
                {/*</Paper>*/}
                {/*</Grid>*/}
            </Grid>
            {dialogAllActionOpen && <Dialog
                maxWidth="lg"
                open={dialogAllActionOpen}
                onClose={handleDialogMassActionClose}
            >
                <DialogTitle>
                    <FormattedMessage id={messages.customerItemMassAction.id} values={{type: getStringFromType(type)}}/>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText m={1}>
                        <FormattedMessage id={messages.customerItemMassActionWarning.id}/>
                    </DialogContentText>
                    <DialogContentText m={1}>
                        <FormattedMessage id={messages.genericActionAskingBeforeValidating.id}/>
                    </DialogContentText>
                </DialogContent>
                <DividingHR subContent/>
                <DialogActions>
                    <Button variant="outlined" onClick={handleDialogMassActionClose}>
                        <FormattedMessage id={messages.genericCancel.id}/>
                    </Button>
                    <Button variant="outlined" color="error" onClick={handleDialogMassActionRemove}>
                        <FormattedMessage id={messages.genericRemoveAll.id}/>
                    </Button>
                    <Button variant="outlined" color="success" onClick={handleDialogMassActionAdd}>
                        <FormattedMessage id={messages.genericAddAll.id}/>
                    </Button>
                </DialogActions>
            </Dialog>}
        </>
    )
}

export default CustomerReferencingPage;