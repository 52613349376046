import {BadgeProps, Button, Grid, Tooltip} from "@mui/material";
import * as React from "react";
import {useEffect, useState} from "react";
import {SearchBar} from "../../../Utils/SearchBar";
import {getMessageDescriptor} from "../../../../../../i18n/messages";
import {useIntl} from "react-intl";
import ReturnButton from "../../../Utils/ReturnButton";
import {DividingHR} from "../../../Utils/DividingHR";
import {TextValueField} from "../../../Utils/TextValueField";
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import {styled} from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import {useDebounce} from "@uidotdev/usehooks";
import {useSearchParams} from "react-router-dom";
import {ItemFilterImpl} from "../../../../../../corelogic/models/item";

const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

interface ItemsListPageHeaderProps {
    productCount?: number,
    onChange?: (inputValue: string) => void
    getToggleFiltersState?: (isToggleFilters: boolean) => void
    filterCount?: number
}

export const ItemsListPageHeader = (props: ItemsListPageHeaderProps) => {
    const {productCount, onChange, getToggleFiltersState, filterCount} = props
    const intl = useIntl()
    const [toggleFilters, setToggleFilters] = useState<boolean>(true)
    const [inputSearch, setInputSearch] = useState("")
    const delaySearchValue = useDebounce(inputSearch, 500)
    const [isFirstChange, setIsFirstChange] = useState(true)
    const [searchParams] = useSearchParams();

    useEffect(() => {
        let params = ItemFilterImpl.fromSearchParam(searchParams)
        setInputSearch(params.search ? params.search : "")
    }, [])

    useEffect(() => {
        if (onChange !== undefined && !isFirstChange) {
            onChange(delaySearchValue)
        }
    }, [onChange,delaySearchValue,isFirstChange])

    const handleOpenFilters = () => {
        setToggleFilters(prevState => !prevState)
        if (getToggleFiltersState) {
            getToggleFiltersState(toggleFilters)
        }
    }

    const handleSearchBarChange = (input: string) => {
        setInputSearch(input)
        setIsFirstChange(false)
    }

    const handleSearchBarReset = () => {
        setInputSearch("")
    }

    return (
        <Grid container justifyContent="space-between" alignItems="center" p={1}>
            <Grid item>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <ReturnButton/>
                    </Grid>
                    <Grid item mx={2}>
                        <DividingHR
                            subContent
                            style={{height: "30px", width: 1, margin: 0}}/>
                    </Grid>
                    <Grid item>
                        <StyledBadge badgeContent={filterCount} color="error">
                            {toggleFilters ?
                                <Tooltip title={intl.formatMessage(getMessageDescriptor("itemListFilterOpenTitle"))}>
                                    <Button variant="contained" onClick={handleOpenFilters}>
                                        <MenuIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                    </Button>
                                </Tooltip>
                                :
                                <Tooltip title={intl.formatMessage(getMessageDescriptor("itemListFilterCloseTitle"))}>
                                    <Button variant="contained" onClick={handleOpenFilters}>
                                        <MenuOpenIcon sx={{marginRight: "5px", marginBottom: "1px"}}/>
                                    </Button>
                                </Tooltip>
                            }
                        </StyledBadge>
                    </Grid>
                    <Grid item mx={2}>
                        <DividingHR
                            subContent
                            style={{height: "30px", width: 1, margin: 0}}/>
                    </Grid>
                    <Grid item>
                        <TextValueField
                            style={{
                                fontWeight: "bolder"
                            }}
                            text={intl.formatMessage(getMessageDescriptor("itemListPageHeaderTitle"))}
                            value={productCount}/>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <Grid container alignItems="center">
                    <Grid item>
                        <SearchBar label={intl.formatMessage(getMessageDescriptor("searchBarItem"))}
                                   value={inputSearch}
                                   onChange={handleSearchBarChange} onResetClick={handleSearchBarReset}/>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}