import {FormError, OrderCreationPageHeader} from "./OrderCreationPageHeader";
import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Paper,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Grid from '@mui/material/Grid';
import * as React from "react";
import {useEffect, useState} from "react";
import {OrderItemListWithDrawer} from "./OrderItemListWithDrawer";
import '../../../Style/Pages/OrderPages/_OrderCreationPage.scss';
import {useDispatch, useSelector} from "react-redux";
import {getOrderLinesSelector, getOrderSelectedSelector} from "../../../../../../store/selectors/ordersSelectors";
import {
    addOrderLine,
    deleteOrder,
    removeOrderLine,
    retrieveFilteredOrderLinesByOrderId,
    retrieveSelectedOrder,
    saveData,
    validateOrder
} from "../../../../../../corelogic/usecase/orders/ordersActions";
import {DividingHR} from "../../../Utils/DividingHR";
import {generatePath, useNavigate, useParams} from "react-router-dom";
import {APP_ACTIONS_TYPES} from "../../../../../../store/AppActionsTypes";
import {Order, OrderInfoDialog, OrderLineFilter, TemporaryOrderLine} from "../../../../../../corelogic/models/order";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {FormattedMessage, useIntl} from "react-intl";
import 'react-toastify/dist/ReactToastify.css';
import {
    getCustomerSelectedDeliveryAddresses,
    getCustomerSelectedSelector
} from "../../../../../../store/selectors/customersSelector";
import {
    retrieveCustomerAddresses,
    retrieveCustomerById
} from "../../../../../../corelogic/usecase/customer/customersActions";
import {Customer} from "../../../../../../corelogic/models/customer";
import moment, {Moment} from "moment";
import {OrderBillingDetails} from "./OrderBillingDetails";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {useDebounce} from "@uidotdev/usehooks";
import {getUserLoggedSelector} from "../../../../../../store/selectors/sessionSelector";
import {UserRoleCode} from "../../../../../../corelogic/models/user";

export const OrderCreationPageSelector = () => {
    const dispatch = useDispatch()
    const {orderId} = useParams()
    const {orderSelected, fetchingSelected} = useSelector(getOrderSelectedSelector)
    const {customerSelected} = useSelector(getCustomerSelectedSelector)
    const navigate = useNavigate()

    useEffect(() => {
        const orderIdNum = Number(orderId)
        if (orderId && !isNaN(orderIdNum))
            dispatch(retrieveSelectedOrder(orderIdNum))
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.orders.ORDER_RESET_SELECTION})
    }, [dispatch, orderId])

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (!fetchingSelected && !orderSelected) {
                navigate(generatePath("/"))
            }
        }, 10000)
        return () => clearTimeout(timeOut)
    })

    useEffect(() => {
        orderSelected?.orderedCustomer?.id && dispatch(retrieveCustomerById(orderSelected?.orderedCustomer?.id))
        return () => dispatch<any>({type: APP_ACTIONS_TYPES.customers.CUSTOMER_RESET_SELECTION})
    }, [dispatch, orderSelected?.orderedCustomer?.id])

    return (
        <>
            {orderSelected && customerSelected && !fetchingSelected ?
                <OrderCreationPage dataOrder={orderSelected} customerSelected={customerSelected}/> :
                <LinearProgress sx={{
                    margin: "auto",
                    marginBottom: "5px",
                    height: "15px"
                }}/>
            }
        </>
    )
}

interface OrderCreationPageInterface {
    dataOrder: Order,
    customerSelected: Customer
}

export const OrderCreationPage = (props: OrderCreationPageInterface) => {
    const {dataOrder, customerSelected} = props
    const {userLogged} = useSelector(getUserLoggedSelector)
    const {customerSelectedAddresses} = useSelector(getCustomerSelectedDeliveryAddresses)
    const {dataOrderLines} = useSelector(getOrderLinesSelector)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const intl = useIntl()
    const theme = useTheme()
    const isSmallerThanXL = useMediaQuery(theme.breakpoints.down('xl'))
    const [openModalValidateOrder, setOpenModalValidateOrder] = useState(false)
    const [openModalLineAddConfirmation, setOpenModalLineAddConfirmation] = useState(false)
    const [temporaryOrderLineInfo, setTemporaryOrderLineInfo] = useState<TemporaryOrderLine>()
    const [formData, setFormData] = useState<Order>({
        ...dataOrder,
        deliveryAddressBusinessName: dataOrder.deliveryAddressBusinessName ? dataOrder.deliveryAddressBusinessName : customerSelected.businessName,
        deliveryAddress1: dataOrder.deliveryAddress1 ? dataOrder.deliveryAddress1 : customerSelected.address1,
        deliveryAddress2: dataOrder.deliveryAddress2 ? dataOrder.deliveryAddress2 : customerSelected.address2,
        deliveryAddress3: dataOrder.deliveryAddress3 ? dataOrder.deliveryAddress3 : customerSelected.address3,
        deliveryAddressZipCode1: dataOrder.deliveryAddressZipCode1 ? dataOrder.deliveryAddressZipCode1 : customerSelected.zipCode1,
        deliveryAddressZipCode2: dataOrder.deliveryAddressZipCode2 ? dataOrder.deliveryAddressZipCode2 : customerSelected.zipCode2,
        deliveryAddressCity: dataOrder.deliveryAddressCity ? dataOrder.deliveryAddressCity : customerSelected.city,
        deliveryAddressCountry: dataOrder.deliveryAddressCountry ? dataOrder.deliveryAddressCountry : customerSelected.country,
        delayRequested: dataOrder.delayRequested || moment().add(customerSelected?.transitTime || 1, "day").toDate()
    })
    const [formError, setFormError] = useState<FormError>({
        deliveryAddress: false,
        delayRequested: false,
        emptyLines: false
    })
    const [orderLineFilter, setOrderLineFilter] = useState<OrderLineFilter>({
        search: ""
    })
    const [displayItemList, setDisplayItemList] = useState(true)
    const [displayBillingDetails, setDisplayBillingDetails] = useState(true)
    const [searchValue, setSearchValue] = useState("")
    const delaySearchValue = useDebounce(searchValue, 500)

    useEffect(() => {
        setOrderLineFilter(prevState => ({...prevState, search: delaySearchValue}))
    }, [delaySearchValue])

    useEffect(() => {
        dispatch(retrieveFilteredOrderLinesByOrderId(dataOrder.id, orderLineFilter))
    }, [dataOrder.id, dispatch, orderLineFilter, dataOrder.delayRequested])

    useEffect(() => {
        dispatch(retrieveCustomerAddresses(customerSelected.id))
    }, [customerSelected.id, dispatch])

    useEffect(() => {
        setFormError({
            deliveryAddress: !formData.deliveryAddressBusinessName || !formData.deliveryAddress1 || !formData.deliveryAddressZipCode1 || !formData.deliveryAddressCity,
            delayRequested: moment(formData.delayRequested) < moment().add(1, "day").startOf("day"),
            emptyLines: dataOrderLines.length <= 0
        })
    }, [dataOrderLines.length, formData])

    //----------------------------- ORDER ACTIONS ---------------------------------

    const handleSubmit = () => {
        dispatch(validateOrder(formData, true))
        setOpenModalValidateOrder(false)
        navigate(generatePath("/orders"))
    }

    const handleNotSubmit = () => {
        dispatch(validateOrder(formData))
        setOpenModalValidateOrder(false)
        navigate(generatePath("/orders"))
    }

    const handleValidateClick = () => {
        setOpenModalValidateOrder(true)
    }

    const handleDeleteOrder = (orderId: number) => {
        dispatch(deleteOrder(orderId))
        navigate(generatePath("/orders"))
    }

    const handleCancelClick = () => {
        navigate(generatePath("/orders"))
    }

    //----------------------------- ORDER LINES ACTIONS ---------------------------------

    const handleItemAddClick = (id: number, quantity: number, unit: string) => {
        const isAlreadyAddedItem = dataOrderLines.find(item => item.item.id === id)
        if (isAlreadyAddedItem) {
            setOpenModalLineAddConfirmation(true)
            setTemporaryOrderLineInfo({id: id, quantity: quantity, unit: unit})
        } else {
            dispatch<any>(addOrderLine(dataOrder.id, id, quantity, unit))
        }
    }

    const handleDeleteLineClick = (lineId: number) => {
        if (lineId) {
            dispatch(removeOrderLine(lineId))
        }
    }

    const handleOrderLineFilterChange = (orderLineFilterInput: string) => {
        setSearchValue(orderLineFilterInput ? orderLineFilterInput : "")
    }

    const handleAddExistingLineConfirm = () => {
        if (temporaryOrderLineInfo?.id && temporaryOrderLineInfo?.quantity && temporaryOrderLineInfo.unit) {
            dispatch<any>(addOrderLine(dataOrder.id, temporaryOrderLineInfo.id, temporaryOrderLineInfo.quantity, temporaryOrderLineInfo.unit))
        }
        setOpenModalLineAddConfirmation(false)
    }

    const handleAddExistingLineCancel = () => {
        setOpenModalLineAddConfirmation(false)
    }

    //----------------------------- DISPLAY RESPONSIVE ACTIONS ---------------------------------

    const handleChangeDisplay = () => {
        setDisplayBillingDetails(prevState => !prevState)
        setDisplayItemList(prevState => !prevState)
    }

    useEffect(() => {
        if (!isSmallerThanXL) {
            setDisplayBillingDetails(true)
            setDisplayItemList(true)
        }
        if (isSmallerThanXL && !displayItemList) {
            setDisplayBillingDetails(true)
        } else if (isSmallerThanXL && !displayBillingDetails) {
            setDisplayItemList(true)
        } else if (isSmallerThanXL && displayItemList && displayBillingDetails) {
            setDisplayBillingDetails(false)
        }
    }, [displayBillingDetails, displayItemList, isSmallerThanXL])

    //----------------------------- HEADER ACTIONS ---------------------------------

    const handleHeaderValidation = (value: OrderInfoDialog) => {
        const dateMoment = value.delayRequested as Moment
        const order = {
            ...formData,
            reference: value.reference,
            delayRequested: value.delayRequested !== dataOrder.delayRequested ? dateMoment.toDate() : value.delayRequested,
            comment: value.comment,
            deliveryAddressBusinessName: value.deliveryAddress.businessName,
            deliveryAddress1: value.deliveryAddress.address1,
            deliveryAddress2: value.deliveryAddress.address2,
            deliveryAddress3: value.deliveryAddress.address3,
            deliveryAddressZipCode1: value.deliveryAddress.zipCode1,
            deliveryAddressZipCode2: value.deliveryAddress.zipCode2,
            deliveryAddressCity: value.deliveryAddress.city,
            deliveryAddressCountry: value.deliveryAddress.country
        }
        setFormData({...order})
        dispatch(saveData({...order}))
    }

    const renderValidateOrder = () => {
        return (
            <Dialog open fullWidth>
                <DialogTitle>
                    <Grid container flexWrap="nowrap">
                        <Grid item>
                            <FormattedMessage id={messages.orderCreationModalOrderRegistered.id}/>
                        </Grid>
                        <Grid item>
                            <IconButton>
                                <CloseIcon onClick={() => setOpenModalValidateOrder(false)}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    {formError.delayRequested && <DialogContentText
                        sx={{display: "flex", alignItems: "flex-start", padding: "10px"}}>
                        <Alert
                            severity="warning">{intl.formatMessage(getMessageDescriptor("orderCreationPageWarningDeliveryDate"))}</Alert>
                    </DialogContentText>}
                    {formError.deliveryAddress && <DialogContentText
                        sx={{display: "flex", alignItems: "flex-start", padding: "10px"}}>
                        <Alert
                            severity="warning">{intl.formatMessage(getMessageDescriptor("orderCreationPageWarningDeliveryAddress"))}</Alert>
                    </DialogContentText>}
                    {formError.emptyLines && <DialogContentText
                        sx={{display: "flex", alignItems: "flex-start", padding: "10px"}}>
                        <Alert
                            severity="warning">{intl.formatMessage(getMessageDescriptor("orderCreationPageWarningNoOrderLines"))}</Alert>
                    </DialogContentText>}
                    <DialogContentText
                        sx={{display: "flex", alignItems: "flex-start", padding: "10px"}}>
                        <Alert severity="info"><FormattedMessage
                            id={messages.orderCreationModalOrderRegisteredSubmittingInformation.id}/></Alert>
                    </DialogContentText>
                    <DialogContentText
                        sx={{display: "flex", alignItems: "flex-start", padding: "10px"}}>
                        <Alert severity="info"><FormattedMessage
                            id={messages.orderCreationModalOrderRegisteredCancelSubmittingInformation.id}/></Alert>
                    </DialogContentText>
                </DialogContent>
                <DividingHR subContent/>
                <DialogActions>
                    <Button onClick={handleNotSubmit} variant="outlined" color="error"
                            disabled={Object.values(formError).includes(true)}>
                        <FormattedMessage
                            id={messages.genericNotSubmit.id}/>
                    </Button>
                    <Button onClick={handleSubmit} variant="outlined"
                            disabled={Object.values(formError).includes(true)}>
                        <FormattedMessage id={messages.genericSubmit.id}/>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderAddLineConfirmation = () => {
        return (
            <Dialog open>
                <DialogTitle>
                    {intl.formatMessage(getMessageDescriptor("orderLineAddConfirmationTitle"))}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText
                        sx={{display: "flex", alignItems: "flex-start", padding: "10px"}}>
                        <Alert severity="info">
                            {intl.formatMessage(getMessageDescriptor("orderLineAddConfirmationInfo"))}
                        </Alert>
                    </DialogContentText>
                </DialogContent>
                <DividingHR subContent/>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleAddExistingLineCancel}> <FormattedMessage
                        id={messages.genericCancel.id}/>
                    </Button>
                    <Button variant="contained" onClick={handleAddExistingLineConfirm}>
                        <FormattedMessage id={messages.genericConfirm.id}/>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    return (
        <>
            <Paper elevation={3} sx={{backgroundColor: "common.main", position: "sticky", top: "77px", zIndex: 1000}}>
                <OrderCreationPageHeader customerSelected={customerSelected}
                                         addresses={customerSelectedAddresses}
                                         orderSelected={dataOrder}
                                         formData={formData}
                                         isSmallerThanXLDeviceWidth={isSmallerThanXL}
                                         onChangeDisplayButtonClick={handleChangeDisplay}
                                         onValidate={handleHeaderValidation}
                                         isCustomer={userLogged?.role?.code === UserRoleCode.CUSTOMER}
                />
            </Paper>
            <Grid container spacing={1} p={1}>
                {/* ---------------------- FILTERING AND ITEM LIST PART ---------------------- */}
                <Grid item xs={12} xl={6} hidden={!displayItemList}>
                    <Paper elevation={2}>
                        <OrderItemListWithDrawer onItemAddClick={handleItemAddClick}/>
                    </Paper>
                </Grid>
                {/* ---------------------- BILLING LIST ---------------------- */}
                <Grid item xs={12} xl={6} hidden={!displayBillingDetails}>
                    <Paper elevation={2}>
                        <OrderBillingDetails dataOrder={dataOrder}
                                             onDeleteLineClick={handleDeleteLineClick}
                                             onOrderLineFilterBySearchChange={handleOrderLineFilterChange}
                                             onDeleteOrder={handleDeleteOrder}
                                             onCancelClick={handleCancelClick}
                                             onValidateClick={handleValidateClick}
                                             customer={customerSelected}/>
                    </Paper>
                </Grid>
            </Grid>
            {/* ---------------------- CONFIRMATION DIALOGS ---------------------- */}
            {openModalValidateOrder && renderValidateOrder()}
            {openModalLineAddConfirmation && renderAddLineConfirmation()}
        </>

    )
}