import React, {useEffect, useState} from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    BadgeProps,
    Button,
    Checkbox,
    FormGroup,
    Grid,
    Paper,
    TextField,
    Tooltip
} from "@mui/material";
import Badge from "@mui/material/Badge";
import {FormattedMessage, useIntl} from "react-intl";
import messages, {getMessageDescriptor} from "../../../../../../i18n/messages";
import {DividingHR} from "../../../Utils/DividingHR";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FormControlLabel from "@mui/material/FormControlLabel";
import Box from "@mui/material/Box";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import moment, {Moment} from "moment";
import {OrderFilter, OrderFilterImpl} from "../../../../../../corelogic/models/order";
import DataArrayIcon from '@mui/icons-material/DataArray';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';
import {styled} from "@mui/material/styles";
import InputAdornment from "@mui/material/InputAdornment";
import CloseIcon from '@mui/icons-material/Close';
import {useSearchParams} from "react-router-dom";
import {getOrderStatusMessage, OrderStatus} from "../../../../../secondary/InMemory/Data/enums";

const StyledBadge = styled(Badge)<BadgeProps>(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}))

interface OrderFilterAccordionProps {
    onClickResetAllFilters?: () => void
    onClickApplyAllFilters?: (orderFilterState: OrderFilter, filterCount: number) => void
}

const OrderFilterAccordeon = (props: OrderFilterAccordionProps) => {
    const {onClickApplyAllFilters, onClickResetAllFilters} = props
    const intl = useIntl()
    const [filterCount, setFilterCount] = useState(0)
    // const [creationDateValue, setDelayRequestedValue] = useState<Moment | null>(moment().subtract(1, 'months').set({D:1}))
    const [creationDateValue, setCreationDateValue] = useState<Moment | null>(null)
    const [deliveryDateValue, setDeliveryDateValue] = useState<Moment | null>(null)
    const [searchParams] = useSearchParams();
    const [orderFilter, setOrderFilter] = useState<OrderFilter>({
        search: "",
        st: [],
        // cd: creationDateValue ? creationDateValue.toDate().getTime() : undefined
    })
    const [filterAccordionExpand, setFilterAccordionExpand] = useState({
        status: false
    })

    useEffect(() => {
        let params = searchParams.size === 0 ? new OrderFilterImpl(orderFilter) : OrderFilterImpl.fromSearchParam(searchParams)
        setOrderFilter(params.getFilters())
        setCreationDateValue(params.cd ? moment(params.cd) : null)
        setDeliveryDateValue(params.dr ? moment(params.dr) : null)
        setFilterCount(params.count())
    }, [searchParams, setFilterCount, setOrderFilter, setCreationDateValue])

    useEffect(() => {
        setFilterCount(new OrderFilterImpl(orderFilter).count())
    }, [orderFilter])

    const applyAllFilters = () => {
        if (onClickApplyAllFilters) {
            onClickApplyAllFilters(orderFilter, filterCount)
        }
    }

    const resetAllFilters = () => {
        if (onClickResetAllFilters) {
            onClickResetAllFilters()
        }
        setCreationDateValue(moment().subtract(1, 'months').set({D: 1}))
        setDeliveryDateValue(null)
        setOrderFilter({
            ...orderFilter,
            st: [],
            // cd: moment().subtract(1, 'months').set({D:1}).toDate().getTime(),
            dr: undefined,
            cc: "",
            cbn: "",
            tgte: "",
            ic: ""
        })
    }

    const handleToggleAccordionState = (name: string) => {
        setFilterAccordionExpand({
            ...filterAccordionExpand,
            [name]: !filterAccordionExpand[name as keyof Object]
        })
    }

    const handleFilterBy = (e: React.ChangeEvent<HTMLInputElement>, filterName: string) => {
        if (!filterName) {
            return;
        }
        const code = e.target.name;
        const filterCodes = orderFilter[filterName as keyof OrderFilter] as string[] || [];

        if (e.target.checked) {
            if (!filterCodes.includes(code)) {
                setOrderFilter({...orderFilter, [filterName]: [...filterCodes, code]});
            }
        } else {
            setOrderFilter({
                ...orderFilter,
                [filterName]: filterCodes.filter(c => c !== code)
            });
        }
    }


    const handleChangeDate = (newValue: Moment | null) => {
        if (newValue) {
            setCreationDateValue(newValue)
            setOrderFilter({...orderFilter, cd: newValue.toDate()})
        } else {
            setCreationDateValue(null)
            setOrderFilter({...orderFilter, cd: undefined})
        }
    }

    const handleChangeDeliveryDate = (newValue: Moment | null) => {
        if (newValue) {
            setDeliveryDateValue(newValue)
            setOrderFilter({...orderFilter, dr: newValue.toDate()})
        } else {
            setOrderFilter({...orderFilter, dr: undefined})
        }
    }

    const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setOrderFilter({
            ...orderFilter,
            [event.target.name]: event.target.value
        })
    }

    const handleCleanField = (name: string) => {
        setOrderFilter({
            ...orderFilter,
            [name]: ""
        })
    }

    return (
        <Grid container direction="column" spacing={1} px={2} width={310}>
            <Grid item container justifyContent="space-evenly">
                <Grid item>
                    <StyledBadge badgeContent={filterCount} color="error">
                        <Tooltip title={intl.formatMessage(getMessageDescriptor("genericApplyFilters"))}>
                            <Button variant="contained" onClick={applyAllFilters}>
                                <FilterAltIcon/>
                            </Button>
                        </Tooltip>
                    </StyledBadge>
                </Grid>
                <Grid item>
                    <Tooltip title={intl.formatMessage(getMessageDescriptor("itemListFilterResetTitle"))}>
                        <Button variant="contained" onClick={resetAllFilters}>
                            <FilterAltOffIcon/>
                        </Button>
                    </Tooltip>
                </Grid>
            </Grid>
            <DividingHR subContent style={{marginBottom: 0}}/>
            <Grid item>
                <Paper elevation={5}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item>
                            <Box sx={{display: "flex", alignItems: "center", margin: "10px"}}>
                                <DesktopDatePicker
                                    label={intl.formatMessage(getMessageDescriptor("orderFilterByCreationDate"))}
                                    inputFormat="DD/MM/YYYY"
                                    value={creationDateValue}
                                    onChange={handleChangeDate}
                                    renderInput={(params) => <TextField fullWidth {...params}
                                                                        variant="standard"
                                    />}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item>
                <Paper elevation={5}>
                    <Grid container alignItems="center" justifyContent="center">
                        <Grid item>
                            <Box sx={{display: "flex", alignItems: "center", margin: "10px"}}>
                                <DesktopDatePicker
                                    label={intl.formatMessage(getMessageDescriptor("orderFilterByDeliveryDate"))}
                                    inputFormat="DD/MM/YYYY"
                                    value={deliveryDateValue}
                                    onChange={handleChangeDeliveryDate}
                                    renderInput={(params) => <TextField fullWidth {...params}
                                                                        variant="standard"
                                    />}
                                />
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item>
                <Accordion elevation={5} expanded={filterAccordionExpand.status}
                           onChange={() => handleToggleAccordionState("status")}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>}>
                        <Grid container>
                            <DataArrayIcon sx={{fill: "rgba(33, 150, 243, 1)"}}/>
                            <Typography fontWeight="bolder" paddingLeft={2}>
                                <FormattedMessage id={messages.orderGridColOrderStatus.id}/>
                            </Typography>
                        </Grid>
                    </AccordionSummary>
                    <DividingHR subContent style={{margin: 0}}/>
                    <AccordionDetails sx={{maxHeight: "300px", overflow: "auto"}}>
                        <FormGroup>
                            {Object.values(OrderStatus)
                                .filter(value => value !== OrderStatus.UNDEFINED)
                                .map(status => {
                                    const orderStatus = status as OrderStatus;
                                    return (
                                        <FormControlLabel key={orderStatus}
                                                          control={<Checkbox
                                                              onChange={(e) => handleFilterBy(e, "st")}
                                                              checked={orderFilter.st?.includes(orderStatus.toString())}
                                                              name={orderStatus.toString()}
                                                              sx={{color: "rgba(255, 82, 82, 1)", padding: 0}}/>}
                                                          label={intl.formatMessage(getMessageDescriptor(getOrderStatusMessage(orderStatus)))}/>
                                    );
                                })}
                        </FormGroup>
                    </AccordionDetails>
                </Accordion>
            </Grid>
            <Grid item>
                <Paper elevation={5}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item width="100%">
                            <Tooltip title="contain">
                                <TextField size="small" name="cc" value={orderFilter.cc} id="filled-basic"
                                           label={intl.formatMessage(getMessageDescriptor("orderGridColCodeClient"))}
                                           variant="outlined"
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end"
                                                                   onClick={() => handleCleanField("cc")}
                                                                   sx={{cursor: "pointer"}}>
                                                       <CloseIcon/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                           onChange={handleChangeTextField}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item>
                <Paper elevation={5}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item width="100%">
                            <Tooltip title="contain">
                                <TextField size="small" name="cbn" value={orderFilter.cbn} id="filled-basic"
                                           label={intl.formatMessage(getMessageDescriptor("orderGridColLabelClient"))}
                                           variant="outlined"
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end"
                                                                   onClick={() => handleCleanField("cbn")}
                                                                   sx={{cursor: "pointer"}}>
                                                       <CloseIcon/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                           onChange={handleChangeTextField}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item>
                <Paper elevation={5}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item width="100%">
                            <Tooltip title="contain">
                                <TextField size="small" name="tgte" type="number" value={orderFilter.tgte}
                                           id="filled-basic"
                                           label={intl.formatMessage(getMessageDescriptor("orderGridColTotalExclTax"))}
                                           variant="outlined"
                                           fullWidth
                                           InputLabelProps={{shrink: true}}
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end"
                                                                   onClick={() => handleCleanField("tgte")}
                                                                   sx={{cursor: "pointer"}}>
                                                       <CloseIcon/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                           onChange={handleChangeTextField}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
            <Grid item>
                <Paper elevation={5}>
                    <Grid container justifyContent="center" alignItems="center" p={1}>
                        <Grid item width="100%">
                            <Tooltip title="contain">
                                <TextField size="small" name="ic" value={orderFilter.ic}
                                           id="filled-basic"
                                           label={intl.formatMessage(getMessageDescriptor("orderFilterByItemCode"))}
                                           variant="outlined"
                                           fullWidth
                                           InputProps={{
                                               endAdornment: (
                                                   <InputAdornment position="end"
                                                                   onClick={() => handleCleanField("ic")}
                                                                   sx={{cursor: "pointer"}}>
                                                       <CloseIcon/>
                                                   </InputAdornment>
                                               ),
                                           }}
                                           InputLabelProps={{shrink: true}}
                                           onChange={handleChangeTextField}/>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default OrderFilterAccordeon;