import React, {useEffect, useRef} from "react";
import {Autocomplete, Button, Grid, TextField} from "@mui/material";
import {Address} from "../../../../corelogic/models/customer";
import {useDispatch, useSelector} from "react-redux";
import {getCustomerFormSelector} from "../../../../store/selectors/customersSelector";
import * as yup from "yup";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import messages from "../../../../i18n/messages";
import {FormattedMessage, useIntl} from "react-intl";
import Typography from "@mui/material/Typography";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {retrieveCustomerFormDataValues} from "../../../../corelogic/usecase/customer/customersActions";

export interface AddressProps {
    title?: string
    businessName?: string
    address1?: string
    address2?: string
    address3?: string
    zipCode1?: string
    zipCode2?: string
    city?: string
    country?: string
    hasFocus?: boolean
    bolder?: boolean
    withLocationIcon?: boolean
    onlyDisplayTitleAndBusinessName?: boolean
}

export const AddressDisplay1Line = (props: AddressProps) => {
    const {
        address1,
        address2,
        address3,
        businessName,
        title,
        zipCode1,
        zipCode2,
        city,
        country,
        hasFocus,
        bolder,
        withLocationIcon,
        onlyDisplayTitleAndBusinessName
    } = props
    return (
        <Grid container>
            {onlyDisplayTitleAndBusinessName && withLocationIcon ?
                <>
                    <Grid item xs={1}>
                        <LocationOnIcon/>
                    </Grid>
                    <Grid item xs={11}>
                        <Typography
                            color={hasFocus ? "secondary" : "text.primary"}>{title} - {businessName}</Typography>
                    </Grid>
                </>
                :
                onlyDisplayTitleAndBusinessName && !withLocationIcon ?
                    <>
                        <Grid item container>
                            <Typography
                                color={hasFocus ? "secondary" : "text.primary"}>{title} - {businessName}</Typography>
                        </Grid>
                    </>
                    :
                    <Grid item container>
                        <Typography
                            color={hasFocus ? "secondary" : "text.primary"}
                            fontWeight={bolder ? "bolder" : ""}>
                            {`${address1 ? address1 : ""}${address2 ? address2 : ""}${address3 ? address3 : ""}${zipCode1 ? ", " + zipCode1 : ""}${zipCode2 ? zipCode2 : ""}${city ? ", " + city : ""}`}
                        </Typography>
                    </Grid>
            }
        </Grid>
    )
}

export const AddressDisplayBlock = (props: AddressProps) => {
    const {address1, address2, address3, businessName, title, zipCode1, zipCode2, city, country, hasFocus} = props

    return (
        <>
            <Grid container direction="column">
                {title && title?.length > 0 &&
                    <Grid item>
                        <Typography color={hasFocus ? "secondary" : "text.primary"}
                                    fontWeight="bolder">{title}</Typography>
                    </Grid>}
                {businessName &&
                    <Grid item>
                        <Typography color={hasFocus ? "secondary" : "text.primary"}
                                    fontWeight="bolder">{businessName}</Typography>
                    </Grid>}
                {address1 &&
                    <Grid item>
                        <Typography color={hasFocus ? "secondary" : "text.primary"}>{address1}</Typography>
                    </Grid>}
                {address2 &&
                    <Grid item>
                        <Typography color={hasFocus ? "secondary" : "text.primary"}>{address2}</Typography>
                    </Grid>}
                {address3 &&
                    <Grid item>
                        <Typography color={hasFocus ? "secondary" : "text.primary"}>{address3}</Typography>
                    </Grid>}
                {zipCode1 && <Grid item>
                    <Typography color={hasFocus ? "secondary" : "text.primary"}>{zipCode1}</Typography>
                </Grid>}
                {zipCode2 && <Grid item>
                    <Typography color={hasFocus ? "secondary" : "text.primary"}>{zipCode2}</Typography>
                </Grid>}
                {city &&
                    <Grid item>
                        <Typography color={hasFocus ? "secondary" : "text.primary"}>{city}</Typography>
                    </Grid>}
                {country &&
                    <Grid item>
                        <Typography color={hasFocus ? "secondary" : "text.primary"}>{country}</Typography>
                    </Grid>}
            </Grid>
        </>
    )
}

interface AddressFormInterface {
    data: Address,

    onConfirmClick?(addressForm: Address): void,

    onCancelClick?(): void
}

export const AddressForm = (props: AddressFormInterface) => {
    const {data, onConfirmClick, onCancelClick} = props
    const dispatch = useDispatch()
    const intl = useIntl()
    const {dataFormFields} = useSelector(getCustomerFormSelector)
    const titleInputFocus = useRef<HTMLInputElement | null>(null)

    const schemaValidationAddressForm = yup.object({
        title: yup.string().required("Please type a title").default(data.title),
        businessName: yup.string().required("Please type a business name").default(data.businessName),
        address1: yup.string().required("Please type an address").default(data?.address1),
        zipCode1: yup.string().required("Please type a zipCode").default(data.zipCode1),
        city: yup.string().required("Please type a city").default(data.city),
        country: yup.object().required("Please choose a country").default(data.country)
    }).required()

    //On définit le useForm et on va chercher ce dont on a besoin pour tout gérer
    const {handleSubmit, reset, formState: {errors}, control} = useForm<Address>(
        {
            resolver: yupResolver(schemaValidationAddressForm)
        }
    )

    useEffect(() => {
        dispatch(retrieveCustomerFormDataValues())
    }, [dispatch])

    useEffect(() => {
        reset(data)
        titleInputFocus.current?.focus()
    }, [data, reset])

    const onSubmit: SubmitHandler<Address> = (data) => {
        if (onConfirmClick) {
            onConfirmClick(data)
            handleReset()
        }
    }

    const handleReset = () => {
        if (onCancelClick) {
            onCancelClick()
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)} onReset={handleReset}>
            <Grid container direction="column" spacing={2} p={3}>
                <Grid item>
                    <Controller
                        name="title"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                inputRef={titleInputFocus}
                                fullWidth
                                required
                                variant="standard"
                                label={intl.formatMessage(messages.customerAddressTitle)}
                                type="text"
                                error={!!errors.title}
                                helperText={errors.title?.message}
                                InputLabelProps={{shrink: !!field.value}}
                            />}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name="businessName"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                fullWidth
                                required
                                variant="standard"
                                defaultValue={data.businessName}
                                type="text"
                                label={intl.formatMessage(messages.customerAddressBusinessName)}
                                error={!!errors.businessName}
                                helperText={errors.businessName?.message}
                                InputLabelProps={{shrink: !!field.value}}
                            />}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name="address1"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                fullWidth
                                required
                                variant="standard"
                                defaultValue={data.address1}
                                label={intl.formatMessage(messages.customerAddressAddress1)}
                                type="text"
                                error={!!errors.address1}
                                helperText={errors.address1?.message}
                                InputLabelProps={{shrink: !!field.value}}
                            />}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name="address2"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                fullWidth
                                variant="standard"
                                defaultValue={data.address2}
                                label={intl.formatMessage(messages.customerAddressAddress2)}
                                type="text"
                                error={!!errors.address2}
                                helperText={errors.address2?.message}
                                InputLabelProps={{shrink: !!field.value}}
                            />}
                    />
                </Grid>
                <Grid item>
                    <Controller
                        name="address3"
                        control={control}
                        render={({field}) =>
                            <TextField
                                {...field}
                                fullWidth
                                variant="standard"
                                defaultValue={data.address3}
                                label={intl.formatMessage(messages.customerAddressAddress3)}
                                type="text"
                                error={!!errors.address3}
                                helperText={errors.address3?.message}
                                InputLabelProps={{shrink: !!field.value}}
                            />}
                    />
                </Grid>
                <Grid container item spacing={1}>
                    <Grid item lg={3} md={3} sm={6} xs={6}>
                        <Controller
                            name="zipCode1"
                            control={control}
                            render={({field}) =>
                                <TextField
                                    {...field}
                                    fullWidth
                                    required
                                    variant="standard"
                                    defaultValue={data.zipCode1}
                                    label={intl.formatMessage(messages.customerAddressZipCode1)}
                                    type="text"
                                    error={!!errors.zipCode1}
                                    helperText={errors.zipCode1?.message}
                                    InputLabelProps={{shrink: !!field.value}}
                                />}
                        />
                    </Grid>
                    <Grid item lg={3} md={3} sm={6} xs={6}>
                        <Controller
                            name="zipCode2"
                            control={control}
                            render={({field}) =>
                                <TextField
                                    {...field}
                                    fullWidth
                                    variant="standard"
                                    defaultValue={data.zipCode2}
                                    label={intl.formatMessage(messages.customerAddressZipCode2)}
                                    type="text"
                                    error={!!errors.zipCode2}
                                    helperText={errors.zipCode2?.message}
                                    InputLabelProps={{shrink: !!field.value}}
                                />}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Controller
                            name="city"
                            control={control}
                            render={({field}) =>
                                <TextField
                                    {...field}
                                    fullWidth
                                    required
                                    variant="standard"
                                    defaultValue={data.city}
                                    label={intl.formatMessage(messages.customerAddressCity)}
                                    type="text"
                                    error={!!errors.city}
                                    helperText={errors.city?.message}
                                    InputLabelProps={{shrink: !!field.value}}
                                />}
                        />
                    </Grid>
                </Grid>
                <Grid item>
                    <Controller
                        name="country"
                        control={control}
                        render={({field: {onChange, value}}) =>
                            <Autocomplete
                                options={dataFormFields.countries}
                                onChange={(event, country) => {
                                    onChange(country)
                                }}
                                value={value ? value : null}
                                getOptionLabel={(option => option.label || "")}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={(params) => <TextField {...params}
                                                                    required
                                                                    fullWidth
                                                                    label={intl.formatMessage(messages.customerAddressCountry)}
                                                                    variant="standard"
                                                                    error={!!errors.country}
                                                                    helperText={errors.country?.message}

                                />}
                            />}
                    />
                </Grid>
                <Grid container item justifyContent="flex-end" spacing={2}>
                    <Grid item>
                        <Button type="reset" variant="contained" color="error"><FormattedMessage
                            id={messages.genericCancel.id}/></Button>
                    </Grid>
                    <Grid item>
                        <Button type="submit" variant="contained"><FormattedMessage
                            id={messages.genericSave.id}/></Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    )
}